import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { setNewChatModel } from "./ChatModelSlice";
import { conversationsActions } from "./conversationSlice";
import { ChatModelState } from "../../Helper";
interface ChatModelData {
  id: string;
  version: string;
  name:string;
}
interface FileErrorMsgState {
  errorMsg: string;
  isError: boolean;
  reason: string;
}
interface SnackBarAlert {
  message: string;
  show: boolean;
  alertType: string;
}
interface ChatsState {
  chatsNum: number;
  maximizedChat: {
    versionId: string;
    versionName: string;
  } | null;
  selectedOptions: string[];
  fullWidthMode: string;
  isUserInteracted: boolean[];
  reGenerateResponse: boolean;
  newConversation: boolean;
  Chat1Data: ChatModelData;
  Chat2Data: ChatModelData;
  Chat3Data: ChatModelData;
  Chat4Data: ChatModelData;
  Chat5Data: ChatModelData;
  Chat6Data: ChatModelData;
  GPTVersionID: string | null;
  GeminiVersionID: string;
  LlamaVersionID: string;
  isUpLoadedFile: boolean;
  selectedPersona: {
    id: string;
    title: string;
    description: string;
    objective: string;
  };
  popupSaveConShown: boolean;
  disabledVersions: string[];
  messageError: string;
  reGenerateMessage: string;
  fileErrorMsg: FileErrorMsgState;
  ResponsiveAppSize: string;
  showNavMenu: boolean;
  ParentMessageId: string | null;
  IsUserType: boolean;
  currentObj?: ChatModelState;
  isEditing:boolean
  snackAlert: SnackBarAlert;
}

const initialState: ChatsState = {
  chatsNum: 0,
  maximizedChat: null,
  selectedOptions: [],
  fullWidthMode: "",
  isUserInteracted: [false, false, false, false, false, false],
  reGenerateResponse: false,
  newConversation: true,
  GPTVersionID: "",
  Chat1Data: { id: "", version: "" ,name:""},
  Chat2Data: { id: "", version: "" ,name:""},
  Chat3Data: { id: "", version: "" ,name:""},
  Chat4Data: { id: "", version: "" ,name:""},
  Chat5Data: { id: "", version: "" ,name:""},
  Chat6Data: { id: "", version: "" ,name:""},
  GeminiVersionID: "",
  LlamaVersionID: "",
  isUpLoadedFile: false,
  popupSaveConShown: false,
  selectedPersona: { id: "", title: "", description: "", objective: "" },
  disabledVersions: [],
  messageError: "",
  reGenerateMessage: "",
  fileErrorMsg: { isError: false, errorMsg: "", reason: "" },
  showNavMenu: false,
  ResponsiveAppSize: "Desktop",
  ParentMessageId: null,
  IsUserType: false,
  isEditing:false,
  snackAlert: { show: false, message: "", alertType: "" },
};
interface UserInteractionPayload {
  index: number;
  value: boolean;
}

const chatsSlice = createSlice({
  name: "chats",
  initialState: initialState,
  reducers: {
    getChatsOpen: (
      state,
      action: PayloadAction<{ chatsNum: number; selectedOptions: string[] }>
    ) => {
      state.chatsNum = action.payload.chatsNum;
      state.selectedOptions = action.payload.selectedOptions;
    },
    setMaximizedChat: (
      state,
      action: PayloadAction<{ versionId: string; versionName: string } | null>
    ) => {
      state.maximizedChat = action.payload;
    },
    setCurrentObjSave: (state, action: PayloadAction<ChatModelState>) => {
      state.currentObj = action.payload;
    },
    setIsEditingStatus: (state, action: PayloadAction<boolean>) => {
      state.isEditing = action.payload;
    },
    toggleFullWidthMode: (state, action: PayloadAction<string>) => {
      const model = action.payload;
      state.fullWidthMode = state.fullWidthMode === model ? "" : model;
    },
    setUserInteracted: (
      state,
      action: PayloadAction<UserInteractionPayload>
    ) => {
      const { index, value } = action.payload;
      state.isUserInteracted[index] = value;
    },
    resetUserInteracted: (state) => {
      state.isUserInteracted = state.isUserInteracted.map(() => false);
    },
    regenerateResponse: (state, action: PayloadAction<boolean>) => {
      state.reGenerateResponse = action.payload;
    },
    setNewConversation: (state, action: PayloadAction<boolean>) => {
      state.newConversation = action.payload;
    },
    setNewGPTVersion: (state, action: PayloadAction<string>) => {
      state.GPTVersionID = action.payload;
    },
    setNewGeminiVersion: (state, action: PayloadAction<string>) => {
      state.GeminiVersionID = action.payload;
    },
    setNewLlamaVersion: (state, action: PayloadAction<string>) => {
      state.LlamaVersionID = action.payload;
    },

    setChat1Data: (
      state,
      action: PayloadAction<{ id: string; version: string; name:string}>
    ) => {
      state.Chat1Data.id = action.payload.id;
      state.Chat1Data.version = action.payload.version;
      state.Chat1Data.name = action.payload.name;
    },
    setChat2Data: (
      state,
      action: PayloadAction<{ id: string; version: string; name:string}>
    ) => {
      state.Chat2Data.id = action.payload.id;
      state.Chat2Data.version = action.payload.version;
      state.Chat2Data.name = action.payload.name;
    },
    setChat3Data: (
      state,
      action: PayloadAction<{ id: string; version: string; name:string}>
    ) => {
      state.Chat3Data.id = action.payload.id;
      state.Chat3Data.version = action.payload.version;
      state.Chat3Data.name = action.payload.name;
    },
    setChat4Data: (
      state,
      action: PayloadAction<{ id: string; version: string; name:string}>
    ) => {
      state.Chat4Data.id = action.payload.id;
      state.Chat4Data.version = action.payload.version;
      state.Chat4Data.name = action.payload.name;
    },
    setChat5Data: (
      state,
      action: PayloadAction<{ id: string; version: string; name:string}>
    ) => {
      state.Chat5Data.id = action.payload.id;
      state.Chat5Data.version = action.payload.version;
      state.Chat5Data.name = action.payload.name;
    },
    setChat6Data: (
      state,
      action: PayloadAction<{ id: string; version: string; name:string}>
    ) => {
      state.Chat6Data.id = action.payload.id;
      state.Chat6Data.version = action.payload.version;
      state.Chat6Data.name = action.payload.name;
    },
    setUpLoadedFile: (state, action: PayloadAction<boolean>) => {
      state.isUpLoadedFile = action.payload;
    },
    setFileError: (state, action: PayloadAction<FileErrorMsgState>) => {
      state.fileErrorMsg.errorMsg = action.payload.errorMsg;
      state.fileErrorMsg.isError = action.payload.isError;
      state.fileErrorMsg.reason = action.payload.reason;
    },
    setSnackBarAlert: (state, action: PayloadAction<SnackBarAlert>) => {
      state.snackAlert.show = action.payload.show;
      state.snackAlert.message = action.payload.message;
      state.snackAlert.alertType = action.payload.alertType;
    },
    setSelectedPersona: (state, action: PayloadAction<any>) => {
      state.selectedPersona = action.payload;
    },
    setPopupConShown: (state, action: PayloadAction<boolean>) => {
      state.popupSaveConShown = action.payload;
    },
    setDisabledVersions: (state, action: PayloadAction<string[]>) => {
      state.disabledVersions = action.payload;
    },
    setMessageErrorLogin: (state, action: PayloadAction<string>) => {
      state.messageError = action.payload;
    },
    setMessageError: (state, action: PayloadAction<string>) => {
      state.messageError = action.payload;
    },
    setReGenerateMessage: (state, action: PayloadAction<string>) => {
      state.reGenerateMessage = action.payload;
    },
    setResponsiveAppSize: (state, action: PayloadAction<string>) => {
      state.ResponsiveAppSize = action.payload;
    },
    setShowNavMenu: (state, action: PayloadAction<boolean>) => {
      state.showNavMenu = action.payload;
    },
    setParentMessageId: (state, action: PayloadAction<string | null>) => {
      state.ParentMessageId = action.payload;
    },
    setIsUserType: (state, action: PayloadAction<boolean>) => {
      state.IsUserType = action.payload;
    },
  },
});
export const handleNewConversationRequest = () => (dispatch: any) => {debugger
    dispatch(setSelectedPersona(""));
    dispatch(setNewConversation(true));
    dispatch(conversationsActions.getLastActiveModels([]));
    dispatch(conversationsActions.removeActiveConversation());
    dispatch(setDisabledVersions([]));
    dispatch(setNewChatModel());
      dispatch(
        getChatsOpen({
          chatsNum: 4,
          selectedOptions: ["ChatGPT", "Gemini", "Llama", "Anthropic"],
        })
      );
  };

export const {
  getChatsOpen,
  setMaximizedChat,
  toggleFullWidthMode,
  setUserInteracted,
  resetUserInteracted,
  regenerateResponse,
  setNewConversation,
  setNewGPTVersion,
  setNewGeminiVersion,
  setNewLlamaVersion,
  setUpLoadedFile,
  setIsEditingStatus,
  setCurrentObjSave,
  setSelectedPersona,
  setPopupConShown,
  setMessageError,
  setChat1Data,
  setChat2Data,
  setChat3Data,
  setChat4Data,
  setChat5Data,
  setChat6Data,
  setDisabledVersions,
  setMessageErrorLogin,
  setReGenerateMessage,
  setFileError,
  setResponsiveAppSize,
  setShowNavMenu,
  setParentMessageId,
  setIsUserType,
  setSnackBarAlert,
} = chatsSlice.actions;
export default chatsSlice.reducer;
