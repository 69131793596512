import React, { useState, useEffect, useRef } from "react";
import "./Models.css";
import {
  Badge,
  Dropdown,
  DropdownItem,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import chatsSlice, {
  handleNewConversationRequest,
  setChat1Data,
  setChat2Data,
  setChat3Data,
  setChat4Data,
  setChat5Data,
  setChat6Data,
  setMaximizedChat,
} from "../../../../store/one-query/chatsSlice";
import {
  useGetConversationVersions,
  useIsAllowedVersion,
} from "../../../../store/Apis/ConversationVersionsApi";
import { conversationsActions } from "../../../../store/one-query/conversationSlice";
import DeleteModal from "./DeletePopup";
import UpgradeToProPopup from "./UpgradeToProPopup";
interface Version {
  id: string;
  name: string;
  createdAt: string;
  updatedById: string;
  updatedAt: string;
  createdById: string;
  isDeleted: boolean;
  deletedById: string;
  deletedAt: string;
  title: string;
  version: string;
  allowAnnonymous: boolean;
  isPro: boolean;
}
interface ModelsProps {
  modelName: string;
  companyName: string;
  defaultNameVersion: string | undefined;
  index: number;
  page: string;
  setVersionModel: (id: string) => void;
  annonyVersion: string | undefined;
  lastActiveVersion?: any;
  setActiveModels: () => void
}
const ReturnVersionModel = (props: ModelsProps) => {
  const {
    modelName,
    companyName,
    defaultNameVersion,
    index,
    page,
    setVersionModel,
    lastActiveVersion,
    annonyVersion,
  } = props;
  const dispatch = useAppDispatch();
  const [versionArrayData, setVersionArrayData] = useState<Version[]>();
  const [version, setVersion] = useState<string>("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showDeleteConversations, setShowDeleteConversations] = useState(false);
  const [versionInAnnonIsDisabled, setVersionInAnnonIsDisabled] =
    useState(false);
  const conversationVersionModels = useAppSelector(
    (state) => state.conversationVersion.data
  );
  const isFullWidth = useAppSelector((state) => state.ChatsState.fullWidthMode);
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const versionIsAllowedMutate = useIsAllowedVersion();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const disabledVersions = useAppSelector((s) => s.ChatsState.disabledVersions);
  const isNewConversation = useAppSelector((s) => s.ChatsState.newConversation);
  const getConversationVersions = useGetConversationVersions();
  const [defaultVersion, setDefaultVersion] = useState<any>();
  const [showIsPro, setShowIsPro] = useState<boolean>(false);

  useEffect(() => {
    setDefaultVersion(
      page === "Landing-page"
        ? conversationVersionModels?.find(
            (version) => version.title === annonyVersion && version.isDefault
          )
        : conversationVersionModels?.find(
            (version) => version.title === defaultNameVersion
          )
    );
  }, [annonyVersion, defaultNameVersion]);

  useEffect(() => {
    if (defaultNameVersion) {
      setVersion(defaultNameVersion);

      if (defaultVersion) {
        handleSelectedVersion(
          defaultVersion.title,
          defaultVersion.id,
          defaultVersion.version,
          defaultVersion.allowAnnonymous,
          defaultVersion.name
        );
      }
    }
  }, [defaultNameVersion, defaultVersion, handleNewConversationRequest]);

  useEffect(() => {
    if (defaultNameVersion && isNewConversation) {
      setVersion(defaultNameVersion);

      if (defaultVersion && isNewConversation) {
        handleSelectedVersion(
          defaultVersion.title,
          defaultVersion.id,
          defaultVersion.version,
          defaultVersion.allowAnnonymous,
          defaultVersion.name
        );
      }
    }
  }, [isNewConversation]);

  useEffect(() => {
    if (conversationVersionModels) {
      setVersionArrayData(conversationVersionModels);
    }
  }, [conversationVersionModels, versionArrayData]);

  useEffect(() => {
    getConversationVersions();
  }, [dispatch]);

  useEffect(() => {
    if (lastActiveVersion && versionArrayData) {
      const versionObj = versionArrayData.find(
        (obj) => obj.id === lastActiveVersion.id
      );
      if (versionObj) {
        handleSelectedVersion(
          versionObj.title,
          versionObj.id,
          versionObj.version,
          versionObj.allowAnnonymous,
          versionObj.name
        );
      }
    }
  }, [lastActiveVersion, versionArrayData]);

  const handleSetIdForEveryChat = (title = "", id = "", version = "" , name = "") => {
    const chatData = { id, version , name};

    switch (index) { 
      case 0:
        dispatch(setChat1Data(chatData));
        break;
      case 1:
        dispatch(setChat2Data(chatData));
        break;
      case 2:
        dispatch(setChat3Data(chatData));
        break;
      case 3:
        dispatch(setChat4Data(chatData));
        break;
      case 4:
        dispatch(setChat5Data(chatData));
        break;
      case 5:
        dispatch(setChat6Data(chatData));
        break;
      default:
        break;
    }
    setVersionModel(version);
    setVersion(title);
    setDropdownOpen(false);
  };
  const handleSelectedVersion = (
    title: string,
    id: string,
    version: string,
    versionIsAnnony: boolean,
    name:string
  ) => {
    if (page === "Landing-page") {
      if (versionIsAnnony) {
        const isDisabled = disabledVersions.some(
          (disableObj) => disableObj === version
        );
        if (!isDisabled) {
          handleSetIdForEveryChat(title, id, version, name);
        }
      } else {
        setShowDeleteConversations(true);
        setVersionInAnnonIsDisabled(true);
      }
    } else {
      versionIsAllowedMutate(id, (isAllowed: boolean) => {
        if (isAllowed) handleSetIdForEveryChat(title, id, version, name);
        else {
          setShowIsPro(true);
        }
      });
    }
    isFullWidth?.length &&
      dispatch(setMaximizedChat({ versionId: id, versionName: version }));
  };
  const handleSelectVersion = () => {
    dispatch(conversationsActions.setIsConHistoryClicked(false));
  };

  const renderTooltip = (versionTitle: string) => (props: any) =>
    (
      <Tooltip id="button-tooltip" {...props}>
        {versionTitle}
      </Tooltip>
    );
  const handleClose = () => {
    setShowIsPro(!showIsPro);
  };

  return (
    <>
      <Dropdown
        className="model-versions"
        show={dropdownOpen}
        ref={dropdownRef}
      >
        <Dropdown.Toggle className="versions" onClick={handleSelectVersion}>
          <div
            className="d-flex align-items-center justify-content-between"
            onClick={toggleDropdown}
          >
            <span className={dropdownOpen ? "opened" : ""}>
              {version && version.length > 12
                ? version.slice(0, 12) + "..."
                : version}
            </span>
            <img
              src={
                dropdownOpen
                  ? "/images/icn-arrow-up (1).svg"
                  : "/images/icn-arrow-up.svg"
              }
            />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="versions-menu">
          {modelName &&
            versionArrayData?.map((obj, index) => {
              if (obj.name.toLowerCase() === modelName) {
                const isDisabled =
                  page === "Landing-page"
                    ? !obj.allowAnnonymous
                    : disabledVersions.includes(obj.version);
                return (
                  <OverlayTrigger
                    key={index}
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip(obj.title)}
                  >
                    <DropdownItem
                      disabled={
                        page === "Landing-page" && obj.allowAnnonymous
                          ? isDisabled
                          : page === "Landing-page" && !obj.allowAnnonymous
                          ? false
                          : isDisabled
                      }
                      key={index}
                      className={`version-item  ${
                        isDisabled ? "disabled-hover" : ""
                      } ${version === obj.title && "selected"}
                        ${page === "Landing-page" ? "Landing-page" : ""}`}
                      onClick={() => {
                        handleSelectedVersion(
                          obj.title,
                          obj.id,
                          obj.version,
                          obj.allowAnnonymous,
                          obj.name
                        );
                        props.setActiveModels();
                      }
                      }
                    >
                      <span className={`version-name ${page}`}>
                        {obj.title}
                      </span>

                      {page === "Landing-page" && !obj.allowAnnonymous ? (
                        <Badge className="bg-transparent">
                          <img src="/images/Lock-light.svg" />
                        </Badge>
                      ) : (
                        obj.isPro && <Badge className="main-page-pro">Pro</Badge>
                      )}
                    </DropdownItem>
                  </OverlayTrigger>
                );
              }
              return null;
            })}
        </Dropdown.Menu>
      </Dropdown>
      {versionInAnnonIsDisabled && (
        <DeleteModal
          show={showDeleteConversations}
          handleClose={() => setShowDeleteConversations(false)}
          page="Landing-page"
          name={companyName}
        />
      )}
      {showIsPro && (
        <UpgradeToProPopup showModal={showIsPro} closeModal={handleClose} />
      )}
    </>
  );
};

export default ReturnVersionModel;
