import React, { useEffect } from "react";
import "./PrivacyAndTermCondition.css";
import { useAppSelector } from "../../../../store/hooks";
import { useNavigate } from "react-router-dom";
import SidebarApp from "../get-conversation/getConversation";
import Privacy_TermContent from "./PrivacyContent";

const TermAndContion = () => {
  let darkMode = useAppSelector((state) => state.User.darkMode);
  const navigate = useNavigate();

  return (
    <div className="d-flex home-page">
      <div>
        <SidebarApp />
      </div>
      <div className=" ms-4 mt-3 me-4">
        <h3
          className="c-pointer d-inline SettingDivHeader"
          onClick={() => navigate("/settings")}
        >
          <img
            src={
              darkMode
                ? "Images/SettingsSideResources/icn-arrow-left-dark.svg"
                : "Images/SettingsSideResources/icn-arrow-left.svg"
            }
          />
          <span className="d-none d-md-inline settingWord"> Setting &gt;</span>
        </h3>
        <h3 className="d-inline termLetter"> Terms & conditions</h3>

        <div className="mt-4 divContainer container-fluid">
          {/* 1 */}
          <Privacy_TermContent
            header={"Expertex terms and conditions"}
            Content={[
              <p>
                {`Expertex, Inc. (“Expertex,” “we,” “us,” or “our”) provides a SaaS-based AI-powered engine that allows professionals and enterprises to LLM models for multiple purposes (the “Services”). This Privacy Policy describes how we handle personal information that we collect about our customers, including when they use our Services or visit our website, https://Expertex.ai/.\nWe treat all personal information covered by this Privacy Policy as pertaining to individuals acting as business representatives, and not in their individual or household capacity. This Privacy Policy does not apply to our handling of personal information that we process on behalf of our customers as a service provider.`}
                ,
              </p>,
            ]}
          ></Privacy_TermContent>
          {/* 2 */}
          <Privacy_TermContent
            header={"1. Acceptance of the Terms and Conditions."}
            Content={[
              <ol style={{ listStyleType: "none" }}>
                <li className="orderListed">
                  <div className="listNumbered">{`1.1`}</div>
                  <div>
                    Expertex, Inc. (herein referred to as the “Company,” “we,”
                    “us” or “our”) provides and makes available this web site
                    (the “Site”). All use of the Site is subject to the terms
                    and conditions contained in these Website Terms and
                    Conditions (this “Agreement”). Please read this Agreement
                    carefully. By accessing, browsing or otherwise using the
                    Site, you acknowledge that you have read, understood, and
                    agree to be bound by this Agreement. If you do not accept
                    the terms and conditions of this Agreement, you shall not
                    access, browse or use the Site.
                  </div>
                </li>
                <li className="orderListed">
                  <div className="listNumbered">{`1.2`}</div>
                  <div>
                    You understand and agree that your use of our Software as a
                    Service AI-powered generative search platform (“Company
                    Products”) shall not be governed by this Agreement, but
                    rather by your company’s or organization’s agreement with
                    the Company covering such Company Products. However, please
                    note that your access to and use of the Site and any Company
                    Products is also subject to the Company’s Privacy Policy
                    located at Expertex.ai/legal/privacy-policy/
                  </div>
                </li>
              </ol>,
            ]}
          ></Privacy_TermContent>
          {/* 2 */}
          <Privacy_TermContent
            header={"2. Use of the Site."}
            Content={[
              <ol style={{ listStyleType: "none" }}>
                <li className="orderListed">
                  <div className="listNumbered">{`2.1`}</div>
                  <div>
                    This Site contains material, including but not limited to
                    software, text, graphics and images (collectively referred
                    to as the “Content”). We may own the Content or portions of
                    the Content may be made available to us through arrangements
                    that we have with third-parties. The Content is protected by
                    United States and foreign intellectual property laws.
                    Unauthorized use of the Content may result in violation of
                    copyright, trademark, and other laws. You have no rights in
                    or to the Content, and you will not copy the Content and
                    will only access and use the Content for your personal
                    purposes. You may not sell, transfer, assign, license,
                    sublicense, or modify the Content or reproduce, display,
                    publicly perform, make a derivative version of, distribute,
                    or otherwise use the Content in any way for any public or
                    commercial purpose. The use or posting of any of the Content
                    on any other web site or computer network for any purpose is
                    expressly prohibited. If you violate any part of this
                    Agreement, your right to access and/or use the Content and
                    Site shall automatically terminate.
                  </div>
                </li>
                <li className="orderListed">
                  <div className="listNumbered">{`2.2`}</div>
                  <div>
                    The trademarks, service marks, and logos of the Company (the
                    “Company Trademarks”) used and displayed on this Site are
                    registered and unregistered trademarks or service marks of
                    the Company. Other company, product, and service names
                    located on the Site may be trademarks or service marks owned
                    by third-parties (the “Third-Party Trademarks”, and,
                    collectively with the Company Trademarks, the “Trademarks”).
                    Nothing on this Site or in this Agreement should be
                    construed as granting, by implication, estoppel, or
                    otherwise, any license or right to use any Trademark
                    displayed on this Site without the prior written consent of
                    the Company specific for each such use. The Trademarks may
                    not be used to disparage the Company or the applicable
                    third-party, the Company’s or third-party’s products or
                    services, or in any manner (using commercially reasonable
                    judgment) that may damage any goodwill in the Trademarks.
                    Use of any Trademarks as part of a link to or from any web
                    site is prohibited without the Company’s prior written
                    consent. All goodwill generated from the use of any Company
                    Trademark shall inure to the Company’s benefit.
                  </div>
                </li>
                <li className="orderListed">
                  <div className="listNumbered">{`2.3`}</div>
                  <div>
                    You agree not to: (a) take any action that imposes an
                    unreasonable load on the Site’s infrastructure, (b) use any
                    device, software or routine to interfere or attempt to
                    interfere with the proper working of the Site or any
                    activity being conducted on the Site, (c) attempt to
                    decipher, decompile, disassemble or reverse engineer any of
                    the software comprising or making up the Site, (d) delete or
                    alter any material posted on the Site by the Company or any
                    other person or entity, or (e) frame or link to any of the
                    materials or information available on the Site.
                  </div>
                </li>
                <li className="orderListed">
                  <div className="listNumbered">{`2.4`}</div>
                  <div>
                    The Site contains links to third-party web sites (“External
                    Sites”). These links are provided solely as a convenience to
                    you and not as an endorsement by us of the content on such
                    External Sites. The content of such External Sites is
                    developed and provided by others. You should contact a
                    representative of those External Sites if you have any
                    concerns regarding such links or any content located on such
                    External Sites. We are not responsible for the content of
                    any linked External Sites and do not make any
                    representations regarding the content or accuracy of any
                    materials on such External Sites. You should take
                    precautions when downloading files from all web sites to
                    protect your computer from viruses and other destructive
                    programs. If you decide to access any External Sites, you do
                    so at your own risk.
                  </div>
                </li>
                <li className="orderListed">
                  <div className="listNumbered">{`2.5`}</div>
                  <div>
                    Certain elements of the Site are protected by trade dress,
                    trademark, unfair competition, and other state and federal
                    laws and may not be copied or imitated in whole or in part,
                    by any means, including but not limited to, the use of
                    framing or mirrors, except as otherwise expressly permitted
                    by Section 2.1 of the Agreement. None of the Content for
                    this Site may be retransmitted without the express written
                    consent from the Company for each and every instance.
                  </div>
                </li>
                <li className="orderListed">
                  <div className="listNumbered">{`2.6`}</div>
                  <div>
                    You may from time to time provide suggestions, comments for
                    enhancements or functionality or other feedback (“Feedback”)
                    to us with respect to the Site or Content. We shall have
                    full discretion to determine whether or not to proceed with
                    the development or implementation of any Feedback. You
                    hereby grants Company a royalty-free, fully paid up,
                    worldwide, transferable, sublicenseable, irrevocable,
                    perpetual license to (a) copy, distribute, transmit,
                    display, perform, and create derivative works of the
                    Feedback; and (b) use the Feedback and/or any subject matter
                    thereof, including without limitation, the right to develop,
                    manufacture, have manufactured, market, promote, sell, have
                    sold, offer for sale, have offered for sale, import, have
                    imported, rent, provide and/or lease products or services
                    which practice or embody, or are configured for use in
                    practicing, the Feedback and/or any subject matter of the
                    Feedback.
                  </div>
                </li>
              </ol>,
            ]}
          ></Privacy_TermContent>
          {/* 3 */}
          <Privacy_TermContent
            header={"3. Limitation of Liability and Disclaimer of Warranties."}
            Content={[
              <ol style={{ listStyleType: "none" }}>
                <li className="orderListed">
                  <div className="listNumbered">{`3.1`}</div>
                  <div>
                    THE COMPANY, ITS AFFILIATES, THEIR RESPECTIVE OFFICERS,
                    DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS, OR LICENSORS
                    (COLLECTIVELY, THE “COMPANY PARTIES“) MAKE NO WARRANTIES OR
                    REPRESENTATIONS ABOUT THE SITE OR CONTENT, INCLUDING BUT NOT
                    LIMITED TO ITS ACCURACY, RELIABILITY, COMPLETENESS,
                    TIMELINESS OR RELIABILITY. THE COMPANY PARTIES SHALL NOT BE
                    SUBJECT TO LIABILITY FOR THE TRUTH, ACCURACY OR COMPLETENESS
                    OF THE SITE OR CONTENT OR ANY OTHER INFORMATION CONVEYED TO
                    THE USER OR FOR ERRORS, MISTAKES OR OMISSIONS THEREIN OR FOR
                    ANY DELAYS OR INTERRUPTIONS OF THE DATA OR INFORMATION
                    STREAM FROM WHATEVER CAUSE. YOU AGREE THAT YOU USE THE SITE
                    AND THE CONTENT AT YOUR OWN RISK. THE COMPANY PARTIES DO NOT
                    WARRANT THAT THE SITE WILL OPERATE ERROR FREE OR THAT THE
                    SITE, ITS SERVER, OR THE CONTENT ARE FREE OF COMPUTER
                    VIRUSES OR SIMILAR CONTAMINATION OR DESTRUCTIVE FEATURES. IF
                    YOUR USE OF THE SITE OR THE CONTENT RESULTS IN THE NEED FOR
                    SERVICING OR REPLACING EQUIPMENT OR DATA, NO COMPANY PARTY
                    SHALL BE RESPONSIBLE FOR THOSE COSTS. THE SITE AND CONTENT
                    ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT
                    ANY WARRANTIES OF ANY KIND. THE COMPANY PARTIES DISCLAIM ALL
                    WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE WARRANTIES OF
                    TITLE, MERCHANTABILITY, NONINFRINGEMENT OF THIRD PARTIES
                    RIGHTS, AND FITNESS FOR PARTICULAR PURPOSE.
                  </div>
                </li>
                <li className="orderListed">
                  <div className="listNumbered">{`3.2`}</div>
                  <div>
                    IN NO EVENT SHALL ANY COMPANY PARTY BE LIABLE FOR ANY
                    DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION,
                    INCIDENTAL AND CONSEQUENTIAL DAMAGES, LOST PROFITS, OR
                    DAMAGES RESULTING FROM LOST DATA OR BUSINESS INTERRUPTION)
                    RESULTING FROM THE USE OR INABILITY TO USE THE SITE AND THE
                    CONTENT, WHETHER BASED ON WARRANTY, CONTRACT, TORT
                    (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY, EVEN IF
                    SUCH COMPANY PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF
                    SUCH DAMAGES.
                  </div>
                </li>
                <li className="orderListed">
                  <div className="listNumbered">{`3.3`}</div>
                  <div>
                    SOME STATES DO NOT ALLOW THE DISCLAIMER OR EXCLUSION OF
                    CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF
                    LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES.
                    ACCORDINGLY, IN SUCH STATES, SOME OF THE ABOVE LIMITATIONS
                    MAY NOT APPLY TO YOU OR BE ENFORCEABLE WITH RESPECT TO YOU,
                    AND THE LIABILITY OF THE COMPANY PARTIES SHALL BE LIMITED TO
                    THE GREATEST EXTENT PERMITTED BY LAW.
                  </div>
                </li>
                <li className="orderListed">
                  <div className="listNumbered">{`3.4`}</div>
                  <div>
                    IF YOU ARE FROM NEW JERSEY, THE FOREGOING SECTIONS 3.1 AND
                    3.2 AND SECTION 4 BELOW ARE INTENDED TO BE ONLY AS BROAD AS
                    IS PERMITTED UNDER THE LAWS OF THE STATE OF NEW JERSEY. IF
                    ANY PORTION OF THESE SECTIONS IS HELD TO BE INVALID UNDER
                    THE LAWS OF THE STATE OF NEW JERSEY, THE INVALIDITY OF SUCH
                    PORTION SHALL NOT AFFECT THE VALIDITY OF THE REMAINING
                    PORTIONS OF THE APPLICABLE SECTIONS.
                  </div>
                </li>
              </ol>,
            ]}
          ></Privacy_TermContent>
          {/* 4 */}
          <Privacy_TermContent
            header={"4. Indemnification"}
            Content={[
              <p>
                To the extent permitted under applicable law, you agree to
                defend, indemnify, and hold harmless the Company Parties from
                and against any claims, actions or demands, including, without
                limitation, reasonable legal and accounting fees, arising or
                resulting from your breach of this Agreement or your access to,
                use or misuse of the Content or Site. The Company shall provide
                notice to you of any such claim, suit, or proceeding. The
                Company reserves the right to assume the exclusive defense and
                control of any matter which is subject to indemnification under
                this section. In such case, you agree to cooperate with any
                reasonable requests assisting the Company’s defense of such
                matter.
              </p>,
            ]}
          ></Privacy_TermContent>
          {/* 5 */}
          <Privacy_TermContent
            header={"5. Termination of the Agreement"}
            Content={[
              <ol style={{ listStyleType: "none" }}>
                <li className="orderListed">
                  <div className="listNumbered">{`5.1`}</div>
                  <div>
                    The Company reserves the right, in its sole discretion, to
                    restrict, suspend, or terminate this Agreement and your
                    access to all or any part of the Site or the Content at any
                    time and for any reason without prior notice or liability.
                    The Company reserves the right to change, suspend, or
                    discontinue all or any part of the Site or the Content at
                    any time without prior notice or liability.
                  </div>
                </li>
                <li className="orderListed">
                  <div className="listNumbered">{`5.2`}</div>
                  <div>
                    Sections 2 (Use of the Site), 3 (Limitation of Liability and
                    Warranty), 4 (Indemnification), and 5 (Termination of
                    Agreement) shall survive the termination of this Agreement.
                  </div>
                </li>
              </ol>,
            ]}
          ></Privacy_TermContent>
          <Privacy_TermContent
            header={"6. User Must Comply with Applicable Laws"}
            Content={[
              <ol style={{ listStyleType: "none" }}>
                <li className="orderListed">
                  <div className="listNumbered">{`6.1`}</div>
                  <div>
                    This Site is hosted in the United States. We make no claims
                    concerning whether the Content may be  downloaded, viewed,
                    or be appropriate for use outside of the United States. If
                    you access the Site or the Content from outside of the
                    United States, you do so at your own risk. Whether inside or
                    outside of the United States, you are solely responsible for
                    ensuring compliance with the laws of your specific
                    jurisdiction.
                  </div>
                </li>
                <li className="orderListed">
                  <div className="listNumbered">{`6.2`}</div>
                  <div>
                    The United States controls the export of products and
                    information. You expressly agree to comply with such
                    restrictions and not to export or re-export any of the
                    Content to countries or persons prohibited under the export
                    control laws. By downloading the Content, you are expressly
                    agreeing that you are not in a country where such export is
                    prohibited or are a person or entity for which such export
                    is prohibited. You are solely responsible for compliance
                    with the laws of your specific jurisdiction regarding the
                    import, export, or re-export of the Content.
                  </div>
                </li>
              </ol>,
            ]}
          ></Privacy_TermContent>
        </div>
      </div>
    </div>
  );
};

export default TermAndContion;
