import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import "./LoginComponent2.css";
import { setMessageErrorLogin } from "../../../store/one-query/chatsSlice";
import { userLoginWithFacebook, userLoginWithGoogle, userLoginWithTwitter, useSigninUser } from "../../../store/Apis/UserApi";
import { emailRegex } from "../../../Helper";

function LoginComponent2() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const dispatch = useAppDispatch();
  const user = useAppSelector((s) => s.User);
  let errorMessageLogin = useAppSelector((s) => s.ChatsState.messageError);
  const navigate = useNavigate();
  const {signinUserMutate,loading} = useSigninUser();
  let darkMode = useAppSelector((state) => state.User.darkMode);
  const [onFocusState, setOnFocusState] = useState(false);
  const [errorMessageRegisterState, setErrorMessageRegisterState] =
    useState("");
  useEffect(() => {
    if (errorMessageLogin) setErrorMessageRegisterState(errorMessageLogin);
  }, [errorMessageLogin]);
  const login = () => {
    let valid = true;

    if (!email) {
      setEmailError("Email is required");
      valid = false;
    } else {
      setEmailError("");
    }

    if (!password) {
      setPasswordError("Password is required");
      valid = false;
    } else {
      setPasswordError("");
    }

    if (valid) {
      signinUserMutate({ userName: email, password });
    }
  };

  useEffect(() => {
    if (user.IsAuthenticated && user.token) navigate("/main-page");
  }, [user.token, user.IsAuthenticated]);
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  useEffect(() => {
    if (email.length > 0) {
      setOnFocusState(false);
    }
  }, [email]);

  let handleOnChangeEmail = (email: string) => {
    setEmailError("");
    if (email == "") {
      setErrorMessageRegisterState("");
      setIsInvalidEmail(false);
    } else {
      if (emailRegex.test(email)) {
        setIsInvalidEmail(false);
      } else {
        setIsInvalidEmail(true);
      }
    }
    setErrorMessageRegisterState("");
    dispatch(setMessageErrorLogin(""));
  };
  const handleChangePassword = (password: string) => {
    setPassword(password);
    setPasswordError("")
    setErrorMessageRegisterState("");
    dispatch(setMessageErrorLogin(""));
  };
  let toggleEye = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleUserLoginWithGoogle = () => {
    dispatch(userLoginWithGoogle("login"));
  };

  const handleUserSigninWithFacebook = () => {
    dispatch(userLoginWithFacebook("login"));
  };

  const handleUserLoginWithTwitter = () => {
    dispatch(userLoginWithTwitter("login"));
  };

  return (
    <>
      <div className="mainLogin">
        <div className="borderLogin">
          <div className="leftPart col-md-6 col-12">
            <div className="ellipse2 ellipse"></div>
            <div className="ellipse1 ellipse"></div>
            <div className="ellipse8 ellipse"></div>
            <div className="ellipse3 ellipse"></div>
            <div className="ellipse6 ellipse"></div>
            <div className="ellipse5 ellipse"></div>
            <div className="ellipse9 ellipse"></div>
            <div className="leftBody2">
              <div className="leftFormContainer">
                <form action="/Login" method="post">
                  <div
                    className="d-flex justify-content-center c-pointer"
                    onClick={() => navigate("/")}
                  >
                    <img
                      src={
                        darkMode
                          ? "/images/GetConversations/Expertex-logo-dark.svg"
                          : "/images/GetConversations/Expertex-logo-light.svg"
                      }
                      className="ExpertexLogoRegister"
                      alt="Expertex_logo"
                    />
                  </div>
                  <div className="m-2 ms-3 w-100  d-flex align-start">
                    <p className="headerLogin ">Sign In</p>
                  </div>
                  <div className="innerFormDiv">
                    <div className="emailPassDiv">
                      <div className="containerLogin">
                        <div>
                          <input
                            type="email"
                            name="email"
                            placeholder="Enter email"
                            className={
                              !isInvalidEmail
                                ? "inputPasswordBorderBlueLogin"
                                : "inputPasswordBorderRedLogin"
                            }
                            onChange={(e) => {
                              setEmail(e.target.value);
                              handleOnChangeEmail(e.target.value);
                            }}
                            onFocus={() => {
                              if (email.length <= 0) setOnFocusState(true);
                            }}
                            required
                          />
                          
                          {isInvalidEmail && !onFocusState ? (
                            <div className="textMessageDiv">
                              {darkMode ? (
                                <img src="/images/icn-error-circle-dark.svg" />
                              ) : (
                                <img src="/images/icn-error-circle.svg" />
                              )}
                              <p className="warningNameRegister ps-2 text-left">
                                Invalid email address
                              </p>
                            </div>
                          ) :emailError ? (
                            <div className="textMessageDiv">
                              {darkMode ? (
                                <img src="/images/icn-error-circle-dark.svg" />
                              ) : (
                                <img src="/images/icn-error-circle.svg" />
                              )}
                              <p className="warningNameRegister ps-2 text-left">
                                {emailError}
                              </p>
                            </div>
                          ): (
                            onFocusState && (
                              <div className="textMessageDiv">
                                <p className="ps-2 text-left">
                                  example@gmail.com
                                </p>
                              </div>
                            )
                          )}
                          {((errorMessageRegisterState.length > 0 &&
                            errorMessageRegisterState ===
                              "User account not activated yet") ||
                            errorMessageRegisterState ===
                              "User doesn't exist") && (
                            <div className="textMessageDiv">
                              {darkMode ? (
                                <img src="/images/icn-error-circle-dark.svg" />
                              ) : (
                                <img src="/images/icn-error-circle.svg" />
                              )}
                              <p className="warningNameRegister ps-2 text-left">
                                {errorMessageRegisterState}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="containerLogin mb-4">
                        <div>
                          <input
                            type={isPasswordVisible ? "text" : "password"}
                            className={
                              !isInvalidEmail
                                ? "inputPasswordBorderBlueLogin"
                                : "inputPasswordBorderRedLogin"
                            }
                            name="password"
                            required
                            placeholder="Password"
                            onChange={(e) =>
                              handleChangePassword(e.target.value)
                            }
                          />
                          <img
                            className="clickable eyeLogo eyePosition"
                            src={
                              isPasswordVisible
                                ? "/Images/LoginResources/icn-open-eye.svg"
                                : "/Images/LoginResources/icn-eye-off.svg"
                            }
                            onClick={() => toggleEye()}
                          />
                        </div>
                        {passwordError && (
                          <div className="textMessageDiv">
                            {darkMode ? (
                              <img src="/images/icn-error-circle-dark.svg" />
                            ) : (
                              <img src="/images/icn-error-circle.svg" />
                            )}
                            <p className="warningNameRegister ps-2 text-left">
                              {passwordError}
                            </p>
                          </div>
                        )}
                        {errorMessageRegisterState === "Incorrect password" && (
                          <div className="textMessageDiv">
                            {darkMode ? (
                              <img src="/images/icn-error-circle-dark.svg" />
                            ) : (
                              <img src="/images/icn-error-circle.svg" />
                            )}
                            <p className="warningNameRegister ps-2 text-left">
                              {errorMessageRegisterState}
                            </p>
                          </div>
                        )}
                        <div className="leftFormContainerForgetPassword mt-3 pb-5 me-4">
                          <Link to="/forget-password">Forget password ?</Link>
                        </div>
                      </div>

                      <input
                        disabled={loading}
                        type="button"
                        className={` ${loading && "opacity-50"} c-pointer w-100`} 
                        value="Sign in"
                        onClick={login}
                      />
                    </div>
                  </div>
                  <div className="container-fluid mt-3 orConatiner">
                    <span className="hrLine col-3"></span>
                    <span className="orName col-6">Or continue with</span>
                    <span className="hrLine col-3"></span>
                  </div>

                  <p className="text-center mt-3">
                    Don't have an account?{" "}
                    <Link className="LinkLogin" to="/account/register">
                       Register here
                    </Link>
                  </p>
                </form>
                <div className="iconsDivRegister mt-3">
                  <button
                    className="iconButton"
                    onClick={handleUserLoginWithGoogle}
                  >
                    <img src="/Images/googleLogo.svg" />
                  </button>
                  <button
                    className="iconButton"
                    onClick={handleUserLoginWithTwitter}
                  >
                    <img src="/Images/twitterLogo.svg" />
                  </button>
                 {/* / <button
                    className="iconButton"
                    onClick={handleUserSigninWithFacebook}
                  >
                    <img src="/Images/facebookLogo.svg" />
                  </button> */}
                  {/* <button className="iconButton">
                    <img src="/Images/appleLogo.svg" />
                  </button> */}
                </div>
              </div>
            </div>
          </div>

          <div className="rightPart2 col-md-6 d-none d-md-block"></div>
        </div>
      </div>
    </>
  );
}

export default LoginComponent2;
