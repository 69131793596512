import React, { useState } from "react";
import {
  tomorrow,
  atomDark,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import "./CopyCode.css";
import { useAppSelector } from "../../../../store/hooks";
import Markdown from "react-markdown";

interface Props {
  response: string;
}
const ChatResponseFormat = (props: Props) => {
  const { response } = props;
  const [isCopied, setIsCopied] = useState(false);
  let darkMode = useAppSelector((state) => state.User.darkMode);
  return (
    response && (
      <div className="containerChatFormat mt-2">
        <Markdown
          components={{
            code({ className, children }: any) {
              const match = /language-(\w+)/.exec(className || "");
              if (match)
                return match ? (
                  <div className="divParent">
                    <hr className="horizentalLine chat-prompt" />
                    <div className="programmingLanguageName">
                      {match[1]}
                    </div>
                    <div className="copyCode">
                      <button
                        className="buttonCopy"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            String(children)?.replace(/\n$/, "")
                          );
                          setIsCopied(true);
                          setTimeout(() => setIsCopied(false), 2500);
                        }}
                      >
                        {!isCopied ? (
                          <>
                            <img src="/images/icn-copy.svg" />
                            <span className="ms-1"> Copy</span>
                          </>
                        ) : (
                          <>
                            <img src="/images/icn-check-priceing.svg" />
                            <span className="ms-1">Copied!</span>
                          </>
                        )}
                      </button>
                    </div>

                    <SyntaxHighlighter
                      customStyle={{
                        borderTop: "35px solid var(--sub_background14)",
                        backgroundColor: "var(--sub_background14)",
                        borderRadius: "0.375rem",
                        fontSize: "14px",
                        zIndex: "2",
                        marginTop: "30px",
                      }}
                      style={darkMode ? atomDark : tomorrow}
                      language={match[1]}
                      children={String(children)?.replace(/\n$/, "")}
                    ></SyntaxHighlighter>
                  </div>
                ) : (
                  <div style={{ marginTop: "50px", backgroundColor: "red" }}>
                    <code className={className ? className : ""}>
                      {children}
                    </code>
                  </div>
                );
            },
          }}
        >
          {response}
        </Markdown>
      </div>
    )
  );
};

export default ChatResponseFormat;
