import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";

import {
  useCreateNewConversationType,
  useDeleteConversationType,
  useUpdateConversationType,
} from "../../../../store/Apis/ConversationTypeApi";
import { setFileError, setSelectedPersona } from "../../../../store/one-query/chatsSlice";

interface PersonaProps {
  show: boolean;
  handleClose: () => void;
  personaModalType: string;
  idItemDelete: string | null;
  updateCurrentPersona: any;
  setSelectedPersonaItem: (persona:string) => void;
}
interface ConversationTypeRecord {
  id: string;
  title: string;
  description: string;
  objective: string;
  createdAt: string;
}
const PersonaModal: React.FC<PersonaProps> = ({
  show,
  handleClose,
  personaModalType,
  idItemDelete,
  updateCurrentPersona,
  setSelectedPersonaItem,
}) => {
  const [editPersona, setEditPersona] = useState<ConversationTypeRecord>({
    id: "",
    title: "",
    description: "",
    objective: "",
    createdAt: "",
  });
  const [isFocused, setIsFocused] = useState(false);
  const [formErrors, setFormErrors] = useState({
    nameError: false,
    desErrors: false,
  });
  const dispatch = useAppDispatch();
  const [personaData, setPersonaData] = useState({
    title: "",
    description: "",
    objective: "",
  });
  const [btnDeleteDisable, setBtnDeleteDisable] = useState(true);
  const [wordDeleteMatch, setWordDeleteMatch] = useState("");
  const createNewConversation = useCreateNewConversationType();
  const deleteConversationType = useDeleteConversationType();
  const updateConversationType = useUpdateConversationType();
  const nameError = (e: boolean) =>
    setFormErrors((prevErrs) => ({ ...prevErrs, nameError: e }));
  const desErrors = (e: boolean) =>
    setFormErrors((prevErrs) => ({ ...prevErrs, desErrors: e }));
  const { title, description, objective } = personaData;
  const currentPersona = useAppSelector(
    (state) => state.ChatsState.selectedPersona
  );

  useEffect(() => {
    if (updateCurrentPersona) {
      setEditPersona(updateCurrentPersona);
    }
  }, [updateCurrentPersona]);

  useEffect(() => {
    if (title.trim() === "") {
      nameError(true);
    } else if (description.trim() === "" || objective.trim() === "") {
      desErrors(true);
      nameError(false);
    } else {
      nameError(false);
      desErrors(false);
    }
  }, [personaData]);

  const handleCloseAddPersona = () => {
    handleClose();
    setPersonaData({ title: "", description: "", objective: "" });
    setFormErrors({ nameError: false, desErrors: false });
  };

  const CreatePersona = (e: any) => {
    setPersonaData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const addNewPersona = () => {
    if (title !== "" && description !== "" && objective !== "") {
      let newPersona = {
        title: title,
        id: null,
        description: description,
        objective: objective,
        createdAt: "",
      };
      createNewConversation(newPersona);
      handleCloseAddPersona();
    } else if (title === "") {
      nameError(true);
    } else {
      desErrors(true);
    }
  };

  const handleCancel = () => {
    handleClose();
    setWordDeleteMatch("");
  };

  const handleDeleteItemWithId = () => {
    if (wordDeleteMatch.toLowerCase() === "delete") {
      setBtnDeleteDisable(false);
      if (idItemDelete) deleteConversationType(idItemDelete);
      setWordDeleteMatch("");
      handleCancel();
    }else{
      dispatch(
        setFileError({
          isError: true,
          errorMsg: `"${wordDeleteMatch}" is misspelled. Please correct it to "delete" and try again.`,
          reason: "clear all Conversation",
        })
      );
    }
    setBtnDeleteDisable(true);
  };

  const handleDeleteItemWithIdUsingEnterKey = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.key === "Enter") handleDeleteItemWithId();
  };

  const handleChangeUpdatePersona = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (editPersona) {
      setEditPersona({
        ...editPersona,
        [e.target.name]: e.target.value,
      });
    }
  };

  const UpdateSpecificPersona = () => {
    if (editPersona) {
      updateConversationType(editPersona);
      if(currentPersona.id === editPersona.id){
        setSelectedPersonaItem(editPersona.title)
        dispatch(setSelectedPersona(editPersona));
      }
      handleCancel();
      setEditPersona({
        id: "",
        title: "",
        description: "",
        objective: "",
        createdAt: "",
      }); // Clear the edit form after updating
    }
  };

  // if user delete persona using id
  return personaModalType === "Delete" ? (
    <Modal
      className="personaModalDelete"
      show={show}
      onHide={handleCloseAddPersona}
      backdrop="static"
      centered
    >
      <Modal.Header>
        <Modal.Title>
          <p className="mb-0" style={{ fontSize: "14px" }}>
            Delete persona
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="personaBodyDelete">
        <img
          src="/Images/Navbar/Canceling your subscription.svg"
          alt="Canceling your subscription"
        />
        <div className="containerFormDelete">
          <p className="deleteConfirmMsg mt-5 mb-3">
            by confirming your persona will be lost, This action can’t be undone
          </p>
          <div className=" w-100">
            <Form.Control
              className="w-75 mt-3 mb-4"
              id="inputPersonaDelete"
              placeholder="Type 'delete' in the box to confirm."
              value={wordDeleteMatch}
              onKeyDown={(e) => handleDeleteItemWithIdUsingEnterKey(e)}
              onChange={(e) => setWordDeleteMatch(e.target.value)}
            />
          </div>
        </div>
        <div className="containerButtons">
          <button
            className={`createPersonaBtn me-3 ${
              btnDeleteDisable ? "disableBtn" : "enableBtn"
            }`}
            onClick={handleDeleteItemWithId}
            disabled={!btnDeleteDisable}
          >
            Confirm
          </button>
          <button className="CancelDeletePersonaBtn" onClick={handleCancel}>
            Cancel
          </button>
        </div>
      </Modal.Body>
    </Modal>
  ) : // if user create new persona
  personaModalType === "Create" ? (
    <Modal
      className="personaModal"
      show={show}
      onHide={handleCloseAddPersona}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="mb-0">Create a new persona</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <label className="personDesLabel" htmlFor="personaName">
          Name
        </label>
        <div
          className={`inputPersonaName w-100 ${
            formErrors.nameError && "personaError"
          }`}
        >
          <Form.Control
            className="w-75"
            id="personaName"
            placeholder="Enter persona name"
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            name="title"
            value={personaData.title}
            onChange={(e) => {
              CreatePersona(e);
            }}
          />
          {isFocused && !formErrors.nameError ? (
            <p className="inputMsg">
              Users will be able to select this Persona based on this name.
            </p>
          ) : (
            ""
          )}
          {formErrors.nameError && (
            <div className="d-flex align-items-center gap-1 mt-1">
              <img className="icn-error" />
              <p className="errorMsg mb-0">Please enter persona name</p>
            </div>
          )}
        </div>
        <label className="personDesLabel" htmlFor="personaDes">
          Description
        </label>
        <div className="desContainer">
          <div
            className={`inputPersonaDescription d-flex flex-column gap-3 w-100 ${
              formErrors.desErrors && "missingInput"
            }`}
          >
            <Form.Control
              id="personaDes"
              placeholder="•  You are an description for a/an ..."
              value={personaData.description}
              name="description"
              onChange={(e) => CreatePersona(e)}
            />
            <textarea
              className="w-100"
              placeholder="•  Your objective is to..."
              value={personaData.objective}
              name="objective"
              onChange={(e) => CreatePersona(e)}
            />
          </div>
          {formErrors.desErrors && (
            <div className="d-flex align-items-center gap-1 mt-1">
              <img className="icn-error" />
              <p className="errorMsg mb-0">
                Please enter the description of your new persona in the given
                order
              </p>
            </div>
          )}
        </div>
        <button className="createPersonaBtn" onClick={addNewPersona}>
          Create
        </button>
      </Modal.Body>
    </Modal>
  ) : (
    // if user update persona using id
    editPersona && (
      <Modal
        className="personaModal"
        show={show}
        onHide={handleCloseAddPersona}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <p className="mb-0">Edit persona</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label className="personDesLabel" htmlFor="personaName">
            Name
          </label>
          <div
            className={`inputPersonaName w-100 ${
              formErrors.nameError && "personaError"
            }`}
          >
            <Form.Control
              className="w-75"
              id="personaName"
              placeholder="Enter persona name"
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              name="title"
              value={editPersona.title}
              onChange={(e) => handleChangeUpdatePersona(e)}
            />
            {/* {isFocused && !formErrors.nameError ? (
          <p className="inputMsg">
            Users will be able to select this Persona based on this name.
          </p>
        ) : (
          ""
        )} */}
            {/* {formErrors.nameError && (
          <div className="d-flex align-items-center gap-1 mt-1">
            <img className="icn-error" />
            <p className="errorMsg mb-0">Please enter persona name</p>
          </div>
        )} */}
          </div>
          <label className="personDesLabel" htmlFor="personaDes">
            Description
          </label>
          <div className="desContainer">
            <div
              className={`inputPersonaDescription d-flex flex-column gap-3 w-100 ${
                formErrors.desErrors && "missingInput"
              }`}
            >
              <Form.Control
                id="personaDes"
                placeholder="•  You are an description for a/an ..."
                value={editPersona.description}
                name="description"
                onChange={(e) => handleChangeUpdatePersona(e)}
              />
              <textarea
                className="w-100"
                placeholder="•  Your objective is to..."
                value={editPersona.objective}
                name="objective"
                onChange={(e) => handleChangeUpdatePersona(e)}
              />
            </div>
            {formErrors.desErrors && (
              <div className="d-flex align-items-center gap-1 mt-1">
                <img className="icn-error" />
                <p className="errorMsg mb-0">
                  Please enter the description of your new persona in the given
                  order
                </p>
              </div>
            )}
          </div>
          <button className="createPersonaBtn" onClick={UpdateSpecificPersona}>
            Save changes
          </button>
        </Modal.Body>
      </Modal>
    )
  );
};

export default PersonaModal;
