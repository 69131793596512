import { EventEmitter } from 'events';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const emitter = new EventEmitter();
export const dispatchErrorMessage = (message: string,status:number,errorMessage:string) => {

  emitter.emit('error',message,status,errorMessage);
};

export const useErrorListener = (callback: (message: string,status:number,errorMessage:string) => void) => {
  
  useEffect(() => {
    emitter.on('error', callback);
    return () => {
      emitter.off('error', callback);
    };
  }, [callback]);
};
