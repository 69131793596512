import React from "react";
import "./Payment.css";
import { useNavigate } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";
import SubscriptionForm from "./SubscribtionForm";

const PaymentPage = () => {
  const navigate = useNavigate();

  const handleBackToMainPage = () => {
    navigate("/main-page");
  };

  return (
    <div className="payment_page">
      <div className="details">
        <div className="details_content">
          <div className="go_back" onClick={handleBackToMainPage}>
            <img
              src="/Images/SettingsSideResources/icn-arrow-left.svg"
              alt=""
            />
            <div className="plan_logo">
              <img className="expertex-logo" />
            </div>
          </div>
          <div className="premium_details">
            <div className="">
              <p className="font_style">Subscribe to expertex premium</p>
              <div className="price">
                <h3>USD$ 20</h3>
                <span className="subscription_period">
                  Per <br />
                  month
                </span>
              </div>
              <p className="font_style">
                Unlimited messages, interactions and history.
              </p>
            </div>
            <div className="premium_plan">
              <div className="d-flex align-items-center w-100 justify-content-between">
                <p className="font_style fw-medium">Expertex premium</p>
                <span className="font_style fw-medium">USD$ 20</span>
              </div>
              <p className="font_style">Billed monthly</p>
            </div>
            <div className="total_subtotal_price">
              <div className="subtotal">
                <p>Subtotal</p>
                <span className="subtotal_price">USD$ 20</span>
              </div>
              <div className="total d-flex justify-content-between w-100">
                <p>Total due today</p>
                <span className="total_price">USD$ 20</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SubscriptionForm />
    </div>
  );
};

export default PaymentPage;
