import Api from "../../features/one-query/services/ApiServices";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  ConversationData,
  conversationsActions,
} from "../one-query/conversationSlice";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleNewConversationRequest, setNewConversation, setSnackBarAlert } from "../one-query/chatsSlice";
interface ConversationCreation {
  title: string;
  ConversationModels: {
    name: string;
    order: number;
    id: string;
  }[];
}
/*
create ConversationRequest Endpoint 
*/

// function to create a ConversationRequest
const createConversationRequest = async ({
  conversationRequest,
  handleSendRequest,
  query,
  attachmentFile,
}: {
  conversationRequest: ConversationCreation | undefined;
  handleSendRequest: any;
  query: string;
  attachmentFile: any;
}) => {
  try {
    const response = await Api.post(
      "Conversation/CreateConversationWithModels",
      JSON.stringify(conversationRequest)
    );

    return response.data;
  } catch (e) {
    console.error("Error creating conversation:", e);
    throw e;
  }
};

// custom hook to create a ConversationRequest using useMutation
const useCreateConversationRequest = () => {
  return useMutation({
    mutationKey: ["createConversationRequest"],
    mutationFn: createConversationRequest,
  });
};

// Hook to create a conversation  by return fun make this query and dispatch data
const useCreateConversation = () => {
  const createConversationMutation = useCreateConversationRequest();
  const dispatch:any = useDispatch();
  const navigate = useNavigate();

  const createConversation = (
    conversation: ConversationCreation | undefined,
    handleSendRequest: any,
    query: string,
    attachmentFile: FileList
  ): Promise<{
    activeConId: string | undefined;
    conversationModelsIds: any[];
  }> => {
    // specify that this function returns a Promise
    return new Promise((resolve, reject) => {
      // Renaming 'conversation' to 'conversationRequest'
      createConversationMutation.mutate(
        {
          conversationRequest: conversation,
          handleSendRequest,
          query,
          attachmentFile,
        },
        {
          onSuccess: (data) => {
            dispatch(conversationsActions.setIsNewConversation(false));
            dispatch(setNewConversation(false));
            dispatch(conversationsActions.newConversationSuccess(data));
            dispatch(conversationsActions.setActiveConversation(data?.id));

            let conversationModelsIds = [];
            let activeConId = data?.id;
            let conversationConversationModels =
              data?.conversationConversationModels;

            for (let i = 0; i < conversationConversationModels?.length; i++) {
              conversationModelsIds.push(conversationConversationModels[i].id);
            }
            handleSendRequest(
              query,
              attachmentFile,
              conversationModelsIds,
              activeConId
            );
            dispatch(
              conversationsActions.setConversationModelsIDs(
                conversationModelsIds
              )
            );

            resolve({ activeConId, conversationModelsIds });
            navigate(`/conversation/${activeConId}`);
          },
          onError: (error) => {
            reject(error); // reject the Promise with the error
            dispatch(
              setSnackBarAlert({
                show: true,
                alertType: "warning",
                message: "Internet connection is unstable",
              })
            );
            dispatch(handleNewConversationRequest());
          },
        }
      );
    });
  };

  return createConversation;
};

// ========================================================================================================
/*
Get ConversationRequest Endpoint 
*/

// function to get a ConversationRequest
const getConversationRequest = async () => {
  try {
    const response = await Api.get("Conversation");
    return response.data;
  } catch (e) {}
};

// custom hook to get a ConversationRequest using useQuery
const useGetConversationRequest = () => {
  return useQuery({
    queryKey: ["getConversationRequest"],
    queryFn: getConversationRequest,
    enabled: false,
  });
};

// Hook to get a conversation by return fun make this query and dispatch data
const useGetConversationHistory = () => {
  const { error, refetch } = useGetConversationRequest();
  const dispatch = useDispatch();

  const getConversationHistory = async () => {
    const { data } = await refetch();
    if (data) {
      dispatch(conversationsActions.getConversationSuccess(data));
    }
    if (error) {
      dispatch(conversationsActions.getConversationError(error.message));
    }
    dispatch(conversationsActions.createConversationRequest());
  };

  return getConversationHistory;
};
// ========================================================================================================

/*
Delete ConversationRequest Endpoint 
*/

// function to delete a ConversationRequest
const deleteConversationRequest = async (id: string) => {
  try {
    const response = await Api.delete(`Conversation/${id}`);
    return response.data;
  } catch (e) {}
};

// custom hook to delete a ConversationRequest using useMutation
const useDeleteConversationRequest = () => {
  return useMutation({
    mutationKey: ["deleteConversationRequest"],
    mutationFn: deleteConversationRequest,
  });
};

// hook to delete a conversation by return fun make this query and dispatch data
const useDeleteConversation = () => {
  const queryClient = useQueryClient();
  const deleteConversationMutation = useDeleteConversationRequest();
  const dispatch = useDispatch();
  const deleteConversation = (id: string) => {
    deleteConversationMutation.mutate(id, {
      onSuccess: (data) => {
        dispatch(conversationsActions.deleteConversationSuccess(id));
        queryClient.invalidateQueries({ queryKey: ["getConversationRequest"] });
      },
      onError: (error) => {
        dispatch(conversationsActions.deleteConversationError(error.message));
      },
    });
  };
  return deleteConversation;
};
// ========================================================================================================

/*
Update ConversationRequest Endpoint 
*/

// function to update a ConversationRequest
const updateConversationRequest = async (
  ConversationRequest: ConversationData
) => {
  try {
    const response = await Api.patch(
      `Conversation/${ConversationRequest.id}`,
      JSON.stringify(ConversationRequest)
    );
    return response.data;
  } catch (e) {}
};

// custom hook to update a ConversationRequest using useMutation
const useUpdateConversationRequest = () => {
  return useMutation({
    mutationKey: ["updateConversationRequest"],
    mutationFn: updateConversationRequest,
  });
};

// hook to update a conversation by return fun make this query and dispatch data
const useUpdateConversation = () => {
  const queryClient = useQueryClient();
  const updateConversationMutation = useUpdateConversationRequest();
  const dispatch = useDispatch();
  const updateConversation = (conversation: ConversationData) => {
    updateConversationMutation.mutate(conversation, {
      onSuccess: (data) => {
        dispatch(conversationsActions.updateConversationSuccess(data));
        queryClient.invalidateQueries({ queryKey: ["getConversationRequest"] });
      },
      onError: (error) => {
        dispatch(conversationsActions.updateConversationError(error.message));
      },
    });
  };
  return updateConversation;
};
// ========================================================================================================

//Update Persona in anonnymonus

const updateConversationAnnonymonusRequest = async (
  ConversationRequest: ConversationData
) => {
  try {
    const response = await Api.patch(
      `AnonymousConversation/Patch?id=${ConversationRequest.id}`,
      JSON.stringify(ConversationRequest)
    );
    return response.data;
  } catch (e) {}
};

// custom hook to update a ConversationRequest using useMutation
const useUpdateConversationAnnonymonusRequest = () => {
  return useMutation({
    mutationKey: ["updateConversationAnnonymonusRequest"],
    mutationFn: updateConversationAnnonymonusRequest,
  });
};

// hook to update a conversation by return fun make this query and dispatch data
const useUpdateConversationAnnonyPersona = () => {
  const queryClient = useQueryClient();
  const updateConversationMutation = useUpdateConversationAnnonymonusRequest();
  const dispatch = useDispatch();
  const updateConversation = (conversation: ConversationData) => {
    updateConversationMutation.mutate(conversation, {
      onSuccess: (data) => {
        dispatch(conversationsActions.updateConversationSuccess(data));
        queryClient.invalidateQueries({ queryKey: ["getConversationRequest"] });
      },
      onError: (error) => {
        dispatch(conversationsActions.updateConversationError(error.message));
      },
    });
  };
  return updateConversation;
};

/*
Clear All ConversationRequest Endpoint 
*/

// function to clear all ConversationRequests
const clearAllConversationRequests = async () => {
  try {
    const response = await Api.post(`Conversation/ClearAllConversation`);
    return response.data;
  } catch (e) {}
};

// custom hook to clear all ConversationRequests using useMutation
const useClearAllConversationRequests = () => {
  return useMutation<Error>({
    mutationKey: ["clearAllConversationRequests"],
    mutationFn: clearAllConversationRequests,
  });
};

// hook to clear all conversations by return fun make this query and dispatch data
const useClearAllConversations = () => {
  const clearAllConversationMutation = useClearAllConversationRequests();
  const dispatch = useDispatch();
  const clearAllConversations = () => {
    clearAllConversationMutation.mutate(undefined, {
      onSuccess: () => {
        dispatch(conversationsActions.clearAllConversations());
      },
      onError: (error) => {
        dispatch(conversationsActions.deleteConversationError(error.message));
      },
    });
  };
  return clearAllConversations;
};

// ======================================================================================

// Define response type (if you know the shape, replace `any` with the correct type)

// Define the error structure (if applicable)

// Edit message request function
const editMessageRequest = async (
  messageId: string,
  newPrompt: string,
  connectionId: string
) => {
  try {
    const encodedPrompt = encodeURIComponent(newPrompt);
    const response = await Api.get(
      `Chat/EditMessage?messageId=${messageId}&newPrompt=${encodedPrompt}&connectionId=${connectionId}`
    );
    return response.data;
  } catch (error: any) {
    console.error("Error editing message:", error);
  }
};

// Hook to query the edit message
const useEditMessageRequest = () => {
  return useMutation({
    mutationKey: ["editMessageRequest"],
    mutationFn: ({
      messageId,
      newPrompt,
      connectionId,
    }: {
      messageId: string;
      newPrompt: string;
      connectionId: string;
    }) => editMessageRequest(messageId, newPrompt, connectionId),
  });
};

// Hook to return the function to edit message

const useEditMessage = () => {
  const editMessageRequest = useEditMessageRequest();
  const [loading, setLoading] = useState(false);

  const editMessageMutate = (
    messageId: string,
    newPrompt: string,
    connectionId: string
  ) => {
    setLoading(true);
    editMessageRequest
      .mutateAsync({
        messageId,
        newPrompt,
        connectionId,
      })
      .then((data) => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return { editMessageMutate, loading };
};
// Function to get last active models
const getLastActiveModels = async (id: string) => {
  try {
    const response = await Api.post(`Conversation/${id}/GetActiveModels`);
    return response.data;
  } catch (e) {
    throw e;
  }
};

// Custom hook to handle the mutation
const useGetLastActiveModelsRequest = () => {
  return useMutation({
    mutationKey: ["getLastActiveModels"],
    mutationFn: getLastActiveModels,
  });
};

// Hook to clear all conversations and return a function to make the query
const useGetLastActiveModels = () => {
  const { mutate } = useGetLastActiveModelsRequest();
  const dispatch = useDispatch();
  const [loading, setIsLoading] = useState(false);
  const getLastActiveModels = (id: string) => {
    setIsLoading(true);
    mutate(id, {
      onSuccess: (res) => {
        setIsLoading(false);
        dispatch(conversationsActions.getLastActiveModels(res));
      },
      onError: (e) => {
        setIsLoading(false);
        const ifIdNotValid = e.message.includes(
          "Request failed with status code 400"
        );
        if (ifIdNotValid) {
          dispatch(
            setSnackBarAlert({
              show: true,
              message: "This conversation doesn't exist.",
              alertType: "warning",
            })
          );
        }
      },
    });
  };

  return { getLastActiveModels, loading };
};

export default useGetLastActiveModels;

// ======================================================================================

export {
  useCreateConversation,
  useGetConversationHistory,
  useDeleteConversation,
  useUpdateConversation,
  useUpdateConversationAnnonyPersona,
  useClearAllConversations,
  useEditMessage,
};
