import SignalRService from "../../../../one-query/services/SignalRService";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { Card } from "react-bootstrap";
import Skeleton from "./Skeleton";
import "./HelpAndSupport.css";
import ChatResponseFormat from "../../get-conversation/ChatResponseFormat";

interface SummaryProps {
  isLoading: boolean;
  setIsLoading: (loadingStatus: boolean) => void;
  currentPage: number;
  handleSearchWithSuggestion: (question: string) => void;
  response: string;
  setResponse: (res: any) => void;
  handelSendRequest: (query: string, getConnectionId: string | null) => void;
  inputValue: string;
}

const SummaryAndSuggestion = ({
  isLoading,
  setIsLoading,
  handleSearchWithSuggestion,
  currentPage,
  response,
  setResponse,
  handelSendRequest,
  inputValue,
}: SummaryProps) => {
  const responseContainerRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const [finished, setIsFinished] = useState(false);
  const [expandSummery, setExpandSummery] = useState(false);

  const PopularQuestions = [
    {
      question: "How do I update my profile information?",
    },
    {
      question: "What payment methods are accepted?",
    },
    {
      question: "How do I use a persona?",
    },
  ];

  const handleExpandSummery = () => {
    setExpandSummery(!expandSummery);
  };

  useEffect(() => {
    if (titleRef.current) {
      if (currentPage > 1) {
        titleRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [currentPage]);

  const handleSendSuggestionQuestion = (question: string) => {
    handleSearchWithSuggestion(question);
  };

  const handleModelMessage = useCallback(
    (tag: string, message: string, connectionId: string) => {
      if (message === "**/**Finished**/**") {
        setIsFinished(true);
      } else {
        if (message != null) {
          setResponse((e: any) => (e ? e + message : message));
          if (isLoading) {
            setIsLoading(false);
          }
        }
        setIsFinished(false);
      }
    },
    [isLoading]
  );

  const setupSignalRConnection = useCallback(() => {
    const connection = SignalRService.getInstance().connection;
    connection.on("SendChatGPTMessage", handleModelMessage);
    return () => {
      connection.off("SendChatGPTMessage", handleModelMessage);
    };
  }, [handleModelMessage]);

  useEffect(() => {
    const cleanup = setupSignalRConnection();
    return () => {
      cleanup();
    };
  }, [setupSignalRConnection]);

  const runAutoScroll = () => {
    if (responseContainerRef.current) {
      if (
        responseContainerRef.current.scrollTop !=
        responseContainerRef.current.scrollHeight
      ) {
        responseContainerRef.current.scrollTop =
          responseContainerRef.current.scrollHeight;
      }
    }
  };

  useEffect(() => {
    runAutoScroll();
  }, [response]);

  const getConnectionId = () => {
    return SignalRService.getInstance().connection.connectionId;
  };

  const handleSearchWithSuggestionQuestion = (query: string) => {
    setResponse("");
    setIsLoading(true);
    handleSendSuggestionQuestion(query);
    handelSendRequest(query, getConnectionId());
  };

  return (
    <div>
      <div className="summary-container d-flex flex-column">
        <Card className="summary-card mb-4">
          <Card.Header
            className={`summary-header ${
              response?.length !== 0 && "res-loading"
            }`}
          >
            <span> {response?.length !== 0 ? "Summery" : ""}</span>
            <div>
              {response?.length !== 0 ? (
                <img
                  onClick={handleExpandSummery}
                  className={`${
                    expandSummery ? "minimiz-icon" : "expand-icon"
                  }`}
                />
              ) : null}
            </div>
          </Card.Header>
          <Card.Body
            ref={responseContainerRef}
            className={`summary-body ${expandSummery && "expand-summery"}`}
          >
            {isLoading ? (
              Array.from({ length: 6 }).map((_, i) => (
                <Skeleton
                  key={i}
                  width={
                    i === 0
                      ? "85%"
                      : i === 1
                      ? "65%"
                      : i === 2
                      ? "75%"
                      : i === 3
                      ? "95%"
                      : "75%"
                  }
                  height={`${i <= 4 ? "20px" : "5px"}`}
                  borderRadius="15px"
                  margin="mb-2"
                />
              ))
            ) : (
              <div className="help-response">
                <ChatResponseFormat response={response} />
              </div>
            )}
          </Card.Body>
        </Card>
      </div>
      <div className="suggestion_questions">
        <div ref={titleRef}>
          {isLoading ? (
            <Skeleton
              width="121px"
              height="14px"
              margin="mb-4"
              borderRadius="20px"
            />
          ) : (
            <h3>People also asked</h3>
          )}
        </div>
        <div className="people_questions">
          {PopularQuestions.map((item, i) => (
            <div
              key={i}
              className="suggestion_question"
              onClick={() => handleSearchWithSuggestionQuestion(item.question)}
            >
              {isLoading ? (
                <Skeleton
                  width="24px"
                  height="24px"
                  borderRadius="50%"
                  margin="m-0"
                />
              ) : (
                <img src="./Images/SettingsSideResources/search_icn.svg" />
              )}
              {isLoading ? (
                <Skeleton
                  width={i === 0 ? "45%" : i === 1 ? "55%" : "75%"}
                  height="14px"
                  borderRadius="17px"
                  margin="ms-2"
                />
              ) : (
                <p>{item.question}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SummaryAndSuggestion;
