import React, { useEffect, useState } from "react";
import "./SideBar.css";
import ToggleTheme from "../../main-page/components/get-conversation/ToggleTheme";
import {
  handleNewConversationRequest,
  regenerateResponse,
  setPopupConShown,
  setUpLoadedFile,
} from "../../../store/one-query/chatsSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { conversationsActions } from "../../../store/one-query/conversationSlice";
import { useNavigate } from "react-router-dom";
import SignToSavePopup from "./SignToSavePopup";
function SideBar() {
  const isPopupConShown = useAppSelector((s) => s.ChatsState.popupSaveConShown);
  const isNewConversation = useAppSelector((s) => s.ChatsState.newConversation);
  const isAuthenticated = useAppSelector((s) => s.User.IsAuthenticated);
  const isFullWidth = useAppSelector((state) => state.ChatsState.fullWidthMode);
  const newConversation = useAppSelector(
    (state) => state.ChatsState.newConversation
  );
  const [showNewConversationPopup, setShowNewConversationPopup] =
    useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/main-page");
    }
    handleNewConversation();
  }, [isAuthenticated]);

  const handleNewConversation = () => {
    dispatch(setUpLoadedFile(false));
    dispatch(regenerateResponse(false));
    dispatch(handleNewConversationRequest());
    dispatch(conversationsActions.removeActiveConversation());
  };

  const handleNewConversationClick = () => {
    if (!isPopupConShown && !isNewConversation) {
      setShowNewConversationPopup(true);
      dispatch(setPopupConShown(true));
    } else {
      handleNewConversation();
    }
  };

  return (
    <>
      <div
        className={`LandingSideBar ${
          isFullWidth !== "" && isFullWidth !== "ExpertHome" ? "d-none" : ""
        }`}>
        <div className="Logo">
          <img className="expertex-logo" />
        </div>
        <div className="sideBarContainer">
          <div
            className={`newConversation ${newConversation && "new"}`}
            onClick={handleNewConversationClick}>
            <div className="text d-flex align-items-center gap-3 c-pointer">
              <img
                className="new-conversation-icon"
                src="/images/New Chat Icon.svg"
              />
              <p className="titleFont mb-0">New conversation</p>
            </div>
          </div>
          <div>
            <ToggleTheme />
            <div className="RegisterOrSignIn">
              <div className="text">
                <h3>Register or sign in</h3>
                <p>Save your chat history and personalize your experience.</p>
              </div>
              <div className="buttons">
                <button className="registerBtn">
                  <a href="account/register">Register</a>
                </button>
                <button className="signInBtn">
                  <a href="/account/login">Sign in</a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SignToSavePopup
        show={showNewConversationPopup}
        handleClose={() => setShowNewConversationPopup(false)}
      />
    </>
  );
}

export default SideBar;
