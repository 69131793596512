import React from "react";
import ChatResponseFormat from "./ChatResponseFormat";

interface StreamingChatResponse {
  response: string;
  modelName: string;
}

const StreamingChatResponse = ({
  response,
  modelName,
}: StreamingChatResponse) => {
  const capitalizeWords = () => {
    if (modelName === "MistralAi" || modelName === "MistralAi2")
      return "MistralAi";
    else return modelName?.replace(/ai/gi, "Ai")?.replace(/gpt/gi, "GPT");
  };
  return (
    <div>
      <div className="chatResponse position-relative mb-1">
        <div
          id="chat-details"
          className="d-flex align-items-center justify-content-between"
        >
          <div
            className={`d-flex align-items-center mb-1 justify-content-center gap-2`}
          >
            <img className={`${modelName}Logo`} />
            <p className="chatName mb-0">{capitalizeWords()}</p>
          </div>
        </div>
        <div className="response position-relative w-100">
          <div className="responseText">
            <ChatResponseFormat response={response} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StreamingChatResponse;
