import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useAppDispatch } from "../../../store/hooks";
import {
  handleNewConversationRequest,
  regenerateResponse,
  setUpLoadedFile,
} from "../../../store/one-query/chatsSlice";
import { conversationsActions } from "../../../store/one-query/conversationSlice";
import { useNavigate } from "react-router-dom";

interface PopupProps {
  show: boolean;
  handleClose: () => void;
  popupType?: string;
}
const SignToSavePopup: React.FC<PopupProps> = ({
  handleClose,
  show,
  popupType,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const newConversation = () => {
    dispatch(setUpLoadedFile(false));
    dispatch(regenerateResponse(false));
    dispatch(handleNewConversationRequest());
    dispatch(conversationsActions.removeActiveConversation());
    handleClose();
  };

  return (
    <Modal className="DeleteModal" show={show} onHide={handleClose} centered>
      <Modal.Header closeButton className="clearConPopupHeader border-0">
        <Modal.Title>
          {popupType === "Create_Persona"
            ? "Create persona"
            : "New conversation"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={`DeleteModalBody border-0 px-4 rounded-bottom-4`}>
        <div className="d-flex flex-column align-items-center">
          <img
            src="/images/GetConversations/icn-cancel-subscription.svg"
            className="mb-4 pt-2"
          />
          {popupType === "Create_Persona" ? (
            <div className="popupSignToSaveText my-4">
              <p>To create a new persona you need to</p>
              <a href="/account/login">log in</a> or
              <a href="/account/register"> Register</a>
            </div>
          ) : (
            <div className="popupSignToSaveText my-4">
              <p>Your current conversation will no longer be accessible.</p>
              <a href="/account/register">Register</a> or{" "}
              <a href="/account/login">log in</a> to save conversation
            </div>
          )}

          <div className="d-flex pb-3 buttons">
            {popupType === "Create_Persona" ? (
              <button
                className="confirmDeleteBtn me-2"
                onClick={() => navigate("/account/login")}
              >
                Login
              </button>
            ) : (
              <button
                className="confirmDeleteBtn me-2"
                onClick={newConversation}
              >
                New{" "}
              </button>
            )}
            <button className="cancelDeleteBtn" onClick={handleClose}>
              Cancel
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SignToSavePopup;
