import React, { useEffect, useState } from "react";
import "./RegisterComponent.css";
import "../../../features/main-page/components/SettingPages/ChangePassword.css";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  useRegisterUser,
  userLoginWithFacebook,
  userLoginWithGoogle,
  userLoginWithTwitter,
} from "../../../store/Apis/UserApi";
import { Link, useNavigate } from "react-router-dom";
import { setMessageErrorLogin } from "../../../store/one-query/chatsSlice";
import {
  changePasswordRegex,
  emailRegex,
  nameRegex,
  passwordRegex,
} from "../../../Helper";
import { lowerCaseLetterRegex, numericCharacterRegex, specialCharacterRegex, upperCaseLetterRegex } from "../../../HelperPasswordRegex";
interface IFormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
}
const RegisterPage = () => {
  const { registerUserMutate, loading } = useRegisterUser();
  const dispatch = useAppDispatch();
  const user = useAppSelector((s) => s.User);
  const [formValues, setFormValues] = useState<IFormValues>({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const darkMode = useAppSelector((state) => state.User.darkMode);
  const [isFirstNameValid, setIsFirstNameValid] = useState(false);
  const [isLastNameValid, setIsLastNameValid] = useState(false);
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [messageNewPasswordError, setMessageNewPasswordError] = useState("");
  const [warningMessageNewPassword, setWarningMessageNewPassword] =
    useState(false);
  const [messageConfirmPasswordError, setMessageConfirmPasswordError] =
    useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [warningMessageconfirmation, setWarningMessageconfirmation] =
    useState(false);
  const [onFocusState, setOnFocusState] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const navigate = useNavigate();

  let errorMessageRegister = useAppSelector((s) => s.ChatsState.messageError);
  const [errorMessageRegisterState, setErrorMessageRegisterState] =
    useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [validationErrorStateFirstName, setValidationErrorStateFirstName] =
    useState<string>("");
  const [validationErrorStateLastName, setValidationErrorStateLastName] =
    useState<string>("");
  useEffect(() => {
    if (errorMessageRegister[0] === "The email already exists") {
      setErrorMessageRegisterState(errorMessageRegister[0]);
    }
  }, [errorMessageRegister]);

  useEffect(() => {
    if (isInvalidEmail && !onFocusState) setErrorMessageRegisterState("");
    dispatch(setMessageErrorLogin(""));
    if (onFocusState) setErrorMessageRegisterState("");
    dispatch(setMessageErrorLogin(""));
  }, [isInvalidEmail, onFocusState]);

  useEffect(() => {
    if (formValues.firstName.length > 0) {
      setIsFirstNameValid(false);
    }
    if (formValues?.firstName.length === 0 && isFirstNameValid) {
      setValidationErrorStateFirstName("Please enter your first name");
    } else if (
      !nameRegex.test(formValues.firstName.trim()) &&
      formValues?.firstName.length > 0
    ) {
      setValidationErrorStateFirstName("Enter characters only");
    } else {
      setValidationErrorStateFirstName("");
    }
  }, [formValues.firstName, isFirstNameValid]);

  useEffect(() => {
    if (formValues.lastName.length > 0) {
      setIsLastNameValid(false);
    }
    if (formValues?.lastName.length === 0 && isLastNameValid) {
      setValidationErrorStateLastName("Please enter your last name");
    } else if (
      !nameRegex.test(formValues.lastName.trim()) &&
      formValues?.lastName.length > 0
    ) {
      setValidationErrorStateLastName("Enter characters only");
    } else {
      setValidationErrorStateLastName("");
    }
  }, [formValues.lastName, isLastNameValid]);
  useEffect(() => {
    if (formValues.email.length > 0) {
      setOnFocusState(false);
    }
  }, [formValues.email]);

  useEffect(() => {
    if (formValues.password.length > 1) {
      setWarningMessageNewPassword(false);
      if (changePasswordRegex.test(formValues.password))
        setMessageNewPasswordError("Great, all done.");
      else if (!upperCaseLetterRegex.test(formValues.password)) {
        setMessageNewPasswordError("Password must contain an uppercase letter");
        setMessageConfirmPasswordError("");
      } else if (!lowerCaseLetterRegex.test(formValues.password)) {
        setMessageNewPasswordError("Password must contain a lowercase letter");
        setMessageConfirmPasswordError("");
      } else if (!numericCharacterRegex.test(formValues.password)) {
        setMessageNewPasswordError("Password must contain a numeric character");
        setMessageConfirmPasswordError("");
      } else if (!specialCharacterRegex.test(formValues.password)) {
        setMessageNewPasswordError("Password must contain a special character");
        setMessageConfirmPasswordError("");
      } else if (formValues.password.length <= 8) {
        setMessageNewPasswordError("Password must be at least 8 characters");
        setMessageConfirmPasswordError("");
      }
      else{
        setMessageNewPasswordError("");
      }
    }
    if (changePasswordRegex.test(formValues.password)) {
      if (formValues.confirmPassword.length > 1) {
        setWarningMessageconfirmation(false);
        if (formValues.confirmPassword.length <= 1)
          setMessageConfirmPasswordError("Confirm password is required");
        else if (!(formValues.confirmPassword === formValues.password))
          setMessageConfirmPasswordError("Passwords do not match");
        else if (
          formValues.confirmPassword === formValues.password &&
          messageNewPasswordError === "Great, all done."
        )
          setMessageConfirmPasswordError("Great, passwords matched");
      } else {
        setMessageConfirmPasswordError("");
      }
    }
  }, [formValues.confirmPassword, formValues.password, messageNewPasswordError,]);

  useEffect(() => {
    if (
      nameRegex.test(formValues.firstName.trim()) &&
      nameRegex.test(formValues.lastName.trim()) &&
      emailRegex.test(formValues.email) &&
      changePasswordRegex.test(formValues.password) &&
      formValues.password === formValues.confirmPassword
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [formValues]);

  const handleOnChangeEmail = (email: string) => {
    formValues.email = email;
    if (email == "") {
      setIsInvalidEmail(false);
    } else {
      if (emailRegex.test(email)) {
        setIsInvalidEmail(false);
      } else {
        setIsInvalidEmail(true);
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isDisabled) {
      const { firstName, lastName, email, password } = formValues;
      const user = {
        firstName,
        lastName,
        userName: email,
        password,
      };
      // registerUser(user);
      setIsClicked(true);
    }
  };

  const handleFocusFirstName = () => {
    if (formValues.firstName.length <= 0) {
      setIsFirstNameValid(true);
    }
  };

  const handleFocuslastName = () => {
    if (formValues.lastName.length <= 0) {
      setIsLastNameValid(true);
    }
  };

  const handleUserLoginWithGoogle = () => {
    dispatch(userLoginWithGoogle("register"));
  };

  const handleUserSigninWithFacebook = () => {
    dispatch(userLoginWithFacebook("register"));
  };

  const handleUserLoginWithTwitter = () => {
    dispatch(userLoginWithTwitter("register"));
  };

  const handleRegister = (e: any) => {
    e.preventDefault();
    let isFormValid = true;

    // Validate first name
    if (!nameRegex.test(formValues.firstName.trim())) {
      setIsFirstNameValid(true);
      isFormValid = false;
    } else {
      setIsFirstNameValid(false);
    }

    // Validate last name
    if (!nameRegex.test(formValues.lastName.trim())) {
      setIsLastNameValid(true);
      isFormValid = false;
    } else {
      setIsLastNameValid(false);
    }

    // Validate email
    if (!emailRegex.test(formValues.email)) {
      setIsInvalidEmail(true);
      isFormValid = false;
    } else {
      setIsInvalidEmail(false);
    }

    // Validate password
    if (!changePasswordRegex.test(formValues.password)) {
      setWarningMessageNewPassword(true);
      isFormValid = false;
    } else {
      setWarningMessageNewPassword(false);
    }

    // Validate confirm password
    if (formValues.password !== formValues.confirmPassword) {
      setWarningMessageconfirmation(true);
      setMessageConfirmPasswordError("Passwords do not match");
      isFormValid = false;
    } else {
      setWarningMessageconfirmation(false);
      setMessageConfirmPasswordError("");
    }

    if (isFormValid) {
      const { firstName, lastName, email, password } = formValues;
      const user = {
        firstName,
        lastName,
        userName: email,
        password,
      };
      registerUserMutate(user);
      setIsClicked(true);
      localStorage.setItem("confirmUserEmail", email);
    }
  };

  return (
    <div className="mainRegister">
      <div className="borderRegister">
        <div className="leftPart col-md-6">
          <div className="ellipse2 ellipse z-n1"></div>
          <div className="ellipse1 ellipse"></div>
          <div className="ellipse8 ellipse"></div>
          <div className="ellipse3 ellipse"></div>
          <div className="ellipse6 ellipse"></div>
          <div className="ellipse5 ellipse"></div>
          <div className="ellipse9 ellipse"></div>
          <div className="container-fluid h-100">
            <div className="row h-100" style={{ overflowY: "scroll" }}>
              <div className=" divRegisterForm col-12 ">
                <form
                  className="d-flex flex-column align-items-center"
                  style={{ width: "70%" }}
                  onSubmit={handleSubmit}
                >
                  <img
                    className="ExpertexLogoRegister c-pointer"
                    alt="Expertex_logo"
                    onClick={() => navigate("/")}
                  />
                  <div className="m-2 ms-3 w-100  d-flex align-start">
                    <p className="headerRegister">Create new account</p>
                  </div>
                  <div className="innerFormDiv w-100">
                    <div className="name_container">
                      <div className="name_input col-12 col-md-5 ">
                        <input
                          type="text"
                          name="firstName"
                          value={formValues.firstName}
                          placeholder="First name"
                          onChange={handleChange}
                          className={
                            !isFirstNameValid
                              ? "inputPasswordBorderBlue"
                              : "inputPasswordBorderRed"
                          }
                          onFocus={() => handleFocusFirstName()}
                        />
                        {validationErrorStateFirstName?.length > 0 && (
                          <div className=" ms-1 d-flex">
                            <img className="icn_error" />

                            <div className="warningNameRegister ps-2 text-left">
                              {validationErrorStateFirstName}
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="name_input col-12 col-md-5 ">
                        <input
                          type="text"
                          name="lastName"
                          value={formValues.lastName}
                          placeholder="Last name"
                          onChange={handleChange}
                          className={
                            !isLastNameValid
                              ? "inputPasswordBorderBlue"
                              : "inputPasswordBorderRed"
                          }
                          onFocus={() => handleFocuslastName()}
                        />
                        {validationErrorStateLastName.length > 0 && (
                          <div className=" ms-1 d-flex">
                            <img className="icn_error" />
                            <div className="warningNameRegister ps-2 text-left">
                              {validationErrorStateLastName}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="containerShowPasswordRegisterr">
                      <div>
                        <input
                          type="email"
                          name="email"
                          placeholder="Enter email"
                          className={
                            !isInvalidEmail
                              ? "inputPasswordBorderBlue"
                              : "inputPasswordBorderRed"
                          }
                          onChange={(e) => {
                            handleChange;
                            handleOnChangeEmail(e.target.value);
                            setErrorMessageRegisterState("");
                          }}
                          onFocus={() => {
                            if (formValues.email.length <= 0)
                              setOnFocusState(true);
                          }}
                        />
                        {isInvalidEmail && !onFocusState ? (
                          <div className="textMessageDiv">
                            <img className="icn_error" />

                            <p className="warningNameRegister ps-2 text-left">
                              Invalid email address
                            </p>
                          </div>
                        ) : (
                          onFocusState && (
                            <div className="textMessageDiv">
                              <p className="ps-2 text-left">
                                example@gmail.com
                              </p>
                            </div>
                          )
                        )}
                        {errorMessageRegisterState ===
                          "The email already exists" && (
                          <div className="textMessageDiv">
                            <img className="icn_error" />
                            <p className="warningNameRegister ps-2 text-left">
                              {errorMessageRegisterState}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* input New Password */}
                    <div className="containerShowPasswordRegisterr ">
                      <input
                        type={showNewPassword ? "text" : "password"}
                        value={formValues?.password}
                        name="password"
                        className={
                          messageNewPasswordError.length > 1 &&
                          formValues.password.length > 0 &&
                          messageNewPasswordError !== "Great, all done."
                            ? "inputPasswordBorderRed"
                            : "inputPasswordBorderBlue"
                        }
                        placeholder="New password"
                        onChange={handleChange}
                        onFocus={() => {
                          setWarningMessageNewPassword(true);
                        }}
                        onBlur={() => {
                          setWarningMessageNewPassword(false);
                        }}
                      />
                      <img
                        className="clickable eyeLogo eyePosition"
                        src={
                          showNewPassword
                            ? "/Images/icn-open-eye.svg"
                            : "/Images/icn-eye-off.svg"
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setShowNewPassword(!showNewPassword);
                        }}
                      />
                      {warningMessageNewPassword &&
                        formValues.password.length < 1 && (
                          <div className=" ms-1 d-flex">
                            <img src="/images/icn-warning.svg" />
                            <div className="warningMessagePassword ps-2 text-left">
                              Password must contain an uppercase letter, a
                              lowercase letter, a special character, and a
                              numeric character.
                            </div>
                          </div>
                        )}
                      {formValues.password.length > 0 &&
                        warningMessageNewPassword &&
                        messageNewPasswordError.length > 1 && (
                          <div className=" ms-1 d-flex mt-1">
                            {messageNewPasswordError === "Great, all done." ? (
                              <img src="/images/check_circle.svg" />
                            ) : (
                              <img className="icn_error" />
                            )}
                            <div
                              className="warningMessagePassword ps-2 text-left"
                              style={
                                messageNewPasswordError === "Great, all done."
                                  ? { color: "#12B6EA" }
                                  : {
                                      color: "var(--font-color12)",
                                    }
                              }
                            >
                              {messageNewPasswordError}
                            </div>
                          </div>
                        )}
                    </div>
                    {/* input Confirm Password */}
                    <div className="containerShowPasswordRegisterr ">
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        value={formValues.confirmPassword}
                        name="confirmPassword"
                        placeholder="Confirm password"
                        className={
                          messageConfirmPasswordError.length > 1 &&
                          formValues.confirmPassword.length > 0 &&
                          messageConfirmPasswordError !==
                            "Great, passwords matched"
                            ? "inputPasswordBorderRed"
                            : "inputPasswordBorderBlue"
                        }
                        onChange={handleChange}
                        onFocus={() => {
                          setWarningMessageconfirmation(true);
                        }}
                        onBlur={() => {
                          setWarningMessageconfirmation(false);
                        }}
                      />
                      <img
                        className="clickable eyeLogo eyePosition"
                        src={
                          showConfirmPassword
                            ? "/Images/icn-open-eye.svg"
                            : "/Images/icn-eye-off.svg"
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setShowConfirmPassword(!showConfirmPassword);
                        }}
                      />
                      {warningMessageconfirmation &&
                        formValues.confirmPassword.length == 0 && (
                          <div className=" ms-1 mt-1 d-flex">
                            <img src="/images/icn-warning.svg" />
                            <div className="warningMessagePasswordRegister ps-2 text-left">
                              Please! Make sure your passwords match
                            </div>
                          </div>
                        )}
                      {formValues.confirmPassword?.length > 0 &&
                        warningMessageconfirmation &&
                        messageConfirmPasswordError.length > 0 && (
                          <div className=" ms-1 d-flex">
                            {messageConfirmPasswordError ===
                            "Great, passwords matched" ? (
                              <img src="/images/check_circle.svg" />
                            ) : (
                              <img className="icn_error" />
                            )}
                            <div
                              className="warningMessagePasswordRegister ps-2 text-left"
                              style={
                                messageConfirmPasswordError ===
                                "Great, passwords matched"
                                  ? { color: "#12B6EA" }
                                  : {
                                      color: "var(--font-color12)",
                                    }
                              }
                            >
                              {messageConfirmPasswordError}
                            </div>
                          </div>
                        )}
                    </div>
                    <button
                      type="submit"
                      className={`regButton ${loading && "opacity-50"}`}
                      disabled={loading}
                      onClick={handleRegister}
                    >
                      Register
                    </button>
                    <div className="container-fluid orConatiner">
                      <span className="hrLine col-5"></span>
                      <span className="orName col-2">Or</span>
                      <span className="hrLine col-5"></span>
                    </div>
                    <div className="iconsDivRegister">
                      <button
                        className="iconButton"
                        onClick={handleUserLoginWithGoogle}
                      >
                        <img src="/Images/googleLogo.svg" />
                      </button>
                      <button
                        className="iconButton"
                        onClick={handleUserLoginWithTwitter}
                      >
                        <img src="/Images/twitterLogo.svg" />
                      </button>
                      {/* <button
                        className="iconButton"
                        onClick={handleUserSigninWithFacebook}
                      >
                        <img src="/Images/facebookLogo.svg" />
                      </button> */}
                      {/* <button className="iconButton">
                        <img src="/Images/appleLogo.svg" />
                      </button> */}
                    </div>

                    <p className="text-center">
                      Don't have an account?{" "}
                      <Link className="LinkLogin" to="/account/login">
                        Sign In
                      </Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="register_img"></div>
      </div>
    </div>
  );
};

export default RegisterPage;
