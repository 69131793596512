import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface DataProfile {
  firstName: string;
  lastName: string;
  email: string;
  avatarUrl: string;
  isActived: boolean;
  joinDate: string;
  id: string;
  profilePictureURL: string;
  mode: string;
  isSigned: boolean;
  isSubscriped: boolean;
}

export interface UserState {
  IsAuthenticated: boolean;
  User?: any;
  token?: string;
  IsRequestCompleted?: boolean;
  errorMessage?: string;
  darkMode?: boolean;
  userData?: DataProfile;
  userAvatar?: string;
  userPlan?: string;
  profilePictureURL?: string;
  isUserUpdatePicture?: boolean;
  isSubscribed?: boolean;
}

let initialState: UserState = {
  darkMode: true,
  IsAuthenticated: false,
  User: {},
  userData: {
    firstName: "",
    lastName: "",
    email: "",
    avatarUrl: "",
    isActived: true,
    joinDate: "",
    id: "",
    profilePictureURL: "",
    mode: "",
    isSigned: false,
    isSubscriped: false,
  },
  userAvatar: "",
  userPlan: "Community",
  profilePictureURL: "",
  isUserUpdatePicture: false,
  isSubscribed: false,
};

var user = localStorage.getItem("user");
if (user) initialState = JSON.parse(user);

const UserSlice = createSlice({
  name: "UserSlice",
  initialState: initialState,
  reducers: {
    authenticateUser: (state: UserState, action: PayloadAction<UserState>) => {
      state.IsAuthenticated = action.payload.IsAuthenticated;
      state.IsRequestCompleted = true;
      state.User = action.payload.User;
      state.errorMessage = "";
      localStorage.setItem("user", JSON.stringify(state));
    },

    signoutUserSuccess: (
      state: UserState,
      action: PayloadAction<UserState>
    ) => {
      state.IsAuthenticated = false;
      state.errorMessage = "";
      state.token = "";
      localStorage.removeItem("user");
      localStorage.removeItem("confirmUserEmail");
      localStorage.removeItem("userEmail");
    },

    signoutUserFailed: (state: UserState, action: PayloadAction<UserState>) => {
      state.IsAuthenticated = false;
      state.errorMessage = action.payload.errorMessage;
      localStorage.removeItem("user");
      // localStorage.setItem("user", JSON.stringify(state));
    },

    signinUserSuccess: (state: UserState, action: PayloadAction<UserState>) => {
      state.IsAuthenticated = action.payload.IsAuthenticated;
      state.IsRequestCompleted = true;
      state.token = action.payload.token;
      state.errorMessage = "";
      state.profilePictureURL = action.payload.userData?.profilePictureURL;
      state.userData = action.payload.userData;
      state.isSubscribed=action.payload.userData?.isSubscriped;
      // setProfilePictureURL(action.payload.userData?.profilePictureURL);
      localStorage.setItem("user", JSON.stringify(state));
      localStorage.removeItem("confirmUserEmail");
      localStorage.removeItem("prevPath");
    },
    
    changeSubscriptionStatus: (state: UserState, action: PayloadAction<boolean>) => {
      state.isSubscribed = action.payload;
      localStorage.setItem("user", JSON.stringify(state));
    },

    signinUserFailed: (state: UserState, action: PayloadAction<UserState>) => {
      state.IsAuthenticated = false;
      state.IsRequestCompleted = true;
      state.errorMessage = action.payload.errorMessage;
      localStorage.removeItem("user");
      localStorage.setItem("user", JSON.stringify(state));
    },
    forgetPasswordFailed: (state: UserState, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
    registerFailed: (state: UserState, action: PayloadAction<UserState>) => {
      state.IsRequestCompleted = true;
      state.errorMessage = action.payload.errorMessage;
    },
    toggleThemeMode: (state: UserState, action: PayloadAction<boolean>) => {
      state.darkMode = action.payload;
      localStorage.setItem("user", JSON.stringify(state));
      if (state.darkMode) {
        document.querySelector("body")?.setAttribute("color-theme", "dark");
      } else {
        document.querySelector("body")?.setAttribute("color-theme", "light");
      }
    },
    setAnnonymousToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      localStorage.setItem("user", JSON.stringify(state));
    },
    setUserAvatar: (state, action: PayloadAction<string>) => {
      state.userAvatar = action.payload;
    },
    setIsUserWithProPlan: (state, action: PayloadAction<string>) => {
      state.userPlan = action.payload;
    },
    setProfilePictureURL: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.profilePictureURL = action.payload;
    },
    setIsUpdateProfilePicture: (state, action: PayloadAction<boolean>) => {
      state.isUserUpdatePicture = action.payload;
    },
    setUpdateUserName: (
      state,
      action: PayloadAction<{ firstName: string; lastName: string }>
    ) => {
      if (state.userData) {
        state.userData.firstName = action.payload.firstName;
        state.userData.lastName = action.payload.lastName;
        localStorage.setItem("user", JSON.stringify(state));
      }
    },
  },
});

export const authenticateUserAsync =
  (payload: UserState) => (dispatch: any) => {
    dispatch(authenticateUser(payload));
  };

// export const updateUserPicture =
//   (userId: string, pictureUrl: ArrayBuffer | null) => (dispatch: any) => {
//     Api.post(`/account/updatePicture/${userId}`, {
//       file: pictureUrl,
//     })
//       .then((res) => {
//       })
//       .catch((e) => {
//       });
//   };

export const {
  authenticateUser,
  signoutUserSuccess,
  signoutUserFailed,
  signinUserSuccess,
  signinUserFailed,
  forgetPasswordFailed,
  registerFailed,
  toggleThemeMode,
  setAnnonymousToken,
  setUserAvatar,
  setIsUserWithProPlan,
  setIsUpdateProfilePicture,
  setProfilePictureURL,
  setUpdateUserName,
  changeSubscriptionStatus,
} = UserSlice.actions;
export default UserSlice.reducer;
