import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import "./Payment.css";

const SubscriptionForm = () => {
  return (
    <div className="payment_form">
      <Form className="card_form">
        <Form.Group className="mb-3" controlId="formGroupEmail">
          <Form.Label className="font_style mb-2">
            Contact information
          </Form.Label>
          <Form.Control type="email" placeholder="codingnepalweb@gmail.com" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGroupPassword">
          <Form.Label className="font_style mb-2">Name on card</Form.Label>
          <Form.Control type="text" placeholder="Full name on card" />
        </Form.Group>
        <Form.Group>
          <Form.Label className="font_style mb-2">Card details</Form.Label>
          <div className="form_with_icons">
            <Form.Control
              className="m-0 p-0 border-0 me-2"
              type="text"
              placeholder="1234 1234 1234 1234"
            />
            <div className="icons">
              <img src="/images/payment/icn-visa.svg" alt="" />
              <img src="/images/payment/icn-master-card.svg" alt="" />
              <img src="/images/payment/icn-amex.svg" alt="" />
            </div>
          </div>
          <Row className="p-0 m-0">
            <Col className="p-0">
              <Form.Control className="m-0" placeholder="MM / YY" />
            </Col>
            <Col className="CVC_container">
              <Form.Control
                className="m-0 me-2 border-0 p-0"
                placeholder="CVC"
              />
              <div>
                <img src="/images/payment/Group.svg" alt="" />
              </div>
            </Col>
          </Row>
        </Form.Group>
      </Form>
      <div className="subscribe">
        <div className="upgradeBtn">Subscribe</div>
        <div className="subscribe_text font_style">
          By confirming your subscription, you allow us to charge your card for
          this and future payments in accordance with terms. You can always
          cancel your subscription
        </div>
      </div>
    </div>
  );
};

export default SubscriptionForm;
