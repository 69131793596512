import React, { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import "./Models.css";
import { useAppDispatch } from "../../../../store/hooks";
import { setFileError } from "../../../../store/one-query/chatsSlice";

interface AlertProps {
  showAlert: boolean;
  message: string;
}

const AlertMessage = ({ message, showAlert }: AlertProps) => {
  const [visible, setVisible] = useState(showAlert);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (showAlert) {
      setVisible(true);
      const timer = setTimeout(() => {
        setVisible(false);
        dispatch(setFileError({ isError: false, errorMsg: "", reason: "" }));
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [showAlert]);

  if (!visible) {
    return null;
  }

  return message === "Password successfully updated" ? (
    <Alert
      className="w-75 ms-3 mt-3 position-absolute d-flex align-items-center"
      variant="success"
      style={{
        zIndex: "10000",
        backgroundColor: "#198F51",
        border: "1px solid #198F51",
      }}
    >
      <img src={"/images/Navbar/check_circle.svg"} className="me-2" />
      <Alert.Heading className="alert-msg" style={{ fontSize: "" }}>
        {message}
        <img
          className="c-pointer"
          src="/Images/UploadPopupResources/popupClose-dark.svg"
        />
      </Alert.Heading>
    </Alert>
  ) : (
    <div className="d-flex justify-content-center">
      <Alert
        className="w-50 ms-3 mt-3 position-absolute d-flex align-items-center"
        variant="danger"
        style={{ zIndex: "10000" }}
      >
        <img src={"/images/Navbar/icn-error-white.svg"} className="me-2" />
        <Alert.Heading className="alert-msg" style={{ fontSize: "" }}>
          {message}
          <img
            className="c-pointer"
            src="/Images/UploadPopupResources/popupClose-dark.svg"
          />
        </Alert.Heading>
      </Alert>
    </div>
  );
};

export default AlertMessage;
