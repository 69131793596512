import "./UserQuestion.css";
import "./CopyCode.css";
import ChatResponseFormat from "./ChatResponseFormat";
import EmotionResponse from "./EmotionResponse";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { ChatModelEditsState, ChatModelState } from "../../../../Helper";
interface Props {
  modelName: string;
  currentReply: string;
  prompt: string;
  currentMessageId: string;
  setQuery: (prompt: string) => void;
}

const ChatResponse: React.FC<Props> = ({
  modelName,
  currentReply,
  prompt,
  currentMessageId,
  setQuery,
}) => {
  const dispatch = useAppDispatch();

  const capitalizeWords = () => {
    if (modelName === "MistralAi" || modelName === "MistralAi2")
      return "MistralAi";
    else return modelName?.replace(/ai/gi, "Ai")?.replace(/gpt/gi, "GPT");
  };

  return (
    <div>
      <div className="chatResponse position-relative mb-1">
        <div
          id="chat-details"
          className="d-flex align-items-center justify-content-between"
        >
          <div
            className={`d-flex align-items-center mb-1 justify-content-center gap-2`}
          >
            <img className={`${modelName}Logo`} />
            <p className="chatName mb-0">{capitalizeWords()}</p>
          </div>
        </div>
        <div className="response position-relative w-100">
          <div className="responseText">
            <ChatResponseFormat response={currentReply} />
          </div>
          <EmotionResponse
            messageId={currentMessageId}
            response={currentReply}
            question={prompt}
            setQuery={setQuery}
          />
        </div>
      </div>
    </div>
  );
};

export default ChatResponse;
