import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import "./Filter.css";
import { useGetConversationTypes } from "../../../../store/Apis/ConversationTypeApi";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { FilterItem } from "../../../../Helper";
import { conversationsActions } from "../../../../store/one-query/conversationSlice";

interface FilterProps {
  currentPlace?: string;
}

const Filter = ({ currentPlace }: FilterProps) => {
  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const conversationTypes = useAppSelector((s) => s.ConversationTypes);
  let darkMode = useAppSelector((state) => state.User.darkMode);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useAppDispatch();
  const getConversationTypesMutate = useGetConversationTypes();
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const currentSelectedPersonaFilter = useAppSelector(
    (s) => s.Conversations.currentSelectedPersonaFilter
  );

  const helpAndSupportFilter: FilterItem[] = [
    {
      title: "All sources",
      value: "all",
    },
    {
      title: "Admission",
      value: "admission",
    },
    {
      title: "Academic",
      value: "academic",
    },
    {
      title: "Research",
      value: "research",
    },
    {
      title: "Student life",
      value: "student_life",
    },
  ];

  useEffect(() => {
    getConversationTypesMutate();
  }, []);

  const handleItemClick = (index: number, id: string | null) => {
    dispatch(conversationsActions.setCurrentPersonaFilter(id));
    setSelectedItem(index);
    toggleDropdown();
  };

  const handleSelectOptionAll = () => {
    dispatch(conversationsActions.setCurrentPersonaFilter(""));
  };

  return (
    <div>
      <Dropdown
        show={dropdownOpen}
        onToggle={toggleDropdown}
        autoClose="outside"
      >
        <Dropdown.Toggle className="bg-transparent border-0">
          {currentPlace === "Sidebar" ? (
            <img
              className="filter-icon"
              src={
                darkMode
                  ? "/images/GetConversations/filter.svg"
                  : "/images/GetConversations/filter light.svg"
              }
            />
          ) : (
            <img
              className="help-filter-icon"
              src={
                darkMode
                  ? "/images/GetConversations/filter_alt.svg"
                  : "/images/GetConversations/filter_alt.svg"
              }
            />
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu
          className={dropdownOpen ? "filter-menu show" : "filter-menu"}
        >
          <Dropdown.Header
            className="d-flex align-items-center justify-content-between"
            onClick={toggleDropdown}
          >
            <span>Filter by </span>
            <span className="reset-btn" onClick={handleSelectOptionAll}>
              Reset
            </span>
          </Dropdown.Header>
          {currentPlace === "Sidebar" ? (
            <div className="categories overflow-x-hidden overflow-y-auto">
              {conversationTypes &&
                conversationTypes.data &&
                conversationTypes.data.map((item, index) => (
                  <Dropdown.Item
                    key={index}
                    className={`${
                      index === selectedItem && currentSelectedPersonaFilter
                        ? "selected"
                        : ""
                    }`}
                    onClick={() => handleItemClick(index, item.id)}
                  >
                    <p className="personaFilterItem mb-0">
                      {item.title && item.title.length > 22
                        ? item.title.slice(0, 22) + "..."
                        : item.title}
                    </p>
                  </Dropdown.Item>
                ))}
            </div>
          ) : (
            <div className="categories overflow-x-hidden overflow-y-auto">
              {helpAndSupportFilter &&
                helpAndSupportFilter.map((item, index) => (
                  <Dropdown.Item
                    key={index}
                    className={`${
                      index === selectedItem && currentSelectedPersonaFilter
                        ? "selected"
                        : ""
                    }`}
                  >
                    <p className="personaFilterItem mb-0">
                      {item.title && item.title.length > 22
                        ? item.title.slice(0, 22) + "..."
                        : item.title}
                    </p>
                  </Dropdown.Item>
                ))}
            </div>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default Filter;
