import React, { ReactElement, useState } from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import "./HelpAndSupport.css";
import Skeleton from "./Skeleton";
import { FilterItem } from "../../../../../Helper";

interface FilterProps {
  load: boolean;
  loadedModelReplay: boolean;
  setLoadedModelReplay: (value: boolean) => void;
  startOverBtn: any;
}

const Filter = ({
  load,
  loadedModelReplay,
  setLoadedModelReplay,
  startOverBtn,
}: FilterProps) => {
  const [activeFilter, setActiveFilter] = useState<string>("All sources");

  const filterArr: FilterItem[] = [
    {
      title: "All sources",
      value: "All sources",
    },
    {
      title: "Model",
      value: "all",
    },
    {
      title: "Persona",
      value: "Persona",
    },
    {
      title: "Prompt",
      value: "Prompt",
    },
    {
      title: "Membership",
      value: "Membership",
    },
  ];

  return (
    <Container className="filter-container">
      <Row className="align-items-center justify-content-around">
        <Col>
          <div className="d-flex align-items-center flex-row">
            <div>
              {load ? (
                <Skeleton
                  width="83px"
                  height="12.25px"
                  margin="my-2"
                  marginX="me-4"
                  borderRadius="17.5px"
                />
              ) : (
                <span className="filter-label">Filter by source</span>
              )}
            </div>
            <div>
              <div className="d-flex align-items-center gap-3">
                {filterArr.map((filter, index) => (
                  <div
                    key={index}
                    onClick={() => setActiveFilter(filter.value)}
                    className={`filter-button ${
                      activeFilter === filter.value ? "active" : ""
                    }`}
                  >
                    {load ? (
                      <Skeleton
                        key={filter.value}
                        width="63px"
                        height="10px"
                        borderRadius="15px"
                        margin="m-0"
                        backgroundColor="#2A3746"
                      />
                    ) : (
                      filter.title
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Col>
        {startOverBtn}
      </Row>
    </Container>
  );
};

export default Filter;
