import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  conversationsActions,
} from "../../../../store/one-query/conversationSlice";
import "./getConversation.css";
import {
  resetUserInteracted,
  setNewConversation,
  setSelectedPersona,
} from "../../../../store/one-query/chatsSlice";
import DeleteModal from "./DeletePopup";
import useGetLastActiveModels, {
  useDeleteConversation,
  useUpdateConversation,
} from "../../../../store/Apis/ConversationApi";
import { useNavigate, useParams } from "react-router-dom";
interface HistoryItemProps {
  id: string;
  name: string | undefined;
  date: string;
  category: string | undefined;
}
interface UpdateConversationInterface {
  id: string;
  name: string | undefined;
  personaId: string;
}

const HistoryItem: React.FC<HistoryItemProps> = ({
  id,
  name,
  date,
  category,
}) => {
  const darkMode = useAppSelector((state) => state.User.darkMode);
  const [updateConName, setUpdateConName] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [updatedName, setUpdatedName] = useState<string | undefined>("");
  const [showRenameOrDeleteMenu, setShowRenameOrDeleteMenu] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const allConversations = useAppSelector(
    (state) => state.Conversations.conversations
  );
  const updateConversation = useUpdateConversation();
  const deleteConversation = useDeleteConversation();
  const [conversationName, setConversationName] = useState<string>();
  const activeConversationId = useAppSelector(
    (state) => state.Conversations.activeConversationId
  );
  const { getLastActiveModels, loading } = useGetLastActiveModels();

  const navigate = useNavigate();
  const handleDelete = () => {
    deleteConversation(id);
    setShowDelete(!showDelete);
  };

  const selectedPersona = allConversations.find(
    (con) => con?.id === activeConversationId
  )?.persona;

  function formatDate(dateStr: string): string {
    const date = new Date(dateStr);

    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
    };
    const formattedDate = date.toLocaleDateString("en-GB", options);
    return formattedDate;
  }

  const handleUpdate = () => {
    let data: UpdateConversationInterface;
    if (selectedPersona && name) {
      data = { id, name: updatedName, personaId: selectedPersona.id };
      updateConversation(data);
    }
    setUpdateConName(false);
  };

  const handleActiveConversation = (conId: string) => {
    dispatch(conversationsActions.setActiveConversation(conId));
    dispatch(conversationsActions.setIsConHistoryClicked(true));
    getLastActiveModels(conId);
    dispatch(resetUserInteracted());
    dispatch(setNewConversation(false));
    navigate(`/conversation/${conId}`);
    dispatch(
      setSelectedPersona({ id: "", title: "", description: "", objective: "" })
    );
  };

  const toggleUpdateConversationName = () => {
    setShowRenameOrDeleteMenu(false);
    setUpdateConName(!updateConName);
    setShowRenameOrDeleteMenu;
    setUpdatedName(conversationName);
  };

  const handleDeleteItem = () => {
    setShowRenameOrDeleteMenu(false);
    setShowDelete(true);
  };

  const removeUuid = (inputString: string) => {
    const guidRegex =
      /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    inputString = inputString?.replace(guidRegex, "").trim(); // Trim to remove any trailing whitespace
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
  };

  useEffect(() => {
    if (name) setConversationName(removeUuid(name));
  }, [name]);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setShowRenameOrDeleteMenu(false);
    }
  };

  useEffect(() => {
    if (showRenameOrDeleteMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showRenameOrDeleteMenu]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      handleUpdate();
    }
  };

  return (
    <div
      className={` position-relative d-flex align-items-center justify-content-between my-2 me-3`}>
      <div
        id="question-container"
        className={`d-flex align-items-center w-100 `}
        onClick={() => !updateConName && handleActiveConversation(id)}>
        <div style={{ marginBottom: !updateConName ? "0px" : "0" }}></div>
        {updateConName ? (
          <div>
            <input
              className="inputUpdateConName px-2 py-0 rounded-0"
              value={updatedName}
              onChange={(e) => setUpdatedName(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </div>
        ) : (
          <div className="d-flex flex-column align-items-start c-pointer w-100">
            <div
              className={`containerName ${
                activeConversationId === id && "selectedConversation"
              }`}>
              <div className="d-flex flex-row align-items-start w-100">
                <img
                  className="me-2"
                  src={
                    darkMode
                      ? "/images/GetConversations/send.svg"
                      : "/images/GetConversations/send light.svg"
                  }
                />
                {conversationName && (
                  <p
                    className={`${
                      conversationName.length > 20 && "historyItemTitle"
                    } mb-0 font-inter`}>
                    {conversationName}
                  </p>
                )}
              </div>
              {showRenameOrDeleteMenu && (
                <div className="renameOrDeleteMenu" ref={dropdownRef}>
                  <p className="mb-0" onClick={toggleUpdateConversationName}>
                    Rename
                  </p>
                  <p className="mb-0" onClick={handleDeleteItem}>
                    Delete
                  </p>
                </div>
              )}
            </div>
            <p className="PersonaHistoryItem ms-4 mb-0">{category}</p>
            <span className="historyItemDate ms-4">{formatDate(date)}</span>
          </div>
        )}
      </div>
      {updateConName ? (
        <div className="d-flex align-items-center mx-2">
          <img
            className="cancelUpdateConName mx-2 c-pointer"
            onClick={handleUpdate}
          />
          <img
            className="updateConName c-pointer"
            onClick={() => setUpdateConName(false)}
          />
        </div>
      ) : (
        <img
          className={`align-self-start pt-1 c-pointer  ${
            activeConversationId === id && "selectedStyle"
          }`}
          onClick={() => setShowRenameOrDeleteMenu(!showRenameOrDeleteMenu)}
          src={
            darkMode
              ? "/images/GetConversations/delete_or_rename.svg"
              : "/images/GetConversations/delete_or_rename light.svg"
          }
          alt=""
        />
      )}
      <DeleteModal
        show={showDelete}
        handleClose={() => setShowDelete(!showDelete)}
        method={handleDelete}
        name="Delete conversation"
        message="By Deleting your card your rest messages can’t be reached Are you
        sure?"
      />
    </div>
  );
};

export default HistoryItem;
