import React, {
  forwardRef,
  SyntheticEvent,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import "./OneQueryInput.css";
import "./FileUpload.css";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  regenerateResponse,
  resetUserInteracted,
  setFileError,
  setIsUserType,
  setNewConversation,
  setParentMessageId,
} from "../../../../store/one-query/chatsSlice";
import { conversationsActions } from "../../../../store/one-query/conversationSlice";

export interface QueryInputProps
  extends React.RefAttributes<HTMLTextAreaElement> {
  handleSendQuery(query: string, FileUpload: FileList): void;
  receieveUserImageQuery(imgQueryURL: File): void;
  inputDisabled: boolean;
}
interface CustomTextAreaElement extends HTMLTextAreaElement {
  handleDeleteUploadFile: () => void;
}
const OneQueryInput = forwardRef<CustomTextAreaElement, QueryInputProps>(
  (props, ref) => {
    const [query, setQuery] = useState<string>();
    const isFullWidth = useAppSelector(
      (state) => state.ChatsState.fullWidthMode
    );
    const newConversation = useAppSelector(
      (state) => state.ChatsState.newConversation
    );
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const [isButtonDisabled, setButtonDisabled] = useState(true);
    const [fileUpload, setFileUpload] = useState<any>("");
    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const [isIconDeleteAppear, setIsIconDeleteAppear] = useState(false);
    const dispatch = useAppDispatch();
    const [triggerSend, setTriggerSend] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const regenerateMsg = useAppSelector((s) => s.ChatsState.reGenerateMessage);
    const [isFileError, setIsFileError] = useState(false);

    const onTextAreaInputChanged = (e: SyntheticEvent) => {
      if (textAreaRef.current) {
        textAreaRef.current.style.height = "auto";
        textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
      }
      setButtonDisabled(
        !textAreaRef.current || textAreaRef.current.value.trim() === ""
      );
      setQuery((e.target as HTMLTextAreaElement).value);
    };

    useEffect(() => {
      if (
        triggerSend &&
        !isButtonDisabled &&
        textAreaRef.current &&
        textAreaRef.current.value
      ) {
        dispatch(resetUserInteracted());
        setButtonDisabled(true);
        setTriggerSend(false);
      }
    }, [triggerSend, isButtonDisabled]);

    const handleSendClick = () => {
      if (!isButtonDisabled && textAreaRef.current) {
        // Set trigger for sending request
        setTriggerSend(true);

        if (textAreaRef.current.value.trim() !== "") {
          const trimmedQuery = textAreaRef.current.value.trim();

          props.handleSendQuery(trimmedQuery, fileUpload);

          dispatch(resetUserInteracted());
          dispatch(setNewConversation(false));
          dispatch(conversationsActions.setIsNewConversation(false));
          dispatch(regenerateResponse(false));

          // Clear the input and file state
          setQuery("");
          setFileUpload("");
          setIsFileUploaded(false);
          if (textAreaRef.current) {
            textAreaRef.current.value = "";
          }
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
        }
      }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === "Enter" && !event.shiftKey && textAreaRef.current) {
        event.preventDefault();
        if (!isButtonDisabled &&
          props.inputDisabled &&
          textAreaRef.current.value.trim() !== ""
        ) {
          setQuery(textAreaRef.current.value.trim());
          handleSendClick();
        }
      }
    };

    useEffect(() => {
      if (regenerateMsg.length > 0) {
        if (textAreaRef.current) {
          if (textAreaRef.current.value != "") {
            textAreaRef.current.value = "";
            setQuery("");
          }
          setQuery(regenerateMsg);
          textAreaRef.current.value = regenerateMsg;
          textAreaRef.current.focus();
        }
      }
    }, [regenerateMsg]);

    const handleDeleteUploadFile = () => {
      setFileUpload("");
      setIsFileUploaded(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    };

    const handleImageClick = () => {
      fileInputRef.current?.click();
      setFileUpload("");
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      if (files) {
        // Define allowed extensions
        const allowedExtensions = /(\.doc|\.docx|\.xls|\.xlsx|\.pdf)$/i;
        // Filter files based on allowed extensions
        const validFiles = Array.from(files).filter((file) =>
          allowedExtensions.test(file.name)
        );

        if (validFiles.length > 0) {
          setFileUpload(validFiles); // Only set valid files
          setIsFileUploaded(true);
        } else {
          setFileUpload("");
          setIsFileUploaded(false);
          fileInputRef.current!.value = ""; // Clear the input
          dispatch(
            setFileError({
              isError: true,
              errorMsg: "File type not supported. Use PDF, DOC, or Excel.",
              reason: "user upload file type not support",
            })
          );
        }
      }
    };

    const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files.length > 0) {
        const uploadedFile = event.target.files[0];
        if (uploadedFile.size < 5 * 1024) {
          dispatch(
            setFileError({
              isError: true,
              errorMsg: "The uploaded file is empty!",
              reason: "file Upload is empty",
            })
          );
          setIsFileError(true);
        } else if (uploadedFile.size > 5 * 1024 * 1024) {
          dispatch(
            setFileError({
              isError: true,
              errorMsg: "The uploaded file exceeds the limit allowed (5MB)",
              reason: "file upload excced 5MB",
            })
          );
          setIsFileError(true);
        }
      }
    };

    useEffect(() => {
      if (isFileError) {
        // Optionally, add a delay before resetting the error state
        const timer = setTimeout(() => {
          handleDeleteUploadFile();
          setIsFileError(false);
        }, 3000);

        return () => clearTimeout(timer); // Cleanup timeout
      }
    }, [isFileError]);

    useImperativeHandle(ref, () => {
      if (textAreaRef.current) {
        return {
          handleDeleteUploadFile,
          ...textAreaRef.current,
        };
      } else {
        return {
          handleDeleteUploadFile,
        } as CustomTextAreaElement;
      }
    });

    useEffect(() => {
      if (query && query?.length > 0) {
        dispatch(setIsUserType(true));
        dispatch(setParentMessageId(null));
      } else {
        dispatch(setIsUserType(false));
      }
    }, [query]);

    return (
      <div
        className={`chat-box ${
          isFullWidth !== "" && isFullWidth !== "ExpertHome" ? "fullWidth" : ""
        } 
      ${newConversation && "new-conversation"}`}
      >
        <div className="chatBoxTextDiv">
          <div className="uploadedFiles">
            {isFileUploaded && fileUpload && (
              <div
                className="d-flex flex-row containerFileUpload"
                onMouseOver={() => setIsIconDeleteAppear(true)}
                onMouseLeave={() => setIsIconDeleteAppear(false)}
              >
                <img
                  className="me-3"
                  src={
                    fileUpload[0]?.name.includes("pdf")
                      ? "/Images/GetConversations/icn-pdf-light.svg"
                      : fileUpload[0]?.name.includes("doc") ||
                        fileUpload[0]?.name.includes("docx")
                      ? "/Images/GetConversations/icn-doc-light.svg"
                      : "/Images/GetConversations/icn-xlsx-light.svg"
                  }
                />
                <div className="d-flex flex-column fileName">
                  <span>{fileUpload[0]?.name.split(".")[0]}</span>
                  <span>.{fileUpload[0]?.name.split(".")[1]}</span>
                </div>
                {isIconDeleteAppear && (
                  <div className="overlay">
                    <img
                      className="close-icon c-pointer"
                      src="/Images/GetConversations/cancel.svg"
                      onClick={handleDeleteUploadFile}
                    ></img>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="containerTextAndButtons">
            <textarea
              id="chatTextArea"
              ref={textAreaRef}
              onKeyDown={handleKeyDown}
              onChange={onTextAreaInputChanged}
              onInput={onTextAreaInputChanged}
              placeholder="Input Your Prompt Here"
              value={query}
            ></textarea>
            {newConversation && textAreaRef.current?.value === "" ? (
              <div className="loading user-input d-flex gap-1 mt-2 position-absolute">
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : (
              ""
            )}

            <div className="chatBoxButtonDiv">
              <div>
                <img
                  src="/images/UploadPopupResources/paperclip-2.svg"
                  className="uploadPopupIcon"
                  // onClick={() => setIsUploadPopupOpen(true)}
                  onClick={handleImageClick}
                />
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  accept=".doc,.docx,.xls,.xlsx,.pdf"
                  onChange={handleFileChange}
                  onInput={handleFileInput}
                />
              </div>
              <button
                disabled={!props.inputDisabled || isButtonDisabled}
                className={`send-question-btn ${
                  (!props.inputDisabled || isButtonDisabled) &&
                  "disable-query-input-btn"}`}
                onClick={handleSendClick}
              >
                <img src="/images/send-question-icon.svg" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default OneQueryInput;
