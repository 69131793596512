import React, { useEffect, useRef, useState } from "react";
import "./ChangePassword.css";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { Link, useNavigate } from "react-router-dom";
import Alert from "../../../one-query/services/Alert";
import { useChangePasswordMutation } from "../../../../store/Apis/UserApi";
import { changePasswordRegex } from "../../../../Helper";
const ChangePassword = () => {
  const navigate = useNavigate();
  const { changePasswordMutate, loading } = useChangePasswordMutation();
  let darkMode = useAppSelector((state) => state.User.darkMode);
  const userData = useAppSelector((state) => state.User.userData);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [warningMessageNewPassword, setWarningMessageNewPassword] =
    useState(false);
  const [warningMessageconfirmation, setWarningMessageconfirmation] =
    useState(false);
  const [messageNewPasswordError, setMessageNewPasswordError] = useState("");
  const [messageConfirmPasswordError, setMessageConfirmPasswordError] =
    useState("");
  const errorMessage = useAppSelector((s) => s.ChatsState.messageError);
  const [errorMessageState, setErrorMessageState] = useState("");

  useEffect(() => {
    if (errorMessage === "Old password is incorrect")
      setErrorMessageState(errorMessage);
  }, [errorMessage]);

  const [Password, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleCheckPassword = (e: any) => {
    setPassword((oldData) => ({ ...oldData, [e.target.name]: e.target.value }));
    setErrorMessageState("");
  };

  useEffect(() => {
    if (Password.newPassword.length > 1) {
      setWarningMessageNewPassword(false);
      if (changePasswordRegex.test(Password.newPassword))
        setMessageNewPasswordError("Great, all done.");
      else if (!/[A-Z]/.test(Password.newPassword))
        setMessageNewPasswordError("Password must contain an uppercase Letter");
      else if (!/[a-z]/.test(Password.newPassword))
        setMessageNewPasswordError("Password must contain a lowercase Letter");
      else if (!/\d/.test(Password.newPassword))
        setMessageNewPasswordError("Password must contain a numeric character");
      else if (!/[!@#$%^&*(),.?":{}|<>]/.test(Password.newPassword))
        setMessageNewPasswordError("Password must contain a special character");
      else if (Password.newPassword.length <= 8)
        setMessageNewPasswordError("Password must be at least 8 characters");
    }
    if (changePasswordRegex.test(Password.newPassword)) {
      if (Password.confirmPassword.length > 1) {
        setWarningMessageconfirmation(false);
        if (Password.confirmPassword.length <= 1)
          setMessageConfirmPasswordError("Confirm Password is required");
        else if (!(Password.confirmPassword === Password.newPassword))
          setMessageConfirmPasswordError("Passwords do Not match");
        else setMessageConfirmPasswordError("Great, passwords matched");
      }
    }
  }, [Password.newPassword, Password.confirmPassword]);

  const handleChangePassword = () => {
    if (userData?.id) {
      changePasswordMutate(
        userData.id,
        Password.oldPassword,
        Password.newPassword,
        Password.confirmPassword
      );
    }
  };

  return (
    <>
      <Alert />
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div className="col-md-6 divCahngePasswordForm col-sm-12 ">
            <form className=" formDivPassword  ">
              <img
                src={
                  darkMode
                    ? "/images/GetConversations/Expertex-logo-dark.svg"
                    : "/images/GetConversations/Expertex-logo-light.svg"
                }
                className="ExpertexLogoRegister c-pointer"
                alt="Expertex_logo"
                onClick={() => navigate("/")}
              />
              <div className="m-2 ms-3 w-100  d-flex align-start">
                <p className="headerChangePassword ">Change password</p>
              </div>
              <div className="mb-1 w-100">
                {/* input Old Password */}
                <div className="containerShowPassword mb-2">
                  <input
                    type={showOldPassword ? "text" : "password"}
                    onChange={handleCheckPassword}
                    name="oldPassword"
                    value={Password?.oldPassword}
                    className="inputPasswordBorderBlue"
                    placeholder="Old password"
                  />
                  {Password?.oldPassword && (
                    <img
                      className="clickable eyeLogo eyePosition"
                      src={
                        showOldPassword
                          ? "/Images/icn-open-eye.svg"
                          : "/Images/icn-eye-off.svg"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setShowOldPassword(!showOldPassword);
                      }}
                    />
                  )}
                  {errorMessageState.length > 0 && (
                    <>
                      <div className=" ms-1 d-flex" style={{ width: "23rem" }}>
                        {darkMode ? (
                          <img src="/images/icn-error-circle-dark.svg" />
                        ) : (
                          <img src="/images/icn-error-circle.svg" />
                        )}
                        <div
                          className="warningMessageNewPassword ps-1 text-left"
                          style={{
                            color: `${!darkMode ? "#BA1A1A" : "#FFB4AB"}`,
                          }}
                        >
                          {errorMessageState}
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {/* input New Password */}
                <div className="containerShowPassword mb-2 ">
                  <input
                    type={showNewPassword ? "text" : "password"}
                    value={Password?.newPassword}
                    name="newPassword"
                    className={
                      messageNewPasswordError.length > 1 &&
                      Password.newPassword.length > 0 &&
                      messageNewPasswordError !== "Great, all done."
                        ? "inputPasswordBorderRed"
                        : "inputPasswordBorderBlue"
                    }
                    placeholder="New password"
                    onChange={handleCheckPassword}
                    onFocus={() => {
                      setWarningMessageNewPassword(true);
                    }}
                    onBlur={() => {
                      setWarningMessageNewPassword(false);
                    }}
                  />
                  {Password?.newPassword && (
                    <img
                      className="clickable eyeLogo eyePosition"
                      src={
                        showNewPassword
                          ? "/Images/icn-open-eye.svg"
                          : "/Images/icn-eye-off.svg"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setShowNewPassword(!showNewPassword);
                      }}
                    />
                  )}
                  {warningMessageNewPassword &&
                    Password.newPassword.length < 1 && (
                      <div className=" ms-1 d-flex" style={{ width: "23rem" }}>
                        <img src="/images/icn-warning.svg" />
                        <div className="warningMessageNewPassword ps-2 text-left">
                          Password must contain an uppercase letter, a lowercase
                          letter, and a numeric character.
                        </div>
                      </div>
                    )}
                  {Password.newPassword.length > 0 &&
                    !warningMessageNewPassword &&
                    messageNewPasswordError.length > 1 && (
                      <div className=" ms-1 d-flex" style={{ width: "23rem" }}>
                        {messageNewPasswordError === "Great, all done." ? (
                          <img src="/images/check_circle.svg" />
                        ) : darkMode ? (
                          <img src="/images/icn-error-circle-dark.svg" />
                        ) : (
                          <img src="/images/icn-error-circle.svg" />
                        )}
                        <div
                          className="warningMessageNewPassword ps-2 text-left"
                          style={
                            messageNewPasswordError === "Great, all done."
                              ? { color: "#12B6EA" }
                              : {
                                  color: "var(--font-color12)",
                                }
                          }
                        >
                          {messageNewPasswordError}
                        </div>
                      </div>
                    )}
                </div>
                {/* input Confirm Password */}
                <div className="containerShowPassword mb-1">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    value={Password?.confirmPassword}
                    name="confirmPassword"
                    placeholder="Confirm password"
                    className={
                      messageConfirmPasswordError.length > 1 &&
                      Password.confirmPassword.length > 0 &&
                      messageConfirmPasswordError !== "Great, passwords matched"
                        ? "inputPasswordBorderRed"
                        : "inputPasswordBorderBlue"
                    }
                    onChange={handleCheckPassword}
                    onFocus={() => {
                      setWarningMessageconfirmation(true);
                    }}
                    onBlur={() => {
                      setWarningMessageconfirmation(false);
                    }}
                  />
                  {Password?.confirmPassword && (
                    <img
                      className="clickable eyeLogo eyePosition"
                      src={
                        showConfirmPassword
                          ? "/Images/icn-open-eye.svg"
                          : "/Images/icn-eye-off.svg"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setShowConfirmPassword(!showConfirmPassword);
                      }}
                    />
                  )}
                  {warningMessageconfirmation &&
                    Password.confirmPassword.length == 0 && (
                      <div className=" ms-1 d-flex" style={{ width: "23rem" }}>
                        <img src="/images/icn-warning.svg" />
                        <div className="warningMessageNewPassword ps-2 text-left">
                          Please! Make sure your Passwords match
                        </div>
                      </div>
                    )}
                  {Password.confirmPassword.length > 0 &&
                    !warningMessageconfirmation &&
                    messageConfirmPasswordError.length > 0 && (
                      <div className=" ms-1 d-flex" style={{ width: "23rem" }}>
                        {messageConfirmPasswordError ===
                        "Great, passwords matched" ? (
                          <img src="/images/check_circle.svg" />
                        ) : darkMode ? (
                          <img src="/images/icn-error-circle-dark.svg" />
                        ) : (
                          <img src="/images/icn-error-circle.svg" />
                        )}
                        <div
                          className="warningMessageNewPassword ps-2 text-left"
                          style={
                            messageConfirmPasswordError ===
                            "Great, passwords matched"
                              ? { color: "#12B6EA" }
                              : {
                                  color: "var(--font-color12)",
                                }
                          }
                        >
                          {messageConfirmPasswordError}
                        </div>
                      </div>
                    )}
                </div>

                <div>
                  <button
                    disabled={loading}
                    className={`mt-1 btnPassword ${loading && "opacity-50"}`}
                    onClick={handleChangePassword}
                  >
                    Save
                  </button>
                  <Link to={"/settings"} className="mt-2 btnPassword btnCancel">
                    Cancel
                  </Link>
                </div>
              </div>
            </form>
          </div>
          <div className="col-md-6 backgroundImage d-none d-md-block">
            <img
              src="/Images/change-password.png"
              alt="Change Password"
              className="imgChangePassword"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
