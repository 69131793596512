import React, { useEffect, useState } from "react";
import { Toast } from "react-bootstrap";
import { useAppDispatch } from "../../../../store/hooks";
import { setSnackBarAlert } from "../../../../store/one-query/chatsSlice";
import { useNavigate } from "react-router-dom";

interface SnackBarProps {
  showSnackBar: boolean;
  snackBarMessage: string;
  snackType?: string;
}

const SnackBarAlert = ({ showSnackBar, snackBarMessage }: SnackBarProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (snackBarMessage === "This conversation doesn't exist.") {
      navigate("/main-page");
    }
    if (showSnackBar) {
      const timer = setTimeout(() => {
        dispatch(setSnackBarAlert({ show: false, message: "", alertType: "" }));
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [showSnackBar]);

  return (
    <div>
      <Toast
        show={showSnackBar}
        delay={4000}
        autohide
        style={{
          backgroundColor: "#FFBF17",
          position: "fixed",
          bottom: "20px",
          right: "20px",
          zIndex: 9999,
          height: "50px",
          fontSize: "16px",
          fontWeight: "500",
          color: "#000000",
        }}
      >
        <Toast.Body>{snackBarMessage}</Toast.Body>
      </Toast>
    </div>
  );
};

export default SnackBarAlert;
