export const fetchBuildTimestamp = async () => {
  try {
    const response = await fetch("/build-timestamp.txt", { cache: "no-store" });
    if (!response.ok) {
      throw new Error(
        `Failed to fetch build timestamp. HTTP error! Status: ${response.status}`
      );
    }
    const latestTimestamp = (await response.text()).trim();
    // Compare the timestamps
    const storedTimestamp = localStorage.getItem("metaTimestamp");
    if (storedTimestamp !== latestTimestamp) {
      // Clear all caches
      if ("caches" in window) {
        const cacheNames = await caches.keys();
        await Promise.all(
          cacheNames.map((cacheName) => {
            return caches.delete(cacheName);
          })
        );
      }
      // Clear session storage
      sessionStorage.clear();

      // Clear local storage except for reload trigger and meta timestamp
      localStorage.clear();

      // Reload the app if not already triggered
      if (!localStorage.getItem("reloadTriggered")) {
        localStorage.setItem("reloadTriggered", "true");
        localStorage.setItem("metaTimestamp", latestTimestamp); // Store the new timestamp
        window.location.reload();
      }
    } else {
      // Reset the reload trigger if timestamps match
      localStorage.removeItem("reloadTriggered");
    }
  } catch (error) {}
};
