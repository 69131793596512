import Api from "../../features/one-query/services/ApiServices";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  ConversationVersionData,
  setConversationVersionData,
} from "../one-query/conversationVersions";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  setNewGPTVersion,
  setNewGeminiVersion,
  setNewLlamaVersion,
} from "../one-query/chatsSlice";

// get conversationDataVersions Endpoint

// function to get all conversationDataVersions
const getConversationDataVersions = async () => {
  try {
    const response = await Api.get("/ConversationModel");
    return response.data;
  } catch (e) {}
};

// custom hook to get all conversationDataVersions using useQuery
const useGetConversationDataVersions = () => {
  // ConversationVersionData is the type of the data that will be returned from the query
  const { data, error, isPending, refetch } = useQuery<
    ConversationVersionData,
    Error
  >({
    queryKey: ["conversationDataVersions"],
    queryFn: getConversationDataVersions,
    enabled: false,
  });

  return { data, error, isPending, refetch };
};

// Hook to get a conversationVersion by return fun make this query and dispatch data
export const useGetConversationVersions = () => {
  const { data, error, isPending, refetch } = useGetConversationDataVersions();
  const dispatch = useDispatch();

  const getConversationVersions = async () => {
    const { data } = await refetch();
    if (data) {
      dispatch(setConversationVersionData(data));
    }
  };

  return getConversationVersions;
};
//////////////////////////////////
// Async function to check if a version is allowed for the user
const checkIfVersionIsAllowed = async (id: string) => {
  try {
    const response = await Api.get(`/Chat/IsAllowedForUser?id=${id}`);
    return response.data;
  } catch (e) {
    throw e;
  }
};

// Hook to use the mutation with React Query for checking version allowance
const useAllowedVersion = () => {
  return useMutation({
    mutationKey: ["isAllowedVersion"],
    mutationFn: checkIfVersionIsAllowed, // Mutation function that calls the API
  });
};

// Custom hook to manage the mutation and dispatch actions for checking version allowance
export const useIsAllowedVersion = () => {
  const { mutate } = useAllowedVersion();
  const versionIsAllowedMutate = (
    id: string,
    callback: (isAllowedVersion: boolean) => void
  ) => {
    mutate(id, {
      onSuccess: (isAllowed) => {
        if (callback) callback(isAllowed);
      },
      onError: (error) => {},
    });
  };

  return versionIsAllowedMutate;
};

// ========================================================================================================
