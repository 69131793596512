import React, { useEffect } from 'react'
import SideBar from '../components/SideBar'
import "./LandingPage.css"
import Models from '../../main-page/components/get-conversation/Models'
import Alert from '../../one-query/services/Alert'

function LandingPage() {
  return (
    <>
      <Alert/> 
    <div className="LandingPage d-flex"> 
      <SideBar />
      <Models page='Landing-page'/>
    </div>
    </>
  )
}

export default LandingPage
