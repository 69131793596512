import React, { useEffect, useRef, useState } from "react";
import "../../main-page/components/SettingPages/ChangePassword.css";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import { useLocation } from "react-router-dom";
import Alert from "../../one-query/services/Alert";
import {
  useforgetPassword,
  useResendConfirmEmailToUser,
} from "../../../store/Apis/UserApi";
import { constructUserAgent } from "@microsoft/signalr/dist/esm/Utils";
interface StateType {
  email?: string;
  prevPath?: string;
}
const ResendEmail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let darkMode = useAppSelector((state) => state.User.darkMode);
  const { forgetPasswordMutate, loading } = useforgetPassword();
  const resendConfirmEmail = useResendConfirmEmailToUser();
  const state = (location.state as StateType) || {};
  const confirmEmail = localStorage.getItem("confirmUserEmail");
  const prevPath = localStorage.getItem("prevPath");

  const handleResendConfirmEmail = () => {
    if (prevPath === "/account/register") {
      resendConfirmEmail(confirmEmail);
    } else if (prevPath === "/forget-password") {
      if (confirmEmail) {
        forgetPasswordMutate(confirmEmail);
      }
    } else {
    }
  };

  return (
    <>
      <Alert />
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div className="col-md-6 divCahngePasswordForm col-sm-12 ">
            <form className=" formDivPassword">
              <img
                src={
                  darkMode
                    ? "/images/GetConversations/Expertex-logo-dark.svg"
                    : "/images/GetConversations/Expertex-logo-light.svg"
                }
                className="ExpertexLogoRegister c-pointer"
                alt="Expertex_logo"
                onClick={() => navigate("/")}
              />
              <div className="m-2 ms-3 w-100  d-flex justify-content-center">
                <img
                  src={
                    darkMode
                      ? "/images/GetConversations/Resend-email-dark.svg"
                      : "/images/GetConversations/Resend-email.svg"
                  }
                />
              </div>
              <div className="mt-3 mb-3 d-flex justify-content-center flex-column align-items-center">
                <div className="divMessage ">
                  We sent a recovery link to you at:
                </div>
                {confirmEmail ? (
                  <div className="displayEmailResend">{confirmEmail}</div>
                ) : (
                  <div>No email provided.</div>
                )}
              </div>
              <button
                disabled={loading}
                className={`mt-1 btnResendEmail ${loading && "opacity-50"}`}
                style={{ backgroundColor: `${darkMode && "#12B6EA"}` }}
                onClick={handleResendConfirmEmail}>
                Resend recovery link
              </button>
            </form>
          </div>
          <div className="col-md-6 backgroundImage d-none d-md-block">
            <img
              src="/images/GetConversations/Register.png"
              alt="Change Password"
              className="imgChangePassword"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ResendEmail;
