import React, { useEffect } from "react";
import "./Popups.css";
import { Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../store/hooks";
import {
  useGetPaymentLink,
  useGetSubscribeCost,
} from "../../../../store/Apis/StripeApi";

interface UpgradeToProProps {
  showModal: boolean;
  closeModal: () => void;
}

const UpgradeToProPopup = ({ showModal, closeModal }: UpgradeToProProps) => {
  const userPlan = useAppSelector((s) => s.User.userPlan);
  const { link, error, isPending, getSubscribeLink } = useGetPaymentLink();
  const {
    cost,
    isLoading: costPending,
    error: errorPending,
    getSubscribeCost,
  } = useGetSubscribeCost();
  useEffect(() => {
    getSubscribeCost();
    getSubscribeLink();
  }, []);
  const freeFeatures = [
    "Message models simultaneously with one prompt.",
    "Unlimited history access.",
    "Customized results tailored to the command type.",
    "Unlimited personalized personas, easily stored.",
  ];

  const proFeatures = [
    "Everything in Community.",
    "Early access to new features.",
    "Unlimited access to all Pro models.",
  ];

  const goPayment = () => {
    if (isPending || error) return;
    window.open(link?.url, "_self");
  };

  return (
    <Modal
      className="model_container"
      size="lg"
      show={showModal}
      onHide={closeModal}
      centered
    >
      <Modal.Header closeButton className="modal_header upgrade_plan">
        <Modal.Title>
          <p className="mb-0">Upgrade your plan</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal_body">
        <div className="plans">
          <div className="free_plan">
            <div className="title">
              <h3 className="plan_title mb-1">Community (free)</h3>
              <span className="plan_price">USD $0/month</span>
            </div>
            <button disabled className="upgrade_btn free border-0">
              Your current plan
            </button>
            <p className="plan_description">
              For people just getting started with expertex
            </p>
            <div className="plan_features">
              {freeFeatures.map((feature, i) => (
                <div className="plan_feature" key={i}>
                  <div className="plan_arrow">
                    <img src="/images/GetConversations/icn-check-priceing-light.svg" />
                  </div>
                  <p>{feature}</p>
                </div>
              ))}
            </div>
            <div className="plan_help">
              Have an existing plan? See <a href="#">biling help</a>
            </div>
          </div>
          <div className="pro_plan">
            <div className="title">
              <div className="d-flex mb-1">
                <img
                  className="pro_icn"
                  src="/images/GetConversations/icn-pro-plans-screen-light.svg"
                  alt=""
                />
                <h3 className="plan_title">Pro</h3>
              </div>
              {cost && !costPending && !errorPending && (
                <span className="plan_price">USD ${cost} per person/month</span>
              )}
            </div>
            <div
              className="upgrade_btn pro border-0 c-pointer"
              style={{
                backgroundColor: isPending ? "#dce3e9" : "#12b6ea",
                color: isPending ? "#8f9193" : "#fff",
                cursor: isPending ? "wait" : "pointer",
              }}
              onClick={goPayment}
            >
              Upgrade to pro
            </div>
            <p className="plan_description">You will enjoy :</p>
            <div className="plan_features">
              {proFeatures.map((feature, i) => (
                <div className="plan_feature" key={i}>
                  <div>
                    <img className="plan_arrow" />
                  </div>
                  <p>{feature}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpgradeToProPopup;
