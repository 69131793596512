import React, { useEffect, useRef, useState, version } from "react";
import OneQueryInput from "./OneQueryInput";
import Api from "../../../one-query/services/ApiServices";
import SignalRService from "../../../one-query/services/SignalRService";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import ChatModel from "./ChatModel";
import Navbar from "./Navbar";
import {
  conversationsActions,
  SetActiveConversationModelStatus,
} from "../../../../store/one-query/conversationSlice";
import {
  setAnnonymousToken,
  signinUserSuccess,
} from "../../../../store/account/userSlice";
import useGetLastActiveModels, {
  useCreateConversation,
  useUpdateConversation,
  useUpdateConversationAnnonyPersona,
} from "../../../../store/Apis/ConversationApi";
import {
  getChatsOpen,
  setChat3Data,
  setChat4Data,
  setChat5Data,
  setChat6Data,
  setDisabledVersions,
  setFileError,
  setSelectedPersona,
} from "../../../../store/one-query/chatsSlice";
import { AttachmentPayload } from "../../../../Types";
import { useGetChatModelMessagesHistory } from "../../../../store/Apis/ChatModelApi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AlertMessage from "./AlertMessage";
import SnackBarAlert from "./SnackBarAlert";
import { ModelsCompany, ModelVersionStatus } from "../../../../Helper";
interface ModelsProps {
  page: string;
}

const Models = ({ page }: ModelsProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams<{ id: string }>();
  // current chats opened ids
  const { Chat1Data, Chat2Data, Chat3Data, Chat4Data, Chat5Data, Chat6Data } =
    useAppSelector((state) => state.ChatsState);
  const isAuthenticated = useAppSelector((s) => s.User.IsAuthenticated);
  const isAnnony: boolean = isAuthenticated ? false : true;
  const [conConIds, setConConIds] = useState<string[]>([]);
  const [imageQuery, setImageQuery] = useState<File>();
  const [queryImage, setQueryImage] = useState();
  const [query, setQuery] = useState("");
  const conversationState = useAppSelector((s) => s.Conversations);
  const chatNum = useAppSelector((s) => s.ChatsState.chatsNum);
  const maximizedChat = useAppSelector((s) => s.ChatsState.maximizedChat);
  const isConHistoryClicked = useAppSelector(
    (s) => s.Conversations.isConversationHistoryClicked
  );
  const lastActiveModels = useAppSelector(
    (s) => s.Conversations.lastActiveModels
  );
  const [attachmentFileData, setAttachmentFileData] =
    useState<AttachmentPayload>({ name: "", extension: "" });
  const isFullWidth = useAppSelector((state) => state.ChatsState.fullWidthMode);
  const currentPersona = useAppSelector(
    (state) => state.ChatsState.selectedPersona
  );
  const conversationModelsIDs = useAppSelector(
    (s) => s.Conversations.conversationConversationModels
  );
  const chatsOpened = useAppSelector(
    (state) => state.ChatsState.selectedOptions
  );
  const [arrayDataModelsIDS, setArrayDataModelsIDS] = useState<string[]>([]);
  const [arrayVersionsData, setArrayVersionsData] = useState<
    ModelVersionStatus[]
  >([]);
  // const [fileUploaded, setFileUploaded] = useState<any>();
  const newConversation = useAppSelector(
    (state) => state.ChatsState.newConversation
  );
  const disabledVersions = useAppSelector(
    (state) => state.ChatsState.disabledVersions
  );
  const selcectedPerson = useAppSelector(
    (state) => state.ChatsState.selectedPersona
  );
  const updateConversation = useUpdateConversation();
  const updateselectedPersonaAnnony = useUpdateConversationAnnonyPersona();
  const allConversations = useAppSelector(
    (state) => state?.Conversations.conversations
  );
  const userInputRef = useRef<any>(null);
  const user = useAppSelector((s) => s.User);
  const [canSetActiveModels, SetCanSetActiveModels] = useState<boolean>();
  const { getLastActiveModels, loading } = useGetLastActiveModels();
  const [modelsIdsState, setModelsIdsState] = useState<string[]>([]);
  const [modelsVersionsState, setModelsVersionsState] = useState<string[]>([]);
  const inputDisabled = arrayVersionsData?.every(
    (obj) => obj.isFinished === true
  );

  const modelsIds = [
    Chat1Data.id,
    Chat2Data.id,
    Chat3Data.id,
    Chat4Data.id,
    Chat5Data.id,
    Chat6Data.id,
  ];

  const modelsVersions = [
    Chat1Data.version,
    Chat2Data.version,
    Chat3Data.version,
    Chat4Data.version,
    Chat5Data.version,
    Chat6Data.version,
  ];

  const modelsNames = [
    Chat1Data.name,
    Chat2Data.name,
    Chat3Data.name,
    Chat4Data.name,
    Chat5Data.name,
    Chat6Data.name,
  ];

  //handle send selected persona to BE
  useEffect(() => {
    if (id && currentPersona?.title?.length > 0) {
      const conversationName = allConversations?.find(
        (con) => con?.id === conversationState?.activeConversationId
      )?.name;
      if (isAuthenticated) {
        updateConversation({
          id: conversationState?.activeConversationId,
          name: conversationName,
          personaId: currentPersona?.id,
        });
      } else {
        updateselectedPersonaAnnony({
          id: conversationState.activeConversationId,
          personaId: currentPersona.id,
        });
      }
    }
  }, [id, currentPersona]);

  // handle user sign in with external apps
  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const data: any = {
      token: params.get("token"),
      userData: {
        profilePictureURL: params.get("profile"),
        userName: params.get("userName"),
        email: params.get("email"),
        id: params.get("userId"),
        firstName: params.get("firstName"),
        lastName: params.get("lastName"),
        mode: params.get("mode"),
        isSigned: params.get("isSigned"),
        isSubscriped: params.get("isSubscriped")?.toLowerCase() === "true",
      },
    };
    if (data) {
      if (data?.token?.length > 0 && data.userData.isSigned) {
        dispatch(
          signinUserSuccess({
            IsAuthenticated: true,
            token: data?.token,
            userData: data?.userData,
          })
        );
      } else if (data?.token?.length === 0) {
        if (data.userData.mode === "login") {
          navigate("/");
          dispatch(
            setFileError({
              isError: true,
              errorMsg: "This email doesn't exist please register",
              reason: "user upload file type not support",
            })
          );
        } else if (data.userData.mode === "register") {
          navigate("/");
          dispatch(
            setFileError({
              isError: true,
              errorMsg: "This email already exist please sign in",
              reason: "user upload file type not support",
            })
          );
        }
      }
    }
  }, [location.search]);

  useEffect(() => {
    if (id) {
      getLastActiveModels(id);
      dispatch(conversationsActions.setActiveConversation(id));
    }
  }, []);

  useEffect(() => {
    if (chatNum === 2) {
      dispatch(setChat3Data({ id: "", version: "", name: "" }));
      dispatch(setChat4Data({ id: "", version: "", name: "" }));
      dispatch(setChat5Data({ id: "", version: "", name: "" }));
      dispatch(setChat6Data({ id: "", version: "", name: "" }));
    } else if (chatNum === 4) {
      dispatch(setChat5Data({ id: "", version: "", name: "" }));
      dispatch(setChat6Data({ id: "", version: "", name: "" }));
    }
  }, [chatNum]);

  useEffect(() => {
    setQuery("");
  }, [chatNum, newConversation]);

  useEffect(() => {
    setModelsIdsState(modelsIds.slice(0, chatNum));
    setModelsVersionsState(modelsVersions.slice(0, chatNum));
    const newArrayVersionsData = modelsVersions
      .slice(0, chatNum)
      .map((versionName) => ({ version: versionName, isFinished: true }));
    setArrayVersionsData(newArrayVersionsData);
  }, [
    chatNum,
    Chat1Data,
    Chat2Data,
    Chat3Data,
    Chat4Data,
    Chat5Data,
    Chat6Data,
  ]);
  const onModelIsFinished = (
    versionName: string,
    isFinishedToggle: boolean
  ) => {
    setArrayVersionsData((prevData) =>
      prevData.map((obj) =>
        obj.version === versionName
          ? { ...obj, isFinished: isFinishedToggle }
          : obj
      )
    );
  };

  // handle send last active models to BE
  const handleConversationModelStatus = () => {
    if (conversationState.activeConversationId) {
      const MlModelsIds = modelsIds.slice(0, chatNum).map((option, i) => {
        return { id: option, name: modelsNames[i], order: i + 1 };
      });
      // MlModelsIds = MlModelsIds?.filter((id) => id !== "");
      let conversationModelStatus = {
        conversationId: conversationState.activeConversationId,
        models: MlModelsIds,
      };
      dispatch(SetActiveConversationModelStatus(conversationModelStatus));
    }
  };

  useEffect(() => {
    let modeIds = modelsIds.slice(0, chatNum)?.filter((id) => id !== "");
    if (
      !isConHistoryClicked &&
      canSetActiveModels &&
      modeIds.length == chatNum
    ) {
      handleConversationModelStatus();
      SetCanSetActiveModels(false);
    }
  }, [
    conConIds,
    Chat1Data.id,
    Chat2Data.id,
    Chat3Data.id,
    Chat4Data.id,
    Chat5Data.id,
    Chat6Data.id,
  ]);

  useEffect(() => {
    dispatch(setDisabledVersions(modelsVersions));
  }, [
    Chat1Data,
    Chat2Data,
    Chat3Data,
    Chat4Data,
    Chat5Data,
    Chat6Data,
    newConversation,
  ]);

  let selectedConversation = conversationState.conversations.find(
    (con) => con?.id === conversationState.activeConversationId
  );

  // custom hooks to get history messages from chat models
  const GetModelChatMessages = useGetChatModelMessagesHistory();

  useEffect(() => {
    SignalRService.getInstance();
  }, []);

  const getConnectionId = () => {
    return SignalRService.getInstance().connection.connectionId;
  };

  // get messages history from all models

  useEffect(() => {
    if (isAuthenticated) {
      if (id && lastActiveModels && !newConversation) {
        GetModelChatMessages(id);
      }
    }
  }, [lastActiveModels, newConversation]);

  useEffect(() => {
    if (
      !isAuthenticated &&
      !user.userData?.id &&
      location.search.indexOf("token") === -1 // check if user sign in with external apps
    ) {
      Api.get("Account/LoginAnnonymous").then((e) => {
        dispatch(setAnnonymousToken(e.data));
      });
    }
  }, []);

  const addStringToArray = (newString: any) => {
    setArrayDataModelsIDS((prevArray) => [...prevArray, newString.id]);
  };

  useEffect(() => {
    setArrayDataModelsIDS([]);
    if (selectedConversation?.conversationConversationModels) {
      for (
        let i = 0;
        i < selectedConversation?.conversationConversationModels?.length;
        i++
      ) {
        if (selectedConversation?.conversationConversationModels[i])
          addStringToArray(
            selectedConversation?.conversationConversationModels[i]
          );
      }
    }
  }, [selectedConversation]);

  const conversationModels = chatsOpened.map((option, i) => {
    let chatId = "";
    if (i === 0) {
      chatId = Chat1Data.id;
    } else if (i === 1) {
      chatId = Chat2Data.id;
    } else if (i === 2) {
      chatId = Chat3Data.id;
    } else if (i === 3) {
      chatId = Chat4Data.id;
    } else if (i === 4) {
      chatId = Chat5Data.id;
    } else if (i === 5) {
      chatId = Chat6Data.id;
    }

    return { name: option, order: i + 1, id: chatId };
  });

  const conversationModelsForAnnonymonus = conversationModels?.filter(
    (item) => !["JinaAi"].includes(item.name)
  );

  const handelNewConversation = (query: string) => {
    if (newConversation && query !== "") {
      var conversationObj = {
        title: query.slice(0, 20),
        ConversationModels: conversationModels,
        PersonaId: selcectedPerson?.id,
      };
      return conversationObj;
    }
  };

  const receieveUserImageQuery = (imgQueryURL: File) => {
    if (imageQuery) {
      setImageQuery(imgQueryURL);
    }
  };
  const handleFileDelete = () => {
    if (userInputRef.current) {
      userInputRef.current.handleDeleteUploadFile();
    }
  };
  const SetActiveModels = () => {
    SetCanSetActiveModels(true);
  };
  const handleSendRequest = (
    query: string,
    fileUpload?: any,
    conIDs?: string[],
    activeConId?: string
  ) => {
    if (maximizedChat?.versionName?.length) {
      setArrayVersionsData((prevData) =>
        prevData.map((obj) =>
          obj.version === maximizedChat.versionName
            ? { ...obj, isFinished: false }
            : obj
        )
      );
    } else {
      setArrayVersionsData((prevData) =>
        prevData?.map((obj) => ({
          ...obj,
          isFinished: false,
        }))
      );
    }
    setQuery(query);
    const formData = new FormData();
    setQueryImage(queryImage);
    if (maximizedChat) {
      formData.append(`Models[0].Id`, maximizedChat.versionId);
      formData.append(`Models[0].Key`, maximizedChat.versionName);
    } else {
      // Append models data
      if (chatNum > 0) {
        if (Chat1Data.version) {
          formData.append(`Models[0].Id`, Chat1Data.id);
          formData.append(`Models[0].Key`, Chat1Data.version);
        }
        if (Chat2Data.version) {
          formData.append(`Models[1].Id`, Chat2Data.id);
          formData.append(`Models[1].Key`, Chat2Data.version);
        }
      }
      if (chatNum > 2) {
        if (Chat3Data.version) {
          formData.append(`Models[2].Id`, Chat3Data.id);
          formData.append(`Models[2].Key`, Chat3Data.version);
        }
        if (Chat4Data.version) {
          formData.append(`Models[3].Id`, Chat4Data.id);
          formData.append(`Models[3].Key`, Chat4Data.version);
        }
      }
      if (chatNum > 4) {
        if (Chat5Data.version) {
          formData.append(`Models[4].Id`, Chat5Data.id);
          formData.append(`Models[4].Key`, Chat5Data.version);
        }
        if (Chat6Data.version) {
          formData.append(`Models[5].Id`, Chat6Data.id);
          formData.append(`Models[5].Key`, Chat6Data.version);
        }
      }
    }

    formData.append(
      "ConversationId",
      conversationState.activeConversationId || id || activeConId + ""
    );
    if (selcectedPerson?.id?.length > 0)
      formData.append("PersonaId", selcectedPerson?.id);
    const saveData = () => {
      const connectionId = signalRInstance.connection.connectionId;
      formData.append("ConnectionId", connectionId + "");
      formData.append("Propmpt", query);
      formData.append("IsAnnonymous", isAnnony + "");
      if (!isAnnony) {
        formData.append(
          "ConversationConversationModelIds",
          JSON.stringify(modelsIdsState || conversationModelsIDs)
        );
      }
      if (fileUpload) {
        formData.append("Attachment", fileUpload[0]);
      }
      Api.post(`/chat?connectionId=${connectionId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    };
    // Check if the SignalR connection is open, if not, reopen it
    const signalRInstance = SignalRService.getInstance();
    if (signalRInstance.connection.state === "Disconnected") {
      signalRInstance.connection
        .start()
        .then(() => {
          saveData();
        })
        .catch((error) => {});
    } else {
      saveData();
    }
  };

  const createConversation = useCreateConversation();

  const handleSendQuery = (query: string, attachmentFile: FileList) => {
    if (attachmentFile) {
      setAttachmentFileData({
        name: attachmentFile[0].name.split(".")[0],
        extension: attachmentFile[0].name.split(".")[1],
      });
    }

    if (isAuthenticated) {
      if (
        conversationState.activeConversationId !== undefined ||
        null ||
        id !== undefined
      ) {
        handleSendRequest(query, attachmentFile, arrayDataModelsIDS);
      } else {
        const conversation = handelNewConversation(query);
        if (conversation) {
          createConversation(
            conversation,
            handleSendRequest,
            query,
            attachmentFile
          )
            .then(({ activeConId, conversationModelsIds }) => {
              setConConIds(conversationModelsIds);
              handleFileDelete();
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      }
    } else {
      if (conversationState.activeConversationId !== undefined || null) {
        handleSendRequest(query, attachmentFile);
      } else {
        let body = {
          Models: [
            { Id: Chat1Data.id, Key: "" },
            { Id: Chat2Data.id, Key: "" },
            { Id: Chat3Data.id, Key: "" },
            { Id: Chat4Data.id, Key: "" },
            { Id: Chat5Data.id, Key: "" },
            { Id: Chat6Data.id, Key: "" },
          ],
          title: query.slice(0, 20),
          connectionId: getConnectionId(),
          ConversationModels: conversationModelsForAnnonymonus,
          PersonaId: selcectedPerson?.id,
        };
        Api.post(
          "Conversation/CreateAnnonConversation",
          JSON.stringify(body)
        ).then((e) => {
          const { title, id, description, objective } = e?.data?.persona;
          dispatch(setSelectedPersona({ id, title, description, objective }));
          let activeConId = e.data?.id;
          dispatch(conversationsActions.setActiveConversation(activeConId));
          handleSendRequest(query, attachmentFile, conConIds, activeConId);
        });
      }
    }
  };

  return (
    <div
      className="Models px-4"
      style={{
        width: `${isFullWidth !== "" ? "100vw" : "78.6vw"}`,
      }}
    >
      <Navbar onChatsNumChanged={SetActiveModels} />
      <div
        className={`row modelsContainer d-flex position-relative${
          isFullWidth === "ExpertModel" && "d-none"
        }`}
      >
        {Array(chatNum)
          .fill(0)
          .map((_, index) => (
            <ChatModel
              onChatNumChanged={SetActiveModels}
              key={index}
              page={page}
              currentQuery={query}
              setQuery={setQuery}
              index={index}
              versionName={modelsVersionsState[index]}
              versionId={modelsIdsState[index]}
              attachmentFileData={attachmentFileData}
              modelName={chatsOpened[index]}
              modelCompanyName={ModelsCompany[index]}
              onModelIsFinished={onModelIsFinished}
            />
          ))}
      </div>
      <OneQueryInput
        ref={userInputRef}
        receieveUserImageQuery={receieveUserImageQuery}
        handleSendQuery={handleSendQuery}
        inputDisabled={true}
      />
    </div>
  );
};

export default Models;
