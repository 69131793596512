import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SidebarApp from "../../get-conversation/getConversation";
import "../PrivacyAndTermCondition.css";
import SearchBar from "./SearchBar";
import FilterSearch from "./FilterSearch";
import SummaryAndSuggestion from "./Summery&suggestion";
import SearchContent from "./SearchContent";
import Pagination from "./Pagination";
import results from "./data.json";
import { handleSendHelpRequest } from "../../../../../store/Apis/ChatModelApi";
import { useAppSelector } from "../../../../../store/hooks";
import { Button, Col } from "react-bootstrap";
import Skeleton from "./Skeleton";
import SignalRService from "../../../../one-query/services/SignalRService";
import Filter from "../../get-conversation/Filter";

const HelpAndSupport = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [loadingModelReplay, setLoadingModelReplay] = useState(false);
  const [searchWithSuggestion, setSearchWithSuggestion] = useState<string>("");
  const [response, setResponse] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [isContentVisible, setIsContentVisible] = useState(false);
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  const ResponsiveAppSize = useAppSelector(
    (s) => s.ChatsState.ResponsiveAppSize
  );

  const indexOfLastResult = currentPage * resultsPerPage;
  const indexOfFirstResult = indexOfLastResult - resultsPerPage;
  const currentResults = results.slice(indexOfFirstResult, indexOfLastResult);

  useEffect(() => {
    SignalRService.getInstance();
  }, []);

  const handleSearchWithSuggestion = (question: string) => {
    setSearchWithSuggestion(question);
  };

  const handleIsHelpRequestLoading = (loadingStatus: boolean) => {
    setIsLoading(loadingStatus);
  };

  const handleSetSearch = (value: any) => {
    setInputValue(value);
  };

  const handleSetResponse = (res: any) => {
    setResponse(res);
  };

  const handleShowContent = () => {
    setIsContentVisible(true);
  };

  const handleStartOverBtn = () => {
    setIsContentVisible(false);
    setResponse("");
    setInputValue("");
  };

  const handleReturnPageFooter = () => {
    return (
      <div
        className={`page_footer align-self-start mx-2 ${
          isContentVisible ? "mt-5" : "mb-5"
        }`}
      >
        <div className="contact_us_title d-flex align-items-center flex-wrap">
          <p className="mb-2 me-2">
            If you’d like a member of our support team to respond to you,please
            <span onClick={() => navigate("/contact-us")}>Contact us</span>
          </p>
        </div>
      </div>
    );
  };

  const handleReturnStartOverBtn = () => {
    return (
      <Col xs="auto" onClick={handleStartOverBtn}>
        {isLoading ? (
          <Skeleton
            width="78px"
            height="32px"
            margin="m-0"
            borderRadius="20px"
          />
        ) : (
          <Button
            variant="primary"
            className="start-over-button"
            onMouseEnter={(e) => {
              e.currentTarget.classList.add("hover");
            }}
            onMouseLeave={(e) => {
              e.currentTarget.classList.remove("hover");
            }}
          >
            start over
          </Button>
        )}
      </Col>
    );
  };

  return (
    <div className="d-flex home-page">
      <div>
        <SidebarApp />
      </div>
      <div className={`mt-3 flex-grow-1 `}>
        <h3
          className="c-pointer ms-2 d-inline SettingDivHeader"
          onClick={() => navigate("/settings")}
        >
          <img className="icn_arrow_left ms-2" />
          <span className="d-none d-md-inline settingWord">Setting &gt;</span>
        </h3>
        <h3 className="d-inline termLetter">Help & support</h3>
        <div className="divContainer container-fluid mt-4  d-flex flex-column">
          <h3 className="page_title ms-2">Ask Expertex</h3>
          {!isContentVisible && handleReturnPageFooter()}
          <div className="page_content container position-relative px-0">
            <SearchBar
              suggestionQuestion={searchWithSuggestion}
              handelSendRequest={handleSendHelpRequest}
              setResponse={handleSetResponse}
              setIsContentVisible={handleShowContent}
              inputValue={inputValue}
              setInputValue={handleSetSearch}
            />
            {isContentVisible && (
              <div className="content-container">
                {ResponsiveAppSize !== "Mobile" ? (
                  <FilterSearch
                    load={isLoading}
                    loadedModelReplay={loadingModelReplay}
                    setLoadedModelReplay={() => {}}
                    startOverBtn={handleReturnStartOverBtn()}
                  />
                ) : (
                  <div className="d-flex align-items-center justify-content-between my-4">
                    <Filter currentPlace="HelpAndSupport" />
                    {handleReturnStartOverBtn()}
                  </div>
                )}
                <SummaryAndSuggestion
                  currentPage={currentPage}
                  handleSearchWithSuggestion={handleSearchWithSuggestion}
                  isLoading={isLoading}
                  setIsLoading={handleIsHelpRequestLoading}
                  response={response}
                  setResponse={handleSetResponse}
                  handelSendRequest={handleSendHelpRequest}
                  inputValue={inputValue}
                />
                {isContentVisible && handleReturnPageFooter()}
              </div>
            )}
            {/* <SearchContent
              results={currentResults}
              load={isLoading}
              loadedModelReplay={loadingModelReplay}
            /> */}
            {/* <Pagination
              resultsPerPage={resultsPerPage}
              totalResults={results.length}
              currentResults={currentResults.length}
              paginate={paginate}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpAndSupport;
