import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AttachmentPayload } from "../../Types";
import { useAppDispatch } from "../hooks";
import { setParentMessageId } from "./chatsSlice";
import { ChatModelEditsState, ChatModelState, dataReplyType } from "../../Helper";
interface DataPayload {
  chatModelData: ChatModelState[],
  chatModelsReponsesAndEdits: ChatModelEditsState[];
}
const initialState: DataPayload = {
   chatModelData: [],
  chatModelsReponsesAndEdits: []
};

const ChatModelSlice = createSlice({
  name: "ChatModel",
  initialState: initialState,
  reducers: {
    addChatModelMessages: (
      state: DataPayload,
      action: PayloadAction<ChatModelState>
    ) => {
      state.chatModelData.push(action.payload);
    },
    getModelChatMessage: (
      state: DataPayload,
      action: PayloadAction<ChatModelState[]>
    ) => {
      state.chatModelData.splice(0, state.chatModelData.length);
      state.chatModelData.push(...action.payload);
    },
    updatePlaceOfMessage: (
      state: DataPayload,
      action: PayloadAction<string>
    ) => {
      const messageId = action.payload;

      // Find the index of the message with the given messageId
      const messageIndex = state.chatModelData.findIndex(
        (model) => model.id === messageId
      );

      if (messageIndex !== -1) {
        // Remove the message from its current position
        const [message] = state.chatModelData.splice(messageIndex, 1);

        // Add the message to the end of the array
        state.chatModelData.push(message);
      }
    },
    setNewChatModel: (state: DataPayload) => {
      state.chatModelData.splice(0, state.chatModelData.length);
    },
    setIsRegenerating: (
      state: DataPayload,
      action: PayloadAction<{ id: string; isRegernating: boolean }>
    ) => {
      const { id, isRegernating } = action.payload;
      const message = state.chatModelData.find((model) => model.id === id);
      if (message) {
        message.isRegernating = isRegernating;
      }
    },
    setIsEditing: (
      state: DataPayload,
      action: PayloadAction<{ id: string; isEditing: boolean }>
    ) => {
      const { id, isEditing } = action.payload;
      const message = state.chatModelData.find((model) => model.id === id);
      if (message) {
        message.isEditing = isEditing;
      }
    },
    appendReplyToChatModelMessage: (
      state: DataPayload,
      action: PayloadAction<{ id: string; reply: dataReplyType }>
    ) => {
      const { id, reply } = action.payload;
      // Find the chat model by ID
      const chatModel = state.chatModelData.find((model) => model.id === id);
      if (chatModel) {
        // Initialize replies if undefined or not an array
        if (!Array.isArray(chatModel.replies)) {
          chatModel.replies = [];
        }
        // Push the new reply into the existing replies array
        chatModel.replies.push(reply);
      }
    },
    setQueryEditsMessage: (
      state: DataPayload,
      action: PayloadAction<{ messageId: string; parentMessageId: string }>
    ) => {
      const { messageId, parentMessageId } = action.payload;
      const chatModelMessage = state.chatModelData.find(
        (model) => model.id === parentMessageId
      );
      if (chatModelMessage) {
        if (!chatModelMessage.queryEdits) {
          chatModelMessage.queryEdits = [];
        }
        const idExists = chatModelMessage.queryEdits?.includes(messageId);
        const idParentExists =
          chatModelMessage.queryEdits?.includes(parentMessageId);
        if (!idParentExists) {
          chatModelMessage.queryEdits?.unshift(parentMessageId);
        }
        if (!idExists) {
          chatModelMessage.queryEdits?.push(messageId);
        }
        setParentMessageId(null);
      }
    },
    addChatModelEditsAndResponsesMessages: (
      state: DataPayload,
      action: PayloadAction<ChatModelEditsState>
    ) => {
      state.chatModelsReponsesAndEdits.push(action.payload);
    },
    getChatModelEditsAndResponsesMessages: (
      state: DataPayload,
      action: PayloadAction<ChatModelEditsState>
    ) => {
      state.chatModelsReponsesAndEdits.push(action.payload);
    },
    setNextEditIndex: (
      state: DataPayload,
      action: PayloadAction<{ messageId: string }>
    ) => {
      const message = state.chatModelsReponsesAndEdits.find(
        (item) => item.MessageId === action.payload.messageId
      );
      if (message) {
        message.EditMessageIndex += 1; // Increment EditMessageIndex by 1
        message.ResponseIndex = 0;
      }
    },
    setPrevEditIndex: (
      state: DataPayload,
      action: PayloadAction<{ messageId: string }>
    ) => {
      const message = state.chatModelsReponsesAndEdits.find(
        (item) => item.MessageId === action.payload.messageId
      );
      if (message && message.EditMessageIndex > 0) {
        message.EditMessageIndex -= 1; // Decrement EditMessageIndex by 1
        message.ResponseIndex = 0;
      }
    },
    setNextReplyIndex: (
      state: DataPayload,
      action: PayloadAction<{ messageId: string }>
    ) => {
      const message = state.chatModelsReponsesAndEdits.find(
        (item) => item.MessageId === action.payload.messageId
      );
      if (message) {
        message.ResponseIndex += 1; // Increment EditMessageIndex by 1
      }
    },
    setPrevReplyIndex: (
      state: DataPayload,
      action: PayloadAction<{ messageId: string }>
    ) => {
      const message = state.chatModelsReponsesAndEdits.find(
        (item) => item.MessageId === action.payload.messageId
      );
      if (message && message.ResponseIndex > 0) {
        message.ResponseIndex -= 1; // Decrement EditMessageIndex by 1
      }
    },
    updateModelReplyIndex: (
      state: DataPayload,
      action: PayloadAction<{ messageId: string; replyIndex: number }>
    ) => {
      const message = state.chatModelsReponsesAndEdits.find(
        (item) => item.MessageId === action.payload.messageId
      );
      if (message) {
        message.ResponseIndex = action.payload.replyIndex;
      }
    },
    updateModelEditIndex: (
      state: DataPayload,
      action: PayloadAction<{ messageId: string | null; editIndex: number }>
    ) => {
      const message = state.chatModelsReponsesAndEdits.find(
        (item) => item.MessageId === action.payload.messageId
      );
      if (message) {
        message.EditMessageIndex = action.payload.editIndex;
      }
    },
  },
});

export const addChatModelMessageAsync =
  (message: ChatModelState) => (dispatch: any) => {
    dispatch(addChatModelMessages(message));
  };
  export const addEditAndReplayChatModelAsync =
    (message: ChatModelEditsState) => (dispatch: any) => {
      dispatch(addChatModelEditsAndResponsesMessages(message));
    };

export const {
  addChatModelMessages,
  appendReplyToChatModelMessage,
  getModelChatMessage,
  updatePlaceOfMessage,
  setNewChatModel,
  setIsEditing,
  setIsRegenerating,
  setQueryEditsMessage,
  addChatModelEditsAndResponsesMessages,
  getChatModelEditsAndResponsesMessages,
  setNextEditIndex,
  setPrevEditIndex,
  setNextReplyIndex,
  setPrevReplyIndex,
  updateModelReplyIndex,
  updateModelEditIndex,
} = ChatModelSlice.actions;
export default ChatModelSlice.reducer;
