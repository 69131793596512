import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../store/hooks";
import SidebarApp from "./getConversation";
import SettingsSide from "./SettingsSide";

const SettingComponent = () => {
  return (
    <div className="d-flex home-page">
      <div>
        <SidebarApp />
      </div>
      <div className="flex-grow-1">
          <SettingsSide
            setToDarkMode={() =>
              document
                .querySelector("body")
                ?.setAttribute("color-theme", "dark")
            }
            setToLightMode={() =>
              document
                .querySelector("body")
                ?.setAttribute("color-theme", "light")
            }
          />
      </div>
    </div>
  );
};

export default SettingComponent;
