import React, { useEffect, useState } from "react";
import {
  regenerateResponse,
  setCurrentObjSave,
  setReGenerateMessage,
} from "../../../../store/one-query/chatsSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import "./expertFinds.css";
import { useRegenerateMessagesAsync } from "../../../../store/Apis/ChatModelApi";
import SignalRService from "../../../one-query/services/SignalRService";
import { setIsRegenerating } from "../../../../store/one-query/ChatModelSlice";
interface Props {
  messageId?: string;
  response: string;
  question?: string;
  setQuery?: (prompt: string) => void;
}
const EmotionResponse = (props: Props) => {
  const { messageId, response, question, setQuery } = props;
  const RegenerateMessageAPI = useRegenerateMessagesAsync();
  let darkMode = useAppSelector((state) => state.User.darkMode);
  const messages = useAppSelector((state) => state.chatModel.chatModelData);

  const dispatch = useAppDispatch();
  const [likeSrc, setLikeSrc] = useState("");
  const [dislikeSrc, setDislikeSrc] = useState("");
  const [regenerationIcon, setRegenerationIcon] = useState("");
  const [copyResponse, setcopyResponse] = useState("");
  const [toggleEmotionHappy, setToggleEmotionHappy] = useState(false);
  const [toggleEmotionSad, setToggleEmotionSad] = useState(false);
  useEffect(() => {
    if (!darkMode) {
      setLikeSrc("/images/ReactionEmotionChat/happy-strick.svg");
      setDislikeSrc("/images/ReactionEmotionChat/sad-strick.svg");
      setRegenerationIcon("/images/ReactionEmotionChat/refresh.svg");
      setcopyResponse("/images/ReactionEmotionChat/content_copy.svg");
      // setbookMark("/images/ReactionEmotionChat/bookmark_light.svg");
    } else {
      setLikeSrc("/images/ReactionEmotionChat/happy-strick-dark.svg");
      setDislikeSrc("/images/ReactionEmotionChat/sad-strick-dark.svg");
      setRegenerationIcon("/images/ReactionEmotionChat/refresh-dark.svg");
      setcopyResponse(
        "/images/ReactionEmotionChat/content_copy-strick-dark.svg"
      );
      // setbookMark("/images/ReactionEmotionChat/bookmark-strick-dark.svg");
    }
  }, [darkMode]);
  const copyResponseToClipboard = () => {
    navigator.clipboard.writeText(response);
    if (!darkMode) {
      setcopyResponse("/images/ReactionEmotionChat/check_small.svg");
      setTimeout(() => {
        setcopyResponse("/images/ReactionEmotionChat/content_copy.svg");
      }, 500);
    } else {
      setcopyResponse("/images/ReactionEmotionChat/check_small_dark_blue.svg");
      setTimeout(() => {
        setcopyResponse(
          "/images/ReactionEmotionChat/content_copy-strick-dark.svg"
        );
      }, 500);
    }
  };
  const handleReGenerateResponse = () => {
    const connectionId = SignalRService.getInstance().connection.connectionId;
    if (messageId && connectionId) {
      const currentObj = messages?.find((obj) => obj.id === messageId);
      if (currentObj) dispatch(setCurrentObjSave(currentObj));
      dispatch(setIsRegenerating({ id: messageId, isRegernating: true }));
      RegenerateMessageAPI(messageId, connectionId);
      if (question && setQuery) setQuery(question);
    }
    if (!darkMode) {
      setRegenerationIcon("/images/ReactionEmotionChat/refresh-blue.svg");
      setTimeout(() => {
        setRegenerationIcon("/images/ReactionEmotionChat/refresh.svg");
      }, 500);
    } else {
      setRegenerationIcon("/images/ReactionEmotionChat/refresh-blue-dark.svg");
      setTimeout(() => {
        setRegenerationIcon("/images/ReactionEmotionChat/refresh-dark.svg");
      }, 500);
    }
  };
  // const handleBookMark = () => {
  //   if (!darkMode) {
  //     setbookMark("/images/ReactionEmotionChat/bookmark_blue.svg");
  //     setTimeout(() => {
  //       setbookMark("/images/ReactionEmotionChat/bookmark-filling.svg");
  //     }, 500);
  //   } else {
  //     setbookMark("/images/ReactionEmotionChat/bookmark-blue-dark.svg");
  //     setTimeout(() => {
  //       setbookMark("/images/ReactionEmotionChat/bookmark-filling-dark.svg");
  //     }, 500);
  //   }
  // };
  const handleChangeEmotionHappy = () => {
    if (toggleEmotionHappy == false && toggleEmotionSad == false) {
      setToggleEmotionHappy(true);
      if (!darkMode) {
        setLikeSrc("/images/ReactionEmotionChat/happy-blue.svg");
        setTimeout(() => {
          setLikeSrc("/images/ReactionEmotionChat/happy-filling.svg");
        }, 500);
      } else {
        setLikeSrc("/images/ReactionEmotionChat/happy-dark-blue.svg");
        setTimeout(() => {
          setLikeSrc("/images/ReactionEmotionChat/happy-filling-dark.svg");
        }, 500);
      }
    } else if (toggleEmotionHappy == true && toggleEmotionSad == false) {
      if (!darkMode) {
        setLikeSrc("/images/ReactionEmotionChat/happy-strick.svg");
      } else {
        setLikeSrc("/images/ReactionEmotionChat/happy-strick-dark.svg");
      }
      setToggleEmotionHappy(false);
    }
  };
  const handleChangeEmotionSad = () => {
    if (toggleEmotionSad == false && toggleEmotionHappy == false) {
      setToggleEmotionSad(true);
      if (!darkMode) {
        setDislikeSrc("/images/ReactionEmotionChat/sad-blue.svg");
        setTimeout(() => {
          setDislikeSrc("/images/ReactionEmotionChat/sad-filling.svg");
        }, 500);
      } else {
        setDislikeSrc("/images/ReactionEmotionChat/sad-dark-blue.svg");
        setTimeout(() => {
          setDislikeSrc("/images/ReactionEmotionChat/sad-filling-dark.svg");
        }, 500);
      }
    } else if (toggleEmotionSad == true && toggleEmotionHappy == false) {
      if (!darkMode)
        setDislikeSrc("/images/ReactionEmotionChat/sad-strick.svg");
      else setDislikeSrc("/images/ReactionEmotionChat/sad-strick-dark.svg");
      setToggleEmotionSad(false);
    }
  };
  return (
    <div className="rating d-flex  pt-1">
      <button
        className="d-flex  align-items-center gap-1 EmotionIcon "
        onClick={handleReGenerateResponse}
      >
        <img className="" src={regenerationIcon} />
      </button>

      <button
        className="d-flex align-items-center gap-1 EmotionIcon"
        onClick={copyResponseToClipboard}
      >
        <img className="" src={copyResponse} />
      </button>
      {/* <button className="d-flex gap-4 EmotionIcon" onClick={handleBookMark}>
        <img src={bookMark} />
      </button> */}
      <button
        className="EmotionIcon d-flex align-items-center c-pointer"
        onClick={handleChangeEmotionHappy}
        disabled={toggleEmotionSad}
      >
        <img src={likeSrc} />
      </button>
      <button
        className="EmotionIcon d-flex align-items-center c-pointer"
        onClick={handleChangeEmotionSad}
        disabled={toggleEmotionHappy}
      >
        <img src={dislikeSrc} />
      </button>
    </div>
  );
};

export default EmotionResponse;
