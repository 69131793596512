import React, { useEffect, useRef, useState } from "react";
import "./ChangePassword.css";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { Link, useNavigate } from "react-router-dom";
import Alert from "../../../one-query/services/Alert";
import { setNewPassword } from "../../../../store/Apis/UserApi";
import { changePasswordRegex } from "../../../../Helper";
const ResetPassword = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let darkMode = useAppSelector((state) => state.User.darkMode);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [warningMessageNewPassword, setWarningMessageNewPassword] =
    useState(false);
  const [messageNewPasswordError, setMessageNewPasswordError] = useState("");
  const [messageConfirmPasswordError, setMessageConfirmPasswordError] =
    useState("");

  const forgetUserEmail = localStorage.getItem("confirmUserEmail");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [warningMessageconfirmation, setWarningMessageconfirmation] =
    useState(false);

  const [Password, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleCheckPassword = (e: any) => {
    setPassword((oldData) => ({ ...oldData, [e.target.name]: e.target.value }));
  };


  useEffect(() => {
    let isDisabled = true;

    if (Password.newPassword.length > 1) {
      setWarningMessageNewPassword(false);
      if (changePasswordRegex.test(Password.newPassword)) {
        setMessageNewPasswordError("Great, all done.");
        isDisabled = false;
      } else if (!/[A-Z]/.test(Password.newPassword))
        setMessageNewPasswordError("Password must contain an uppercase Letter");
      else if (!/[a-z]/.test(Password.newPassword))
        setMessageNewPasswordError("Password must contain a lowercase Letter");
      else if (!/\d/.test(Password.newPassword))
        setMessageNewPasswordError("Password must contain a numeric character");
      else if (!/[!@#$%^&*(),.?":{}|<>]/.test(Password.newPassword))
        setMessageNewPasswordError("Password must contain a special character");
      else if (Password.newPassword.length <= 8)
        setMessageNewPasswordError("Password must be at least 8 characters");
    }

    if (changePasswordRegex.test(Password.newPassword)) {
      if (Password.confirmPassword.length > 1) {
        setWarningMessageconfirmation(false);
        if (Password.confirmPassword.length <= 1)
          setMessageConfirmPasswordError("Confirm Password is required");
        else if (!(Password.confirmPassword === Password.newPassword))
          setMessageConfirmPasswordError("Passwords do Not match");
        else {
          setMessageConfirmPasswordError("Great, passwords matched");
          isDisabled = false;
        }
      }
    }

    // Disable the button if the passwords do not match or do not meet requirements
    if (
      !changePasswordRegex.test(Password.newPassword) ||
      Password.confirmPassword !== Password.newPassword
    ) {
      isDisabled = true;
    }

    setIsButtonDisabled(isDisabled);
  }, [Password.newPassword, Password.confirmPassword]);

  const handleResetPassword = () => {
    if (Password.newPassword && Password.confirmPassword) {
      dispatch(
        setNewPassword(
          forgetUserEmail,
          Password?.newPassword,
          Password.confirmPassword
        )
      );
      navigate("/account/login");
    }
  };

  return (
    <>
      <Alert />
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div className="col-md-6 divCahngePasswordForm col-sm-12 ">
            <form className=" formDivPassword  " style={{ width: "23rem" }}>
              <img
                src={
                  darkMode
                    ? "/images/GetConversations/Expertex-logo-dark.svg"
                    : "/images/GetConversations/Expertex-logo-light.svg"
                }
                className="ExpertexLogoChangePassword"
                alt="Expertex_logo"
              />
              <div className="m-2 ms-3 w-100  d-flex align-start">
                <p className="headerChangePassword ms-3">
                  Change your password
                </p>
              </div>
              <div className="mb-1">
                {/* input New Password */}
                <div className="containerShowPassword mb-2 ">
                  <input
                    type={showNewPassword ? "text" : "password"}
                    value={Password?.newPassword}
                    name="newPassword"
                    className={
                      messageNewPasswordError.length > 1 &&
                      Password.newPassword.length > 0 &&
                      messageNewPasswordError !== "Great, all done."
                        ? "inputPasswordBorderRed"
                        : "inputPasswordBorderBlue"
                    }
                    placeholder="New password"
                    onChange={handleCheckPassword}
                    onFocus={() => {
                      setWarningMessageNewPassword(true);
                    }}
                    onBlur={() => {
                      setWarningMessageNewPassword(false);
                    }}
                  />
                  {Password?.newPassword && (
                    <img
                      className="clickable eyeLogo eyePosition"
                      src={
                        showNewPassword
                          ? "/Images/icn-open-eye.svg"
                          : "/Images/icn-eye-off.svg"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setShowNewPassword(!showNewPassword);
                      }}
                    />
                  )}
                  {warningMessageNewPassword &&
                    Password.newPassword.length < 1 && (
                      <div className=" ms-1 d-flex" style={{ width: "23rem" }}>
                        <img src="/images/icn-warning.svg" />
                        <div className="warningMessageNewPassword ps-2 text-left">
                          Password must contain an uppercase letter, a lowercase
                          letter, and a numeric character.
                        </div>
                      </div>
                    )}
                  {Password.newPassword.length > 0 &&
                    !warningMessageNewPassword &&
                    messageNewPasswordError.length > 1 && (
                      <div className=" ms-1 d-flex" style={{ width: "23rem" }}>
                        {messageNewPasswordError === "Great, all done." ? (
                          <img src="/images/check_circle.svg" />
                        ) : darkMode ? (
                          <img src="/images/icn-error-circle-dark.svg" />
                        ) : (
                          <img src="/images/icn-error-circle.svg" />
                        )}
                        <div
                          className="warningMessageNewPassword ps-2 text-left"
                          style={
                            messageNewPasswordError === "Great, all done."
                              ? { color: "#12B6EA" }
                              : {
                                  color: "var(--font-color12)",
                                }
                          }
                        >
                          {messageNewPasswordError}
                        </div>
                      </div>
                    )}
                </div>
                {/* input Confirm Password */}
                <div className="containerShowPassword mb-1">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    value={Password?.confirmPassword}
                    name="confirmPassword"
                    placeholder="Confirm password"
                    className={
                      messageConfirmPasswordError.length > 1 &&
                      Password.confirmPassword.length > 0 &&
                      messageConfirmPasswordError !== "Great, passwords matched"
                        ? "inputPasswordBorderRed"
                        : "inputPasswordBorderBlue"
                    }
                    onChange={handleCheckPassword}
                    onFocus={() => {
                      setWarningMessageconfirmation(true);
                    }}
                    onBlur={() => {
                      setWarningMessageconfirmation(false);
                    }}
                  />
                  {Password?.confirmPassword && (
                    <img
                      className="clickable eyeLogo eyePosition"
                      src={
                        showConfirmPassword
                          ? "/Images/icn-open-eye.svg"
                          : "/Images/icn-eye-off.svg"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setShowConfirmPassword(!showConfirmPassword);
                      }}
                    />
                  )}
                  {warningMessageconfirmation &&
                    Password.confirmPassword.length == 0 && (
                      <div className=" ms-1 d-flex" style={{ width: "23rem" }}>
                        <img src="/images/icn-warning.svg" />
                        <div className="warningMessageNewPassword ps-2 text-left">
                          Please! Make sure your Passwords match
                        </div>
                      </div>
                    )}
                  {Password.confirmPassword.length > 0 &&
                    !warningMessageconfirmation &&
                    messageConfirmPasswordError.length > 0 && (
                      <div className=" ms-1 d-flex" style={{ width: "23rem" }}>
                        {messageConfirmPasswordError ===
                        "Great, passwords matched" ? (
                          <img src="/images/check_circle.svg" />
                        ) : darkMode ? (
                          <img src="/images/icn-error-circle-dark.svg" />
                        ) : (
                          <img src="/images/icn-error-circle.svg" />
                        )}
                        <div
                          className="warningMessageNewPassword ps-2 text-left"
                          style={
                            messageConfirmPasswordError ===
                            "Great, passwords matched"
                              ? { color: "#12B6EA" }
                              : {
                                  color: "var(--font-color12)",
                                }
                          }
                        >
                          {messageConfirmPasswordError}
                        </div>
                      </div>
                    )}
                </div>

                <div>
                  <button
                    className={`mt-1 btnPassword ${
                      isButtonDisabled && "disabledBtn"
                    }`}
                    disabled={isButtonDisabled}
                    onClick={handleResetPassword}
                  >
                    Save changes
                  </button>
                  <Link
                    to={"/account/login"}
                    className="mt-2 btnPassword btnCancel"
                  >
                    Cancel
                  </Link>
                </div>
              </div>
            </form>
          </div>
          <div className="col-md-6 backgroundImage d-none d-md-block">
            <img
              src="/Images/change-password.png"
              alt="Change Password"
              className="imgChangePassword"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
