import React, {
  ChangeEvent,
  DragEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { Modal } from "react-bootstrap";
import "./Popups.css";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  useEditUserNameMutation,
  useprofilePicture,
} from "../../../../store/Apis/UserApi";
import { setUpdateUserName } from "../../../../store/account/userSlice";
import { nameRegex } from "../../../../Helper";
interface UpdateProfileProps {
  showModel: boolean;
  closeModal: () => void;
  userData: any;
  avatarBackground: string | undefined;
}
interface DataProfileInterface {
  firstName: string;
  lastName: string;
}
const UpdateProfile = ({
  showModel,
  closeModal,
  userData,
  avatarBackground,
}: UpdateProfileProps) => {
  const dispatch = useAppDispatch();
  const [image, setImage] = useState<ArrayBuffer | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [userName, setUserName] = useState<DataProfileInterface>({
    firstName: "",
    lastName: "",
  });
  const User = useAppSelector((s) => s.User);

  const userId = useAppSelector((s) => s.User.userData?.id);
  const setProfilePage = useprofilePicture();
  const inputRef = useRef<HTMLInputElement>(null);

  const pictureUrl = useAppSelector((s) => s.User?.profilePictureURL);
  const [profilePictureUrl, setProfilePictureUrl] = useState<string>();
  const [validationErrorStateFirstName, setValidationErrorStateFirstName] =
    useState<string>("");
  const [validationErrorStateLastName, setValidationErrorStateLastName] =
    useState<string>("");
  const updateUserName = useEditUserNameMutation();

  useEffect(() => {
    // this validation for first name
    if (userName.firstName.length === 0) {
      setValidationErrorStateFirstName("Please enter your first name");
    } else if (!nameRegex.test(userName.firstName.trim())) {
      setValidationErrorStateFirstName("Enter characters only");
    } else {
      setValidationErrorStateFirstName("");
    }
    // this validation for last name
    if (userName.lastName.length === 0) {
      setValidationErrorStateLastName("Please enter your last name");
    } else if (!nameRegex.test(userName.lastName.trim())) {
      setValidationErrorStateLastName("Enter characters only");
    } else {
      setValidationErrorStateLastName("");
    }
  }, [userName]);

  useEffect(() => {
    if (image) {
      setProfilePictureUrl(
        `data:image/png;base64,${arrayBufferToBase64(image)}`
      );
    } else if (pictureUrl) setProfilePictureUrl(pictureUrl);
  }, [pictureUrl, image]);
  useEffect(() => {
    if (showModel && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showModel]);

  const userAvatar = useAppSelector((s) => s.User.userAvatar);
  const handleDivClick = () => {
    fileInputRef.current?.click();
  };

  const handleDeleteUserImage = () => {
    setImage(null);
  };
  useEffect(() => {
    if (userData)
      setUserName({
        firstName: userData.firstName,
        lastName: userData.lastName,
      });
  }, [userData]);

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result as ArrayBuffer);
        // Reset the input value to allow the same file to be selected again
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    handleDragOver(event);
    const file = event.dataTransfer.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result as ArrayBuffer);
        // Reset the input value to allow the same file to be selected again
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };
  const handleUserName = (e: any) => {
    setUserName((oldData) => ({ ...oldData, [e.target.name]: e.target.value }));
  };

  const handleSavePicture = () => {
    if (
      nameRegex.test(userName.firstName.trim()) &&
      nameRegex.test(userName.lastName.trim())
    ) {
      handleUpdateUserName();
      dispatch(
        setUpdateUserName({
          firstName: userName?.firstName.trim(),
          lastName: userName?.lastName.trim(),
        })
      );
      closeModal();
    }
    if (image && userId) {
      setProfilePage({ file: image, userId: userId });
      closeModal();
    }
  };

  const handleCancel = () => {
    closeModal();
    setImage(null);
    setUserName({
      firstName: userData.firstName,
      lastName: userData.lastName,
    });
  };

  const handleUpdateUserName = () => {
    if (User.userData?.id) {
      updateUserName(
        User.userData?.id,
        userName?.firstName,
        userName?.lastName
      );
    }
  };

  return (
    <Modal
      className="model_container"
      show={showModel}
      backdrop="static"
      centered>
      <Modal.Header className="modal_header">
        <Modal.Title>
          <p className="mb-0">Update Profile</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal_body user_popup">
        <div
          onClick={handleDivClick}
          onDragOver={handleDragOver}
          onDrop={handleDrop}>
          {profilePictureUrl ? (
            <img src={profilePictureUrl} className="user_avatar_name " />
          ) : (
            <span
              className="user_avatar_name "
              style={{ backgroundColor: avatarBackground }}>
              {userAvatar}
            </span>
          )}

          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleImageUpload}
          />
        </div>
        <div className="user_name row d-flex">
          <div className="col-5 p-1 pb-0 ms-1" style={{ height: "80px" }}>
          <input
            className={`inputStyle w-100 `}
            type="text"
            value={userName?.firstName}
            name="firstName"
            onChange={(e) => handleUserName(e)}
            ref={inputRef}
          />
            {validationErrorStateFirstName?.length > 0 && (
              <p className="ErrorStyleForName">
                {validationErrorStateFirstName}
              </p>
            )}
          </div>
          <div className="col-5 p-1 pb-0 ms-1" style={{ height: "80px" }}>
          <input
            className={`inputStyle w-100 `}
            type="text"
            value={userName?.lastName}
            name="lastName"
            onChange={(e) => handleUserName(e)}
          />
            {validationErrorStateLastName?.length > 0 && (
              <p className="ErrorStyleForName">
                {validationErrorStateLastName}
              </p>
            )}
          </div>
        </div>

        <div className="user_name row d-flex"></div>
        {!profilePictureUrl && (
          <div className="user_tip">
            Drag and drop your image here, or{" "}
            <span onClick={handleDivClick}>click here</span> to select an image
          </div>
        )}
        <div className="containerButtons mt-4">
          <button className="confirm_btn" onClick={handleSavePicture}>
            Save
          </button>
          <button className="cancel_btn" onClick={handleCancel}>
            Cancel
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateProfile;
