import React, { useEffect, useState } from "react";
import Privacy_TermContent from "./PrivacyContent";
import "./PrivacyAndTermCondition.css";
import { useAppSelector } from "../../../../store/hooks";
import { useNavigate } from "react-router-dom";
import SidebarApp from "../get-conversation/getConversation";

const Privacy_Policy = () => {
  const navigate = useNavigate();
  let darkMode = useAppSelector((state) => state.User.darkMode);

  return (
    <div className="d-flex home-page">
      <div>
        <SidebarApp />
      </div>
      <div className="ms-4 mt-3 me-4">
        <h3
          className="c-pointer ms-2  d-inline SettingDivHeader"
          onClick={() => navigate("/settings")}
        >
          <img
            src={
              darkMode
                ? "Images/SettingsSideResources/icn-arrow-left-dark.svg"
                : "Images/SettingsSideResources/icn-arrow-left.svg"
            }
          />
          <span className="d-none d-md-inline settingWord">Setting &gt;</span>
        </h3>
        <h3 className="d-inline termLetter"> Privacy & policy</h3>

        <div className="mt-4 divContainer container-fluid ">
          <Privacy_TermContent
            header={"Expertex privacy and policy"}
            Content={[
              <p>{`Expertex, Inc. (“Expertex,” “we,” “us,” or “our”) provides a SaaS-based AI-powered engine that allows professionals and enterprises to LLM models for multiple purposes (the “Services”). This Privacy Policy describes how we handle personal information that we collect about our customers, including when they use our Services or visit our website, https://Expertex.ai/. \nWe treat all personal information covered by this Privacy Policy as pertaining to individuals acting as business representatives, and not in their individual or household capacity. This Privacy Policy does not apply to our handling of personal information that we process on behalf of our customers as a service provider.`},
            </p>]}
          ></Privacy_TermContent>
          <Privacy_TermContent
            header={"Personal Information We Collect"}
            subHeader={"Information you provide to us may include:"}
            Content={[
              <ul>
                <li>
                  Account information and contact details, such as your
                  company’s name, your first and last name, username and
                  password, email address, mailing address, and phone number.
                </li>
                <li>
                  Automatic data collection. We, our service providers, and our
                  advertising partners may automatically log information about
                  you, your computer or mobile device, and your interaction over
                  time with our website and the Services (in your capacity as
                  our customer’s authorized user), our communications and other
                  online services, such as:
                </li>
                <li>
                  Payment information, including your payment account details,
                  which we may obtain from your financial institution, when you
                  choose to connect your account to the Services.
                </li>
                <li>
                  Communications that we exchange with you, including when you
                  contact us with questions, feedback, or otherwise.
                </li>
                <li>
                  Marketing data, such as your preferences for receiving our
                  marketing communications, and details about your engagement
                  with them.
                </li>
                <li>
                  Other data not specifically listed here, which we will use as
                  described in this Privacy Policy or as otherwise disclosed at
                  the time of collection.
                </li>
                <li>
                  Third party sources. We may combine personal information we
                  receive from you with personal information we obtain from
                  other sources, such as:
                  <ul style={{ listStyleType: "lower-alpha" }}>
                    <li>
                       Third parties, such as our business partners and
                      others.
                    </li>
                    <li> Public sources, such as social media platforms.</li>
                  </ul>
                </li>
                <li>
                  Automatic data collection. We, our service providers, and our
                  advertising partners may automatically log information about
                  you, your computer or mobile device, and your interaction over
                  time with our website and the Services (in your capacity as
                  our customer’s authorized user), our communications and other
                  online services, such as:
                  <ul style={{ listStyleType: "lower-alpha" }}>
                    <li>
                       Online activity data, such as pages or screens you
                      viewed, how long you spent on a page or screen, the
                      website you visited before browsing to the website,
                      navigation paths between pages or screens, information
                      about your activity on a page or screen, access times, and
                      duration of access, and whether you have opened our
                      marketing emails or clicked links within them.
                    </li>
                    <li>
                       Device data, such as your computer’s or mobile device’s
                      operating system type and version, manufacturer and model,
                      browser type, screen resolution, RAM and disk size, CPU
                      usage, device type (e.g., phone, tablet), IP address,
                      unique identifiers (including identifiers used for
                      advertising purposes), language settings, mobile device
                      carrier, radio/network information (e.g., WiFi, LTE, 3G),
                      and general location information such as city, state or
                      geographic area.
                    </li>
                  </ul>
                </li>
                <li>
                  Device data, such as your computer’s or mobile device’s
                  operating system type and version, manufacturer and model,
                  browser type, screen resolution, RAM and disk size, CPU usage,
                  device type (e.g., phone, tablet), IP address, unique
                  identifiers (including identifiers used for advertising
                  purposes), language settings, mobile device carrier,
                  radio/network information (e.g., WiFi, LTE, 3G), and general
                  location information such as city, state or geographic area.
                </li>
              </ul>,
            ]}
          ></Privacy_TermContent>

          {/* second part */}
          <Privacy_TermContent
            subHeader={
              "We use the following tools for automatic data collection:"
            }
            Content={[
              <ul>
                <li>
                  Cookies, which are text files that websites store on a
                  visitor‘s device to uniquely identify the visitor’s browser or
                  to store information or settings in the browser for the
                  purpose of helping you navigate between pages efficiently,
                  remembering your preferences, enabling functionality, helping
                  us understand user activity and patterns, and facilitating
                  online advertising.
                </li>
                <li>
                  Local storage technologies, like HTML5, that provide
                  cookie-equivalent functionality but can store larger amounts
                  of data, including on your device outside of your browser in
                  connection with specific applications.
                </li>
                <li>
                  Web beacons, also known as pixel tags or clear GIFs, which are
                  used to demonstrate that a webpage or email was accessed or
                  opened, or that certain content was viewed or clicked.
                </li>
              </ul>,
            ]}
          ></Privacy_TermContent>
          {/* 3 */}
          <Privacy_TermContent
            header={"How We Share Personal Information"}
            Content={[
              <p className="boldText">We may share your personal information with:</p>,
              <ul>
                <li>
                  <span className="boldText">Affiliates.</span> Our corporate parent, subsidiaries, and
                  affiliates, for purposes consistent with this Privacy Policy.
                </li>
                <li>
                <span className="boldText">Service providers.</span> Companies and individuals that provide
                  services on our behalf or help us operate our Services or our
                  business (such as banking partners, hosting, information
                  technology, customer support, email delivery, and website
                  analytics services).
                </li>
                <li>
                <span className="boldText">Advertising partners.</span> Third party advertising companies,
                  including for the interest-based advertising purposes
                  described above, that may collect information on our website
                  through cookies and other automated technologies.
                </li>
                <li>
                <span className="boldText">Professional advisors.</span> Professional advisors, such as lawyers,
                  auditors, bankers and insurers, where necessary in the course
                  of the professional services that they render to us.
                </li>
                <li>
                <span className="boldText">Authorities and others.</span> Law enforcement, government
                  authorities, and private parties, as we believe in good faith
                  to be necessary or appropriate for the compliance and
                  protection purposes described above.
                </li>
                <li>
                <span className="boldText">Business transferees.</span> Acquirers and other relevant
                  participants in business transactions (or negotiations for
                  such transactions) involving a corporate divestiture, merger,
                  consolidation, acquisition, reorganization, sale or other
                  disposition of all or any portion of the business or assets
                  of, or equity interests in, Expertex or our affiliates
                  (including, in connection with a bankruptcy or similar
                  proceedings).
                </li>
              </ul>,
            ]}
          ></Privacy_TermContent>
          {/* 4 */}
          <Privacy_TermContent
            header={"Your Choices"}
            Content={[
              <p className="boldText">We may share your personal information with:</p>,
              <ul>
                <li>
                <span className="boldText">Opt out of marketing communications.</span> You may opt out of
                  marketing-related communications by following the opt out or
                  unsubscribe instructions contained in the marketing
                  communications we send you.
                </li>
                <li>
                <span className="boldText">Online tracking opt-out.</span> There are a number of ways to limit
                  online tracking, which we have summarized below:
                  <ul style={{ listStyleType: "lower-alpha" }}>
                    <li>
                       Blocking cookies in your browser. Most browsers let you
                      remove or reject cookies, including cookies used for
                      interest-based advertising. To do this, follow the
                      instructions in your browser settings. Many browsers
                      accept cookies by default until you change your settings.
                      For more information about cookies, including how to see
                      what cookies have been set on your device and how to
                      manage and delete them, visit: 
                      <a href="https://allaboutcookies.org/"> www.allaboutcookies.org
                      </a>
                    </li>
                    <li>
                       Blocking advertising ID use in your mobile
                      settings. Your mobile device settings may provide
                      functionality to limit use of the advertising ID
                      associated with your mobile device for interest-based
                      advertising purposes.
                    </li>
                    <li>
                       Using privacy plug-ins or browsers. You can block our
                      websites from setting cookies used for interest-based ads
                      by using a browser with privacy features, like Brave, or
                      installing browser plugins like <a href="https://privacybadger.org/">Privacy Badger</a>, <a href="https://www.ghostery.com/">Ghostery</a>,
                       or <a href="https://ublock.org/en">uBlock Origin</a>, and configuring them to block third
                      party cookies/trackers. You can also opt out of Google
                      Analytics by downloading and installing the browser
                      plug-in available at: 
                      <a href="https://tools.google.com/dlpage/gaoptout"> https://tools.google.com/dlpage/gaoptout
                      </a>
                    </li>
                    <li>
                       Do Not Track. Some Internet browsers may be configured
                      to send “Do Not Track” signals to the online services that
                      you visit. We currently do not respond to “Do Not Track”
                      or similar signals. To find out more about “Do Not Track,”
                      please visit: 
                      <a href="https://www.allaboutdnt.com/"> https://www.allaboutdnt.com
                      </a>
                    </li>
                    <li>
                      Advertising industry opt out tools. You can also use these
                      opt out options to limit use of your information for
                      interest-based advertising by participating companies:
                      <ul style={{listStyleType:"disc"}}>
                        <li>
                          Digital Advertising Alliance for Websites:
                          <a href="https://optout.aboutads.info/?c=2&lang=EN"> optout.aboutads.info
                          </a>
                        </li>
                        <li>
                          Digital Advertising Alliance for Mobile Apps:
                          <a href="https://youradchoices.com/appchoices"> https://youradchoices.com/appchoices
                          </a>
                        </li>
                        <li>
                          Network Advertising Initiative:
                          <a href="https://optout.networkadvertising.org/?c=1"> optout.networkadvertising.org
                          </a>
                        </li>
                        <li>
                          Note that because these opt out mechanisms are
                          specific to the device or browser on which they are
                          exercised, you will need to opt out on every browser
                          and device that you use.
                        </li>
                      </ul>
                    </li>
                    <li>
                       Platform opt outs. The following advertising partners
                      offer opt out features that let you opt out of use of your
                      information for interest-based advertising:
                      <ul style={{listStyleType:"disc"}}>
                        <li>
                          Google:
                          <a href="https://myadcenter.google.com/home?sasb=true&ref=ad-settings"> https://adssettings.google.com/
                          </a>
                        </li>
                        <li>
                          Facebook:
                          <a href="https://www.facebook.com/about/ads"> www.facebook.com/about/ads
                          </a>
                        </li>
                        <li>
                          Twitter:
                          <a href="https://x.com/settings/account/personalization?mx=2"> www.twitter.com/settings/personalization
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>,
            ]}
          ></Privacy_TermContent>
          {/* 5 */}
           <Privacy_TermContent
            header={"Other Sites and Services"}
            Content={[
              <p>Our Services may contain links to websites and other online services operated by third parties. In addition, our content may be integrated into web pages or other online services that are not associated with us. These links and integration are not an endorsement of, or representation that we are affiliated with, any third party. We do not control websites or online services operated by third parties, and we are not responsible for their actions.</p>,
            ]}
          ></Privacy_TermContent>
          {/* 6 */}
          <Privacy_TermContent
            header={"Data Security"}
            Content={[
              <p>We employ a number of technical, organizational and physical safeguards designed to protect the personal information we collect. However, no security measures are failsafe and we cannot guarantee the security of your personal information.</p>,
            ]}
          ></Privacy_TermContent>
          {/* 7 */}
          <Privacy_TermContent
            header={"Children"}
            Content={[
              <p>The Services are not intended for use by children under 13 years of age. If we learn that we have collected personal information through our Services from a child under 13 without the consent of the child’s parent or guardian as required by law, we will delete it.</p>,
            ]}
          ></Privacy_TermContent>
          {/* 8 */}
          <Privacy_TermContent
            header={"Changes to This Privacy Policy"}
            Content={[
              <p>We reserve the right to modify this Privacy Policy at any time. If we make material changes to this Privacy Policy, we will notify you by updating the date of this Privacy Policy and posting it on the website.</p>,
            ]}
          ></Privacy_TermContent>
        </div>
      </div>
    </div>
  );
};

export default Privacy_Policy;
