import React from 'react'
import LoginComponent from '../components/LoginComponent2'
import Alert from '../../one-query/services/Alert'

function LoginView() {
    
  return (
    <>
    <Alert/>
    <LoginComponent />
    </>
  )
}

export default LoginView