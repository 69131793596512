import React, { useState, useEffect } from "react";
import "./SettingsSide.css";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import LogoutPopup from "../SettingPages/LogoutPopup";
import { useNavigate } from "react-router-dom";
import { handleNewConversationRequest } from "../../../../store/one-query/chatsSlice";
interface ChangeSideDisplay {
  setToDarkMode: () => void;
  setToLightMode: () => void;
}
function SettingsSide({ setToDarkMode, setToLightMode }: ChangeSideDisplay) {
  const navigate = useNavigate();
  const dispatch=useAppDispatch();
  const switchToMainSide = () => {
    navigate("/main-page");
    dispatch(handleNewConversationRequest());

  };
  let darkMode = useAppSelector((state) => state.User.darkMode);
  const [toggleState, setToggleState] = useState(darkMode);
  useEffect(() => {
    if (toggleState) {
      setToDarkMode();
    } else {
      setToLightMode();
    }
  }, [toggleState]);
  const [active, setActive] = useState("None");
  return (
    <>
      <div className="settingsMain">
        <div className="settingsMainOffset">
          <div className="settingsHeader">
            <img
              className="arrowIconImg"
              src="/Images/SettingsSideResources/icn-arrow-left.svg"
              onClick={() => switchToMainSide()}
            />
            <p className="settingsHeaderTitle">Settings</p>
          </div>
          <div className="settingsBody">
            {/* Hidden for now Please Don't Remove this commented code */}
            <div
              className="settingsOptionDiv c-pointer"
              onClick={() => navigate("/privacy-policy")}>
              <p className="settingsOptionTitle">Privacy & policy</p>
              <img className="settingsOptionIcon" src="" />
            </div>
            <div
              className="settingsOptionDiv c-pointer"
              onClick={() => navigate("/term-condition")}>
              <p className="settingsOptionTitle">Terms & conditions</p>
              <img
                className="settingsOptionIcon"
                src="/Images/SettingsSideResources/icn-arrow-left-dark.svg"
              />
            </div>
            <div
              className="settingsOptionDiv c-pointer"
              onClick={() => {
                navigate("/help-and-support");
              }}>
              <p className="settingsOptionTitle">Help & support</p>
              <img
                className="settingsOptionIcon"
                src="/Images/SettingsSideResources/icn-arrow-left.svg"
              />
            </div>
            <div
              className="settingsOptionDiv c-pointer"
              onClick={() => {
                navigate("/change-password");
              }}>
              <p className="settingsOptionTitle">Change password</p>
              <img
                className="settingsOptionIcon"
                src="/Images/SettingsSideResources/icn-arrow-left.svg"
              />
            </div>
            {/* <div className="settingsOptionDiv">
              <p className="settingsOptionTitle">Cancel subscription</p>
              <img
                className="settingsOptionIcon"
                src="/Images/SettingsSideResources/icn-arrow-left.svg"
              />
            </div> */}
          </div>
        </div>
      </div>
      {active === "Logout" && <LogoutPopup />}
    </>
  );
}
export default SettingsSide;
