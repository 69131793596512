import {
  getChatsOpen,
  setChat1Data,
  setChat2Data,
  setChat3Data,
  setChat4Data,
  setChat5Data,
  setChat6Data,
  setIsEditingStatus,
  setMaximizedChat,
  setParentMessageId,
  setUpLoadedFile,
  setUserInteracted,
  toggleFullWidthMode,
} from "../../../../store/one-query/chatsSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import SignalRService from "../../../one-query/services/SignalRService";
import React, { useEffect, useState, useRef, useCallback, memo } from "react";
import UserQuestion from "./UserQuestion";
import "./Models.css";
import ToggleTheme from "./ToggleTheme";
import ReturnVersionModel from "./ReturnVersionModel";
import { AttachmentPayload, LastActiveVersion } from "../../../../Types";
import {
  addChatModelMessageAsync,
  addEditAndReplayChatModelAsync,
  appendReplyToChatModelMessage,
  setIsEditing,
  setIsRegenerating,
  updateModelEditIndex,
  updateModelReplyIndex,
  updatePlaceOfMessage,
} from "../../../../store/one-query/ChatModelSlice";
import {
  ChatModelState,
  ModelsCompany,
  ModelsNamesCompany,
  dataReplyType,
  modelNames,
} from "../../../../Helper";
import { conversationsActions } from "../../../../store/one-query/conversationSlice";
import { useParams } from "react-router-dom";
import ResponseLoading from "./ResponseLoading";
import StreamingUserQuestion from "./StreamingUserQuestion";
import StreamingChatResponse from "./StreamingChatResponse";
interface Prompt {
  currentQuery: string;
  setQuery: (q: string) => void;
  index: number;
  page: string;
  versionName: any;
  versionId: string;
  modelName: string;
  modelCompanyName: string;
  attachmentFileData: AttachmentPayload;
  onChatNumChanged: () => void;
  onModelIsFinished:(version:string,isFinished:boolean)=>void;
}

interface MessageDataType {
  id: string;
  version: string;
}
const ChatModel = memo(({
  currentQuery,
  setQuery,
  index,
  page,
  versionName,
  versionId,
  modelName,
  modelCompanyName,
  attachmentFileData,
  onChatNumChanged,
  onModelIsFinished,
}: Prompt) => {
  const [defaultAnnonyVersion, setDefaultAnnonyVersion] = useState<string>("");
  const selectedOptionsStore = useAppSelector(
    (state) => state.ChatsState.selectedOptions
  );
  const isUserInteracted = useAppSelector(
    (state) => state.ChatsState.isUserInteracted
  );
  const newConversation = useAppSelector(
    (state) => state.ChatsState.newConversation
  );
  const disabledVersions = useAppSelector((s) => s.ChatsState.disabledVersions);
  const isFullWidth = useAppSelector((state) => state.ChatsState.fullWidthMode);
  const [selectedModelVersion, setSelectedModelVersion] = useState<string>("");
  const selectedColumns = useAppSelector((state) => state.ChatsState.chatsNum);
  const [defaultVersion, setDefaultVersion] = useState<string>("");
  const ChatsState = useAppSelector((state) => state.ChatsState);
  const { id } = useParams<{ id: string }>();
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [isFinished, setIsFinished] = useState(false);
  const [response, setResponse] = useState("");
  const dispatch = useAppDispatch();
  const conversationVersionModels = useAppSelector(
    (state) => state.conversationVersion.data
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const modelState = useAppSelector((s) => s.chatModel.chatModelData);
  const [company, setCompany] = useState(modelCompanyName);
  const [currentModelName, setCurrentModelName] = useState(modelName);
  const lastActiveModelsVersions = useAppSelector(
    (s) => s.Conversations.lastActiveModels
  );
  const [lastActiveVersion, setLastActiveVersion] = useState<LastActiveVersion>(
    { id: "", name: "", version: "" }
  );

  enum MessageType {
    NewMessage = 1,
    Regenerate,
    Edit,
  }
  const [messageType, setMessageType] = useState<any>();
  const activeConversationId = useAppSelector(
    (s) => s.Conversations.activeConversationId
  );
  const isEditingStatus = useAppSelector((s) => s.ChatsState.isEditing);
  const currentObj = useAppSelector((s) => s?.ChatsState?.currentObj);
  const isSubscribed = useAppSelector((s) => s.User.isSubscribed);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [prompt, setPrompt] = useState("");
  const maximizedChat = useAppSelector((s) => s.ChatsState.maximizedChat);
  const ParentMessageId = useAppSelector((s) => s.ChatsState.ParentMessageId);
  const [messageData, setMessageData] = useState<MessageDataType>({
    id: "",
    version: "",
  });

  useEffect(() => {
    if (currentQuery && currentQuery.length > 0) {
      if (isFullWidth && maximizedChat) {
        if (maximizedChat.versionName === selectedModelVersion) {
          setPrompt(currentQuery);
        }
      } else {
        setPrompt(currentQuery);
      }
    }
  }, [currentQuery]);

  useEffect(() => {
    if (lastActiveModelsVersions?.length > 0) {
      setLastActiveVersion(lastActiveModelsVersions[index]);
    }
  }, [lastActiveModelsVersions, index]);

  useEffect(() => {
    const defaultObjModel = findDefaultChatModelObj();
    if (defaultObjModel) {
      setDefaultAnnonyVersion(defaultObjModel.title);
    }
  }, [currentModelName]);

  const maxChat = (chatName?: string) => {
    const targetChatName = chatName || `${currentModelName}${index}`;
    dispatch(toggleFullWidthMode(targetChatName));
    dispatch(
      setMaximizedChat({
        versionId: versionId,
        versionName: selectedModelVersion,
      })
    );
  };

  const miniChat = (chatName?: string) => {
    const targetChatName = chatName || `${currentModelName}${index}`;
    dispatch(toggleFullWidthMode(targetChatName));
    dispatch(setMaximizedChat(null));
  };

  // get first object in each model
  let firstObjInChatModel = conversationVersionModels?.find(
    (obj: any) =>
      obj?.name?.toLowerCase() === currentModelName?.toLowerCase() &&
      obj?.isDefault
  );
   let firstObjInChatModelInAnnon = conversationVersionModels?.find(
     (obj: any) =>
       obj?.name?.toLowerCase() === currentModelName?.toLowerCase() &&
       obj?.isDefault &&
       obj?.allowAnnonymous
   );
  // set default versions of annonymous page

  // toggle Dropdown of company selector
  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  //get the company name of the model chat
  const getCompanyNameByModelName = (mName: string) => {
    return ModelsNamesCompany[mName];
  };

  // update model and model company name
  useEffect(() => {
    setCurrentModelName(modelName);
    const companyName = getCompanyNameByModelName(
      currentModelName?.toLowerCase()
    );
    setCompany(companyName);
  }, [currentModelName, lastActiveVersion, modelName]);

  // get lastActiveVersion if found
  useEffect(() => {
    if (id && currentModelName && !newConversation) {
      if (
        lastActiveVersion &&
        lastActiveVersion.name?.toLowerCase() ===
          currentModelName?.toLowerCase()
      ) {
        const versionObj = conversationVersionModels?.find(
          (obj) => obj.id === lastActiveVersion.id
        );
        if (versionObj) {
          setDefaultVersion(versionObj.title);
        }
      } else {
        const defaultObjModel = findDefaultChatModelObj();
        if (defaultObjModel) {
          setDefaultVersion(defaultObjModel.title);
        }
      }
    }
  }, [lastActiveVersion, currentModelName]);

  useEffect(() => {
    if (newConversation) {
      if (currentModelName) {
        const defaultObjModel = findDefaultChatModelObj();
        if (defaultObjModel) {
          setDefaultVersion(defaultObjModel.title);
        }
      }
      setQuery("");
      setPrompt("");
      setResponse("");
    }
  }, [newConversation, currentModelName]);

  const findDefaultChatModelObj = () => {
    // Get all default versions for the current model
    const modelVersions = conversationVersionModels?.filter((obj) => {
      return isSubscribed
        ? obj?.name?.toLowerCase() === currentModelName?.toLowerCase() &&
            obj?.isDefault
        : obj?.name?.toLowerCase() === currentModelName?.toLowerCase() &&
            !obj?.isPro &&
            obj?.isDefault;
    });
    if (modelVersions) {
      for (const version of modelVersions) {
        const isDisabled = ChatsState.disabledVersions.some(
          (disableObj) => disableObj === version.version
        );
        if (!isDisabled) {
          return version;
        }
      }
    }
    const availableVersions = conversationVersionModels?.filter((obj) => {
      return isSubscribed
        ? obj?.name?.toLowerCase() === currentModelName?.toLowerCase()
        : obj?.name?.toLowerCase() === currentModelName?.toLowerCase() &&
            !obj?.isPro;
    });
    // Iterate over the available versions and return the first non-disabled one
    if (availableVersions) {
      for (const version of availableVersions) {
        const isDisabled = ChatsState.disabledVersions.some(
          (disableObj) => disableObj === version.version
        );
        if (!isDisabled) {
          return version;
        }
      }
    }
    // If no non-disabled version is found, return undefined
    return undefined;
  };

  const handleNewReply = (chatId: string, newReply: string) => {
    const reply: dataReplyType = {
      createdAt: new Date().toISOString(), // Adding a timestamp for the reply
      reply: newReply,
    };
    // Dispatch the reply to the Redux store
    dispatch(appendReplyToChatModelMessage({ id: chatId, reply }));
  };

  useEffect(() => {
    if (isFinished && messageData.id.length > 0) {
      onModelIsFinished(messageData.version, true);
      if (messageType === MessageType.Regenerate) {
        dispatch(
          setIsRegenerating({
            id: messageData.id,
            isRegernating: false,
          })
        );
        handleNewReply(messageData.id, response); // Add the reply to the replies array
        setIsFinished(false);
      } else {
        const messageEditsAndResponses = {
          MessageId: messageData.id,
          EditMessageIndex: 0,
          ResponseIndex: 0,
        };
        if (messageType === MessageType.Edit) {
          dispatch(
            setIsEditing({ id: ParentMessageId || "", isEditing: false })
          );
          dispatch(setIsEditingStatus(false));
        }

        const message = {
          id: messageData.id || "",
          parentId: ParentMessageId,
          prompt: prompt,
          isRegernating: false,
          isEditing: false,
          replies: [{ createdAt: new Date().toISOString(), reply: response }],
          attachmentFile: attachmentFileData,
          version: selectedModelVersion,
          queryEdits: [],
          messageType: messageType,
        };

        // Dispatch the async action to save the new message to the store
        dispatch(addChatModelMessageAsync(message));

        const findMsgObject = modelState.find(
          (msg: any) => msg.id === ParentMessageId
        );

        if (findMsgObject?.queryEdits && findMsgObject?.queryEdits.length > 0) {
          dispatch(
            updateModelEditIndex({
              messageId: ParentMessageId,
              editIndex: findMsgObject?.queryEdits?.length - 1,
            })
          );
        }

        dispatch(addEditAndReplayChatModelAsync(messageEditsAndResponses));
        setIsFinished(false);
      }
      // Clear the response and prompt for the next input
      setResponse("");
      setPrompt("");
      setQuery("")
      setMessageType("");
      dispatch(setParentMessageId(null));
    }
  }, [isFinished, messageData, messageType]);

  const handleModelMessage = useCallback(
    (
      modelName: string,
      modelVersion: string,
      message: string,
      tag: string,
      conversationId: string,
      messageId: string,
      messageType: number
    ) => {
      if (selectedModelVersion === modelVersion)
        setMessageData({ version: modelVersion, id: messageId });
      setMessageType(messageType);

      if (versionName.length > 0) {
        if (modelVersion === versionName) {
          if (activeConversationId === conversationId) {
            if (message === "**/**Finished**/**") {
              if (
                messageType === MessageType.Regenerate ||
                messageType === MessageType.Edit
              ) {
                if (currentObj?.id?.length) {
                  if (currentObj?.parentId !== null)
                    dispatch(updatePlaceOfMessage(currentObj?.parentId));
                  else dispatch(updatePlaceOfMessage(currentObj?.id));
                }
              }
              if (messageData.id && messageType !== MessageType.Regenerate) {
                dispatch(
                  updateModelReplyIndex({
                    messageId: messageData.id,
                    replyIndex: 0,
                  })
                );
                if (ParentMessageId)
                  dispatch(
                    updateModelReplyIndex({
                      messageId: ParentMessageId,
                      replyIndex: 0,
                    })
                  );
              }
              setIsFinished(true);
            } else {
              if (message != null) {
                setResponse((prev) => (prev ? prev + message : message));
              }
              // setIsFinished(false);
            }
          }
        }
      }
    },
    [versionName, currentObj, activeConversationId]
  );

  const setupSignalRConnection = useCallback(() => {
    const connection = SignalRService.getInstance().connection;
    connection.on("SendModelMessage", handleModelMessage);
    return () => {
      connection.off("SendModelMessage", handleModelMessage);
    };
  }, [handleModelMessage]);

  useEffect(() => {
    const cleanup = setupSignalRConnection();
    return () => {
      cleanup();
    };
  }, [setupSignalRConnection]);

  // get selected version from dropdown version component
  const setVersionModel = (version: string) => {
    setSelectedModelVersion(version);
  };

  // handle go to last message request
  const runAutoScroll = () => {
    if (chatContainerRef.current) {
      if (
        chatContainerRef.current.scrollTop !=
        chatContainerRef.current.scrollHeight
      ) {
        chatContainerRef.current.scrollTop =
          chatContainerRef.current.scrollHeight;
      }
      dispatch(setUpLoadedFile(false));
    }
  };

  useEffect(() => {
    runAutoScroll();
  }, [
    response,
    modelState,
    disabledVersions,
    defaultVersion,
    prompt,
    isFullWidth,
    id,
  ]);

  const stopAutoScroll = (chatIndex: number) => {
    dispatch(setUserInteracted({ index: chatIndex, value: true }));
  };

  // handel select company name
  const handleOptionClick = (value: number, company: string) => {
    let modelName = modelNames[value];
    if (isFullWidth.length > 0) {
      maxChat(`${modelName}${index}`);
    }
    setCurrentModelName(modelName);
    setCompany(company);

    dispatch(conversationsActions.setIsConHistoryClicked(false));
    const newSelectedOptions = [...selectedOptionsStore];
    newSelectedOptions[index] = modelName;
    const updatedOptions = newSelectedOptions.slice(0, selectedColumns);
    dispatch(
      getChatsOpen({
        chatsNum: selectedColumns,
        selectedOptions: updatedOptions,
      })
    );
    setDropdownOpen(false);
    onChatNumChanged();
  };

  const areAllVersionsDisabled = (modelName: string) => {
    const modelVersions = conversationVersionModels?.filter((version) =>
      page === "Landing-page"
        ? version.name.toLowerCase() === modelName.toLowerCase() &&
          version.allowAnnonymous
        : isSubscribed
        ? version.name.toLowerCase() === modelName.toLowerCase()
        : version.name.toLowerCase() === modelName.toLowerCase() &&
          !version.isPro
    );
    // Return true if the model has no versions or all versions are disabled
    return (
      !modelVersions?.length ||
      modelVersions.every((version) =>
        disabledVersions.includes(version.version)
      )
    );
  };

  const HandleSetNewPrompt = (prompt: string) => {
    setPrompt(prompt);
  };

  const filteredMessages = modelState.filter((e: ChatModelState) => {
    return e.version === selectedModelVersion && e.parentId === null;
  });

  let numOfChatsOpen = ChatsState.chatsNum;
  let fullWidth =
    isFullWidth === `${currentModelName}${index}`
      ? "full-width"
      : isFullWidth !== "" && isFullWidth !== `${currentModelName}${index}`
      ? "d-none"
      : "";

  return (
    <>
      <div
        className={`modelContainer ${currentModelName?.toLowerCase()} ${fullWidth} ${
          numOfChatsOpen === 2
            ? "col-12"
            : numOfChatsOpen === 4
            ? "col-6"
            : "col-4"
        }`}
      >
        <div className="model">
          <div className="modelHeader d-flex align-items-center justify-content-between ">
            <div className="d-flex gap-3">
              <div className="customDropdown h-100">
                <div
                  className={`dropdownHeader ${dropdownOpen ? "open" : ""}`}
                  onClick={toggleDropdown}
                >
                  {company}
                  <img className={`company-arrow ${dropdownOpen && "open"}`} />
                </div>
                {dropdownOpen && (
                  <div className="dropdownList" ref={dropdownRef}>
                    <div className="dropContainer">
                      {ModelsCompany.map((c, index) => {
                        const isCompanyDisabled = areAllVersionsDisabled(
                          modelNames[index]
                        );
                        // Skip rendering the item if the company is disabled
                        if (isCompanyDisabled) {
                          return null;
                        }
                        return (
                          <div
                            key={index}
                            className={`dropdownItem  ${
                              isCompanyDisabled ? "d-none" : ""
                            }  ${
                              company === ModelsCompany[index] ? "selected" : ""
                            }`}
                            onClick={() => handleOptionClick(index, c)}
                          >
                            {c}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
              <ReturnVersionModel
                setActiveModels = {onChatNumChanged}
                page={page}
                index={index}
                modelName={currentModelName?.toLowerCase()}
                companyName={company}
                defaultNameVersion={
                  defaultVersion || (id ? "" : firstObjInChatModel?.title)
                }
                setVersionModel={setVersionModel}
                lastActiveVersion={lastActiveVersion}
                annonyVersion={
                  defaultAnnonyVersion || firstObjInChatModelInAnnon?.title
                }
              />
            </div>
            <div>
              {isFullWidth === `${currentModelName}${index}` ? (
                <img className="minimiz-icon" onClick={() => miniChat()} />
              ) : (
                <img className="expand-icon" onClick={() => maxChat()} />
              )}
            </div>
          </div>
          <div
            className={`modelChat columns-${selectedColumns}`}
            ref={chatContainerRef}
            onWheel={() => stopAutoScroll(index)}
          >
            {(newConversation && !id ) || (filteredMessages.length === 0 && prompt.length === 0) ? (
              <div className="new-conversation-message d-flex align-items-center gap-2">
                <img
                  className={`${currentModelName?.toLowerCase()}-styles-newCon-img`}
                />
                <p className="new-conversation-text mb-0">Ask me anything</p>
                <div className="loading d-flex gap-1 mt-2">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            ) : null}

            {modelState &&
              modelState
                .filter((e: ChatModelState) => {
                  return (
                    e.version === selectedModelVersion && e.parentId === null
                  );
                })
                .map((e: ChatModelState, index: number) => {
                  return (
                    <div key={index}>
                      <UserQuestion
                        messageId={e.id}
                        editMessageId={messageData.id}
                        attachmentFile={e.attachmentFile}
                        setQuery={HandleSetNewPrompt}
                        modelName={currentModelName?.toLowerCase()}
                        response={response}
                        isFinished={isFinished}
                      />
                    </div>
                  );
                })}

            {prompt && (
              <StreamingUserQuestion
                prompt={prompt}
                attachmentFile={attachmentFileData}
              />
            )}

            {prompt && !response && !isEditingStatus ? (
              <div>
                <ResponseLoading modelName={currentModelName?.toLowerCase()} />
              </div>
            ) : (
              response && (
                <div className="chat-component">
                  <StreamingChatResponse
                    response={response}
                    modelName={currentModelName?.toLowerCase()}
                  />
                </div>
              )
            )}
          </div>
        </div>
        {isFullWidth && <ToggleTheme />}
      </div>
    </>
  );
});

export default ChatModel;
