import axios from "axios";
import { dispatchErrorMessage } from "./EventEmitter";
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: false,
  maxRedirects: 5, // Set to 0 to prevent automatic redirects
});
let errorMessage:string
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if(error.response.data)
        errorMessage=error.response.data;
      switch (error.response.status) {
        case 301:
        case 302:
          const redirectUrl = error.response.headers.location;
          return axiosInstance.get(redirectUrl);
        case 401:
          dispatchErrorMessage("Unauthorized access - Redirecting to login", 401,error.response.data);
          break;
        case 404:
          dispatchErrorMessage("Resource not found", 404,error.response.data);
          break;
        case 500:
          dispatchErrorMessage("Internal server error", 500,error.response.data);
          break;
        default:
          dispatchErrorMessage(error.response.data.title || "An error occurred", error.response.status,error.response.data);
          break;
      }
    } else {
      // Handle network or other errors that don't have a response
      dispatchErrorMessage("Network error or server not reachable", 0,error.response?.data);
    }
    return Promise.reject(error);  // Ensure the promise is rejected to handle it appropriately
  }
);

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (!localStorage.user) return config;
  config.headers["Authorization"] = JSON.parse(localStorage.user).token;
  return config;
});
export default axiosInstance;
