import { useMutation, useQuery } from "@tanstack/react-query";
import Api from "../../features/one-query/services/ApiServices";
import { useDispatch } from "react-redux";
import { getChatModelEditsAndResponsesMessages, getModelChatMessage } from "../one-query/ChatModelSlice";

// function to get messages from GPT
const getChatModelMessages = async (conversationId: string | undefined) => {
  try {
    const response = await Api.get(
      `Conversation/${conversationId}/GetAllMessages`
    );
    return response.data;
  } catch (e) {}
};

//custom hook to get messages from Chat model using useQuery
const useGetChatModelMessages = () => {
  return useMutation({
    mutationKey: ["ChatModelMessages"],
    mutationFn: getChatModelMessages,
  });
};

// Hook to get messages from Chat model History by return fun make this query and dispatch data
export const useGetChatModelMessagesHistory = () => {
  const getChatModelMessagesMutation = useGetChatModelMessages();
  const dispatch = useDispatch();

  const GetChatModelMessagesHistory = (conversationId: string | undefined) => {
    getChatModelMessagesMutation.mutate(conversationId, {
      onSuccess: (data) => {
        dispatch(getModelChatMessage(data));
        data.map((item: any) =>
          dispatch(
            getChatModelEditsAndResponsesMessages({
              MessageId: item.id,
              EditMessageIndex: 0,
              ResponseIndex: 0,
            })
          )
        );
      },
      onError: (error) => {},
    });
  };

  return GetChatModelMessagesHistory;
};

/////////////////////////////////
// Regenerate Message Endpoint
const regenerateMessage = async ({
  messageId,
  connectionId,
}: {
  messageId: string;
  connectionId: string;
}) => {
  try {
    const response = await Api.get(
      `/Chat/RegenerateMessage?messageId=${messageId}&connectionId=${connectionId}`
    );
    return response.data;
  } catch (e) {
    console.error(e);
    throw e; // Re-throw the error so that onError in useMutation can handle it
  }
};

// Custom hook to get new response
const useRegenerateMessages = () => {
  return useMutation({
    mutationKey: ["RegenerateMessage"],
    mutationFn: regenerateMessage,
  });
};

export const useRegenerateMessagesAsync = () => {
  const getRegenerateMessagesMutation = useRegenerateMessages();

  const getRegenerateMessage = (messageId: string, connectionId: string) => {
    getRegenerateMessagesMutation.mutate(
      { messageId, connectionId }, // Passing both parameters as an object
      {
        onSuccess: (data: any) => {},
        onError: (error: any) => {
          console.error(error);
        },
      }
    );
  };

  return getRegenerateMessage; // Return the function, not the hook itself
};

// Help & Support model endpoint
export const handleSendHelpRequest = (
  query: string,
  getConnectionId: string | null
) => {
  Api.post(`/HelpAndSupport?prompt=${query}&connectionId=${getConnectionId}`);
};

export default useGetChatModelMessages;
