import React, { useState, useEffect } from 'react';
import { useErrorListener } from './EventEmitter';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setMessageErrorLogin } from '../../../store/one-query/chatsSlice';
import AlertMessage from '../../main-page/components/get-conversation/AlertMessage';

const Alert: React.FC = () => {
  const [messageErrorState, setMessageErrorState] = useState<string | null>(null);
  const [statusCode,setStatusCode]=useState<number>()
  const [show, setShow] = useState(true)
  const navigate=useNavigate();
  const dispatch = useDispatch();

  useErrorListener((message: string, status: number, errorMessage: string) => {
    if (errorMessage) {
      dispatch(setMessageErrorLogin(errorMessage));
    }
    setMessageErrorState(message);
    setStatusCode(status);
    setShow(true); // Ensure this triggers the alert to display
  });

  return (
    <>
      {/* {show && messageErrorState && (
        <div className='d-flex justify-content-center'>
          <AlertMessage showAlert={true} message={messageErrorState} />
        </div>
      )} */}
    </>
  );
};

export default Alert;
