import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Subscription.css";
import { useAppSelector } from "../../../store/hooks";
import { useDispatch } from "react-redux";
import { changeSubscriptionStatus } from "../../../store/account/userSlice";
const Subscription = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleNavigate = () => {
    navigate("/main-page");
  };
  useEffect(() => {
    dispatch(changeSubscriptionStatus(true));
  }, []);

  return (
    <div className="main-subscription">
      <div className="logo-container">
        <img className="expertex-logo" />
      </div>
      <div className="subscription-content">
        <img className="fire-work" />
        <h1 className="subscription-title">Thank you for upgrading!</h1>
        <p className="subscription-subtitle">Your adventure starts now </p>
        <button className="subscription-button" onClick={handleNavigate}>
          Ask expertex
        </button>
      </div>
    </div>
  );
};

export default Subscription;
