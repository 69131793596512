import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "../../features/one-query/services/ApiServices";
import { setNewGeminiVersion, setNewGPTVersion, setNewLlamaVersion } from "./chatsSlice";

interface Version {
  id: string;
  name: string;
  createdAt: string;
  updatedById: string;
  updatedAt: string;
  createdById: string;
  isDeleted: boolean;
  deletedById: string;
  deletedAt: string;
  title: string;
  version: string;
  allowAnnonymous: boolean;
  isDefault: boolean;
  isPro: boolean;
}
export interface ConversationVersionData {
  data: Version[];
}
const initialState: ConversationVersionData = {
   data:[],
};
const conversationVersionSlice = createSlice({
  name: "conversationVersion",
  initialState: {} as ConversationVersionData,
  reducers: {
    setConversationVersionData(state, action: PayloadAction<any>) {
      let data = action.payload;
      state.data = [];
      for (let i = 0; i < data?.length; i++) {
        state.data.push({
          id: data[i].id,
          name: data[i].name,
          createdAt: data[i].createdAt,
          updatedById: data[i].updatedById,
          updatedAt: data[i].updatedAt,
          createdById: data[i].createdById,
          isDeleted: data[i].isDeleted,
          deletedById: data[i].deletedById,
          deletedAt: data[i].deletedAt,
          title: data[i].title,
          version: data[i].version,
          allowAnnonymous: data[i].allowAnnonymous,
          isDefault: data[i].isDefault,
          isPro: data[i].isPro,
        });
      }
    },
  },
});

export const { setConversationVersionData } = conversationVersionSlice.actions;

export default conversationVersionSlice.reducer;


