import { useMutation } from "@tanstack/react-query";
import Api from "../../features/one-query/services/ApiServices";
import { useDispatch } from "react-redux";
import {
  UserState,
  forgetPasswordFailed,
  setIsUpdateProfilePicture,
  setProfilePictureURL,
  signinUserFailed,
  signinUserSuccess,
  signoutUserFailed,
  signoutUserSuccess,
} from "../account/userSlice";
import { conversationTypesActions } from "../one-query/conversationType";
import { useAppDispatch } from "../hooks";
import { useNavigate } from "react-router-dom";
import {
  setFileError,
  setMessageError,
  setMessageErrorLogin,
} from "../one-query/chatsSlice";
import { useState } from "react";

const api = process.env.REACT_APP_API;

interface UserRegistration {
  firstName: string;
  lastName: string;
  userName: string;
  password: string;
}

interface UserLogin {
  userName: string;
  password: string;
}
interface ProfilePicture {
  userId: string;
  file: any;
}
interface changePassword {
  userId: string | null;
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}
//Register User fun
const registerUser = async (user: UserRegistration) => {
  try {
    const response = await Api.post("/account/register", user, {
      withCredentials: true, // Include cookies in the request
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {}
};

const useUserRegistration = () => {
  return useMutation({
    mutationKey: ["registerUser"],
    mutationFn: registerUser,
  });
};

// hook to return the fun to register user
const useRegisterUser = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const registerUserMutation = useUserRegistration();
  const registerUserMutate = (user: UserRegistration) => {
    setLoading(true);
    registerUserMutation.mutate(user, {
      onSuccess: (data) => {
        setLoading(false);
        if (data?.status !== 200) {
          if (data?.data?.message?.includes("email already exists")) {
            dispatch(setMessageErrorLogin("The email already exists"));
          } else {
            dispatch(
              setMessageErrorLogin(
                data?.data?.message || "An unknown error occurred."
              )
            );
          }
        } else {
          // Handle the successful registration case
          const email = user?.userName;
          navigate("/resend-email");
          localStorage.setItem("prevPath", "/account/register");
        }
      },
      onError: (error) => {
        setLoading(false);
        dispatch(setMessageErrorLogin("An unexpected error occurred."));
      },
    });
  };

  return { registerUserMutate, loading };
};

// ======================================================================================

// SignIn user fun

const signinUser = async (user: UserLogin) => {
  try {
    const response = await Api.post("/account/login", user, {
      withCredentials: true, // Include cookies in the request
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response;
  } catch (error) {
    throw error;
  }
};

// hook of signin user mutation
const useUserLogin = () => {
  return useMutation({
    mutationKey: ["signinUser"],
    mutationFn: signinUser,
  });
};

// hook to return the fun to signin user
const useSigninUser = () => {
  const signinUserMutation = useUserLogin();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const signinUserMutate = (user: UserLogin) => {
    setLoading(true);
    signinUserMutation.mutate(user, {
      onSuccess: (data) => {
        setLoading(false);
        dispatch(
          signinUserSuccess({
            IsAuthenticated: true,
            token: data?.data?.token,
            userData: data?.data?.user,
          })
        );
      },
      onError: (error) => {
        setLoading(false);
        dispatch(
          signinUserFailed({
            IsAuthenticated: false,
            errorMessage: error.message,
          })
        );
      },
    });
  };
  return { signinUserMutate, loading };
};
// ======================================================================================

// signOut user fun
const signOutUser = async (payload: UserState) => {
  try {
    const response = await Api.post("/account/signout", null, {
      withCredentials: true, // Include cookies in the request
      validateStatus: function (status) {
        return status >= 200 && status < 400; // Validate successful status codes
      },
    });
    return response;
  } catch (error) {}
};

// hook to qeuery the signout user

const useUserSignOut = () => {
  return useMutation({
    mutationKey: ["signoutUser"],
    mutationFn: signOutUser,
  });
};

// hook to return the fun to signout user
const useSignOutUser = () => {
  const signOutUserMutation = useUserSignOut();
  const dispatch = useDispatch();
  const signOutUserMutate = (user: UserState) => {
    signOutUserMutation.mutate(user, {
      onSuccess: (data) => {
        dispatch(conversationTypesActions.getConversationTypes([]));
        dispatch(signoutUserSuccess({ IsAuthenticated: false }));
      },
      onError: (error) => {
        dispatch(signoutUserFailed({ IsAuthenticated: false }));
      },
    });
  };
  return signOutUserMutate;
};
/////////////////////////////////////////////////////
// set Profile Picture

const setProfilePicture = async (profilePicture: ProfilePicture) => {
  try {
    const response = await Api.post(
      `/Account/UpdateUserPicture?userId=${profilePicture.userId}`,
      profilePicture,
      {
        withCredentials: true, // Include cookies in the request
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

// hook of post profile picture user mutation
const usePostProfilePicture = () => {
  return useMutation({
    mutationKey: ["profilePicturePost"],
    mutationFn: setProfilePicture,
  });
};

// hook to return the fun to profile picture
const useprofilePicture = () => {
  const profilePictureUserMutation = usePostProfilePicture();
  const dispatch = useDispatch();
  const profilePictureMutation = (user: ProfilePicture) => {
    profilePictureUserMutation.mutate(user, {
      onSuccess: (data) => {
        dispatch(setIsUpdateProfilePicture(true));
      },
      onError: (error) => {},
    });
  };
  return profilePictureMutation;
};
/////////////////////////////////////////////////////
// get Profile Picture

const getProfilePicture = async (id: string) => {
  try {
    const response = await Api.get(`/Account/GetUserPicture?userId=${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

// hook of get profile picture user mutation
const usegetProfilePicture = () => {
  return useMutation({
    mutationKey: ["profilePictureGet"],
    mutationFn: getProfilePicture,
  });
};

// Hook to use the getProfilePicture function
const useProfilePictureGet = () => {
  const profilePictureUserMutation = usegetProfilePicture();
  const dispatch = useDispatch();

  const profilePictureMutation = (profilePicture: any) => {
    profilePictureUserMutation.mutate(profilePicture, {
      onSuccess: (data) => {
        dispatch(setProfilePictureURL(data?.data));
        // dispatch(setIsUpdateProfilePicture(false))
      },
    });
  };

  return profilePictureMutation;
};
// Reset email password
const forgetPassword = async (userEmail: string) => {
  try {
    const response = await Api.post(`/account/ForgotPassword`, {
      email: userEmail,
    });
    return response.data; // Return the response data
  } catch (error) {
    throw error; // Re-throw the error if needed
  }
};

const useForgetPassword = () => {
  return useMutation({
    mutationKey: ["forgetPassword"],
    mutationFn: forgetPassword,
  });
};

const useforgetPassword = () => {
  const forgetPasswordMutation = useForgetPassword();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const forgetPasswordMutate = (userEmail: string) => {
    setLoading(true);
    forgetPasswordMutation.mutate(userEmail, {
      onSuccess: (data) => {
        navigate("/resend-email");
        setLoading(false);
        dispatch(setMessageError(""));
        localStorage.setItem("prevPath", "/forget-password");

      },
      onError: (error) => {
        setLoading(false);
        dispatch(forgetPasswordFailed(error.message));
      },
    });
  };
  return { forgetPasswordMutate, loading };
};

// ====================================================================
// Set new password
export const setNewPassword =
  (userEmail: string | null, newPassword: string, confirmPassword: string) =>
  (dispatch: any) => {
    Api.post(`/account/ResetPassword`, {
      userEmail: userEmail,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    }).then((e) => {});
  };

// change password
const changePassword = async (
  userId: string | null,
  oldPassword: string,
  newPassword: string,
  confirmNewPassword: string
) => {
  try {
    const response = await Api.post(`/account/changePassword`, {
      userId,
      oldPassword,
      newPassword,
      confirmNewPassword,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const useChangePassword = () => {
  return useMutation({
    mutationKey: ["changePassword"],
    mutationFn: ({
      userId,
      oldPassword,
      newPassword,
      confirmNewPassword,
    }: {
      userId: string | null;
      oldPassword: string;
      newPassword: string;
      confirmNewPassword: string;
    }) => changePassword(userId, oldPassword, newPassword, confirmNewPassword),
  });
};

const useChangePasswordMutation = () => {
  const changePasswordMutation = useChangePassword();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const changePasswordMutate = (
    id: string,
    oldPassword: string,
    newPassword: string,
    confirmPassword: string
  ) => {
    setLoading(true);
    changePasswordMutation.mutate(
      {
        userId: id,
        oldPassword,
        newPassword,
        confirmNewPassword: confirmPassword,
      },
      {
        onSuccess: (data: any) => {
          setLoading(false);
          navigate("/settings");
          dispatch(
            setFileError({
              isError: true,
              errorMsg: "Password successfully updated",
              reason: "Password successfully updated",
            })
          );
          dispatch(setMessageError(""));
        },
        onError: (error: any) => {
          setLoading(false);
          dispatch(setMessageError(error.response.data));
        },
      }
    );
  };

  return { changePasswordMutate, loading };
};

//Edit user name
// Edit user name function
const editUserName = async (
  userId: string,
  firstName: string,
  lastName: string
) => {
  try {
    const response = await Api.patch(
      `/account/UpdateUserName?userId=${userId}`,
      {
        firstName: firstName,
        lastName: lastName,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error during user name update:", error);
    throw error;
  }
};

// Custom hook for editing user name
const useEditUserName = () => {
  return useMutation({
    mutationKey: ["editUserName"],
    mutationFn: ({
      userId,
      firstName,
      lastName,
    }: {
      userId: string;
      firstName: string;
      lastName: string;
    }) => editUserName(userId, firstName, lastName),
  });
};

// Custom hook to handle the mutation and side effects like navigation and error handling
const useEditUserNameMutation = () => {
  const editUserNameMutation = useEditUserName();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const editUserNameMutate = (
    userId: string,
    firstName: string,
    lastName: string
  ) => {
    editUserNameMutation.mutate(
      { userId, firstName, lastName },
      {
        onSuccess: (data: any) => {
          dispatch(setMessageError(""));
        },
        onError: (error: any) => {
          dispatch(setMessageError(error.response.data));
        },
      }
    );
  };

  return editUserNameMutate;
};

// ======================================================================================

// Edit user name function
const resendConfirmEmail = async (userEmail: string | null) => {
  try {
    const response = await Api.post(`/account/ResendConfirmationEmail`, {
      email: userEmail,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Custom hook for editing user name
const useResendConfirmEmail = () => {
  return useMutation({
    mutationKey: ["resendConfirmEmail"],
    mutationFn: ({ userEmail }: { userEmail: string | null }) =>
      resendConfirmEmail(userEmail),
  });
};

// Custom hook to handle the mutation and side effects like navigation and error handling
const useResendConfirmEmailToUser = () => {
  const confirmEmail = useResendConfirmEmail();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const confirmEmailMutate = (userEmail: string | null) => {
    confirmEmail.mutate(
      { userEmail },
      {
        onSuccess: (data: any) => {
          dispatch(setMessageError(""));
        },
        onError: (error: any) => {
          dispatch(setMessageError(error.response.data));
        },
      }
    );
  };

  return confirmEmailMutate;
};
// ======================================================================================

// Contact us function
const contactUsEmail = async (
  firstName: string,
  lastName: string,
  userEmail: string,
  message: string
) => {
  try {
    const response = await Api.post(`/HelpAndSupport/contact-us`, {
      firstName: firstName,
      lastName: lastName,
      email: userEmail,
      message: message,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Custom hook for contact us
const useContactUsEmail = () => {
  return useMutation({
    mutationKey: ["contactUsEmail"],
    mutationFn: ({
      firstName,
      lastName,
      userEmail,
      message,
    }: {
      firstName: string;
      lastName: string;
      userEmail: string;
      message: string;
    }) => contactUsEmail(firstName, lastName, userEmail, message),
  });
};

// Custom hook to handle the mutation and side effects like navigation and error handling
const useHelpContactUsEmail = () => {
  const contactUsFn = useContactUsEmail();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const contactUsMutate = (
    firstName: string,
    lastName: string,
    userEmail: string,
    message: string
  ) => {
    setLoading(true);
    contactUsFn.mutate(
      { firstName, lastName, userEmail, message },
      {
        onSuccess: (data: any) => {
          setLoading(false);
          dispatch(setMessageError(""));
        },
        onError: (error: any) => {
          setLoading(false);
          dispatch(setMessageError(error.response.data));
        },
      }
    );
  };

  return { contactUsMutate, loading };
};

export default useProfilePictureGet;

// User login options
const userLoginWithGoogle = (mode: string) => (dispatch: any) => {
  window.location.href = `${api}/Account/signin-google?mode=${mode}`;
};

const userLoginWithFacebook = (mode: string) => (dispatch: any) => {
  window.location.href = `${api}/Account/signin-facebook?mode=${mode}`;
};

const userLoginWithTwitter = (mode: string) => (dispatch: any) => {
  window.location.href = `${api}/Account/signin-twitter?mode=${mode}`;
};

export {
  useProfilePictureGet,
  useRegisterUser,
  useSigninUser,
  useforgetPassword,
  useChangePasswordMutation,
  useprofilePicture,
  useSignOutUser,
  userLoginWithGoogle,
  userLoginWithFacebook,
  userLoginWithTwitter,
  useEditUserNameMutation,
  useResendConfirmEmailToUser,
  useHelpContactUsEmail,
};
