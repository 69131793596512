import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { Dropdown, Form, Modal } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import HistoryItem from "./HistoryItem";
import Filter from "./Filter";
import "./getConversation.css";
import ToggleTheme from "./ToggleTheme";
import {
  handleNewConversationRequest,
  regenerateResponse,
  setChat1Data,
  setChat2Data,
  setChat3Data,
  setChat4Data,
  setChat5Data,
  setChat6Data,
  setDisabledVersions,
  setNewConversation,
  setUpLoadedFile,
} from "../../../../store/one-query/chatsSlice";
import DeleteModal from "./DeletePopup";
import { useGetConversationHistory } from "../../../../store/Apis/ConversationApi";
import useProfilePictureGet, {
  useSignOutUser,
} from "../../../../store/Apis/UserApi";
import UpdateProfile from "./UpdateProfile";
import UpgradeToProPopup from "./UpgradeToProPopup";
import { setUserAvatar } from "../../../../store/account/userSlice";
import CancelSubscriptionModal from "./CancelSubscriptionModal";

const SidebarApp = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const newConversation = useAppSelector(
    (state) => state.ChatsState.newConversation
  );
  const isFullWidth = useAppSelector((state) => state.ChatsState.fullWidthMode);
  const [showDeleteConversations, setShowDeleteConversations] = useState(false);
  const [showCancelSubModal, setShowCancelSubModal] = useState(false);
  const conversationsNames = useAppSelector((state) => state.Conversations);
  const darkMode = useAppSelector((state) => state.User.darkMode);
  const [search, setSearch] = useState("");
  const dispatch = useAppDispatch();
  const signOutUser = useSignOutUser();
  const getConversationHistory = useGetConversationHistory();
  const getProfilePicture = useProfilePictureGet();
  const userDataProfile = useAppSelector((state) => state.User.userData);
  const navigate = useNavigate();
  const isSubscribed = useAppSelector((s) => s.User.isSubscribed);
  const currentSelectedPersonaFilter = useAppSelector(
    (s) => s.Conversations.currentSelectedPersonaFilter
  );
  const isUserUpdatePicture = useAppSelector(
    (s) => s.User?.isUserUpdatePicture
  );
  const [showUpdateProfileModal, setShowUpdateProfileModal] = useState(false);
  const [showUpgradeToProModal, setShowUpgradeToProModal] = useState(false);
  const userId = useAppSelector((s) => s.User.userData?.id);
  const userData = useAppSelector((s) => s.User);
  const pictureUrl = useAppSelector((s) => s.User?.profilePictureURL);
  const [profilePictureUrl, setProfilePictureUrl] = useState<string>();
  const [colorAvatar, setColorAvatar] = useState<string>();
  const [showCancelSub, setShowCancelSub] = useState(false);
  useEffect(() => {
    if (userData.IsAuthenticated) {
      getConversationHistory();
    }
  }, []);

  useEffect(() => {
    if (pictureUrl) setProfilePictureUrl(pictureUrl);
  }, [pictureUrl]);

  useEffect(() => {
    if (userData.userData?.id || isUserUpdatePicture) {
      getProfilePicture(userData.userData?.id);
    }
  }, [isUserUpdatePicture]);

  useEffect(() => {
    if (!id && window.location.pathname === "/main-page") {
      handleNewConversation();
    } else dispatch(setNewConversation(false));
  }, [window.location.pathname]);

  const switchToSettingsSide = () => {
    navigate("/settings");
  };

  // Function to calculate HSL color from a string
  const stringToHslColor = (str: string, s: number, l: number) => {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var h = hash % 360;
    return "hsl(" + h + ", " + s + "%, " + l + "%)";
  };

  useEffect(() => {
    if (
      userDataProfile?.firstName?.length &&
      userDataProfile?.lastName?.length
    ) {
      // Calculate and set the colorAvatar state
      const color = stringToHslColor(
        userDataProfile.firstName + userDataProfile.lastName,
        30,
        50
      );
      setColorAvatar(color);
    }
  }, [userDataProfile]);

  const handleShowUpdateProfileModal = () => {
    setShowUpdateProfileModal(true);
  };
  const handleCloseUpdateProfileModal = () => {
    setShowUpdateProfileModal(false);
  };
  const handleShowUpgradeToProModal = () => {
    setShowUpgradeToProModal(true);
  };
  const handleCloseUpgradeToProModal = () => {
    setShowUpgradeToProModal(false);
  };

  const handleNewConversation = () => {
    dispatch(setChat1Data({ id: "", version: "" , name:"" }));
    dispatch(setChat2Data({ id: "", version: "" , name:""}));
    dispatch(setChat3Data({ id: "", version: "" , name:""}));
    dispatch(setChat4Data({ id: "", version: "" , name:""}));
    dispatch(setChat5Data({ id: "", version: "" , name:""}));
    dispatch(setChat6Data({ id: "", version: "" , name:""}));
    dispatch(setUpLoadedFile(false));
    dispatch(regenerateResponse(false));
    dispatch(handleNewConversationRequest());
    navigate("/main-page");
  };

  const Logout = () => {
    localStorage.removeItem("user");
    signOutUser({ IsAuthenticated: false });
  };

  const filteredConversations = conversationsNames.conversations.filter(
    (con) => {
      const matchesSearch = search
        ? con.name?.trim().toLowerCase().includes(search.trim().toLowerCase())
        : true;
      const matchesPersonaFilter = currentSelectedPersonaFilter
        ? currentSelectedPersonaFilter === con.persona?.id
        : true;
      return matchesSearch && matchesPersonaFilter;
    }
  );

  //Handel user profile image
  function renderUserProfile(userData: any, colorAvatar: string | undefined) {
    if (!userDataProfile) {
      return null;
    }

    const { avatarUrl, firstName, lastName } = userData;
    const initials = `${firstName?.charAt(0)}${lastName?.charAt(0)}`;
    const fullName = `${firstName} ${lastName}`;
    dispatch(setUserAvatar(initials));
    return (
      <>
        {profilePictureUrl ? (
          <img
            className="profileImage"
            src={profilePictureUrl}
            alt="User Avatar"
          />
        ) : (
          <span
            className="profileImage"
            style={{ backgroundColor: colorAvatar }}
          >
            {initials}
          </span>
        )}
        <span className="user-name">{fullName}</span>
      </>
    );
  }

  const handleMouseEnter = () => {
    setShowCancelSub(true);
  };

  const handleMouseLeave = () => {
    setShowCancelSub(false);
  };

  return (
    <div
      className={`getConversations ${
        isFullWidth !== "" && isFullWidth !== "ExpertHome" ? "d-none" : ""
      }`}
    >
      <div className="logo" onClick={() => navigate("/main-page")}>
        <img className="expertex-logo" />
      </div>
      <div
        className={`newConversation  ${newConversation && "new"}`}
        onClick={handleNewConversation}
      >
        <div className="text d-flex align-items-center gap-3 c-pointer">
          <img
            className="new-conversation-icon"
            src="/images/New Chat Icon.svg"
          />
          <p className="titleFont mb-0">New conversation</p>
        </div>
      </div>
      <div className="new_clear_Conversation">
        <div
          className="clearConversation d-flex align-items-center gap-3 mb-3 c-pointer"
          onClick={() => setShowDeleteConversations(true)}
        >
          <img
            className="clear-conversations-icon"
            src={
              darkMode
                ? "/images/GetConversations/icn-trush-square-dark.svg"
                : "/images/GetConversations/trush-square.svg"
            }
          />
          <p className="titleFont">Clear all conversations</p>
        </div>
        <DeleteModal
          show={showDeleteConversations}
          handleClose={() => setShowDeleteConversations(false)}
          name="Clear all conversations"
          message="By confirming all your conversation data will be lost. This action can't be undone."
        />
        {showCancelSubModal && <CancelSubscriptionModal
          show={showCancelSubModal}
          handleClose={() => setShowCancelSubModal(false)}
          name="Cancel Subscription"
          message="Canceling your subscription removes premium access Are you sure?"
        />}
      </div>
      {/* History */}
      <div className="getConversationsContainer">
        <div className="history">
          <div className="d-flex flex-column align-items-start">
            <div className="d-flex align-items-center pb-2">
              <img
                className="history-icon"
                src={
                  darkMode
                    ? "/images/GetConversations/clock-dark.svg"
                    : "/images/GetConversations/clock.svg"
                }
              />
              <span className="historyTitle">History</span>
            </div>
            <div className="d-flex align-items-center justify-content-center mb-1 py-1 w-100">
              <div className="searchBox">
                <Form.Label className="mb-0" htmlFor="historySearch">
                  <img
                    className="search-icon"
                    src={"/images/GetConversations/search-normal.svg"}
                  />
                </Form.Label>
                <Form.Control
                  id="historySearch"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <Filter currentPlace="Sidebar" />
            </div>
          </div>
          <div className="history-container overflow-x-hidden overflow-y-auto">
            {filteredConversations
              ?.filter((item) => {
                return search !== ""
                  ? item.title
                      ?.trim()
                      .toLowerCase()
                      .includes(search.trim().toLowerCase())
                  : item;
              })
              .sort((a, b) => {
                const dateA = a.createdAt ? new Date(a.createdAt).getTime() : 0;
                const dateB = b.createdAt ? new Date(b.createdAt).getTime() : 0;
                return dateB - dateA;
              })
              .map(({ name, createdAt, id, persona }) => (
                <HistoryItem
                  key={id}
                  name={name || ""}
                  date={createdAt + ""}
                  id={id + ""}
                  category={persona?.title}
                />
              ))}
          </div>
        </div>
        <div className="profileContainer">
          <ToggleTheme />
          {/* Profile */}
          <Dropdown onMouseLeave={handleMouseLeave}>
            <div className="profile d-flex-column align-items-center w-100">
              <Dropdown.Toggle className="user_profile w-100 d-flex align-items-center justify-content-between border-0 c-pointer">
                <div className=" d-flex align-items-center flex-wrap justify-content-center">
                  {renderUserProfile(userDataProfile, colorAvatar)}
                </div>
                <div>
                  <img className="arrow-icn" />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="profileDropdown w-100">
                <Dropdown.Item onMouseOver={handleMouseLeave}>
                  <div className="userInfo">
                    <div>
                      <div
                        className="editProfileImage"
                        onClick={handleShowUpdateProfileModal}
                      >
                        <img
                          className="editProfileImageIcon"
                          src="/images/GetConversations/editProfileImage.svg"
                        />
                      </div>
                      <div className="profileUserImage">
                        {renderUserProfile(userDataProfile, colorAvatar)}
                      </div>
                    </div>
                    <p className="userEmail">{userDataProfile?.email}</p>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item>
                  <>
                    <div
                      className="settings"
                      onClick={() => switchToSettingsSide()}
                      onMouseOver={handleMouseLeave}
                    >
                      <img
                        className="settings-icon"
                        src={
                          darkMode
                            ? "/images/GetConversations/icn-setting-dark.svg"
                            : "/images/GetConversations/setting-2.svg"
                        }
                      />
                      <p className="titleFont">Settings</p>
                    </div>
                    {isSubscribed && (
                      <div
                        className="manageSubscriptions"
                        onMouseEnter={handleMouseEnter}
                      >
                        <img
                          className="settings-icon"
                          src={
                            darkMode
                              ? "/images/getConversations/manageSubDark.svg"
                              : "/images/getConversations/manageSubLight.svg"
                          }
                        />
                        <p className="titleFont manageTitle">
                          Manage subscription
                        </p>
                        <img
                          src={
                            darkMode
                              ? "/images/getConversations/arrowDark.svg"
                              : "/images/getConversations/arrowLight.svg"
                          }
                        />
                      </div>
                    )}
                  </>
                </Dropdown.Item>
                <div
                  className="cancelSubscriptions"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => setShowCancelSubModal(true)}
                  style={{
                    display: showCancelSub ? "flex" : "none",
                  }}
                >
                  <p className="cancelTitle">Cancel subscription</p>
                </div>
                <Dropdown.Item onClick={Logout} onMouseOver={handleMouseLeave}>
                  <div className="d-flex align-items-center gap-4">
                    <img src="/Images/getConversations/logout.svg" />
                    <p className="logOutTitle mb-0">Log out</p>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
              {isSubscribed ? (
                <div className="proPlanBtn">
                  <img src="images/Payment/proStar.svg" alt="" />
                  <span className="proPlanTitle">You're on the pro plan</span>
                </div>
              ) : (
                <div
                  className="upgradeBtn"
                  onClick={handleShowUpgradeToProModal}
                >
                  Upgrade to Pro
                </div>
              )}
            </div>
          </Dropdown>
        </div>
      </div>
      {showUpgradeToProModal && (
        <UpgradeToProPopup
          showModal={showUpgradeToProModal}
          closeModal={handleCloseUpgradeToProModal}
        />
      )}
      <UpdateProfile
        showModel={showUpdateProfileModal}
        closeModal={handleCloseUpdateProfileModal}
        userData={userDataProfile}
        avatarBackground={colorAvatar}
      />
    </div>
  );
};

export default SidebarApp;
