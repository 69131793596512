import React, { useEffect, useState } from "react";
import "../../main-page/components/SettingPages/ChangePassword.css";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import Alert from "../../one-query/services/Alert";
import "./RegisterComponent.css";
import { useforgetPassword } from "../../../store/Apis/UserApi";
import { emailRegex } from "../../../Helper";

const ForgetPassword = () => {
  const darkMode = useAppSelector((state) => state.User.darkMode);
  const errorMessage = useAppSelector((s) => s.ChatsState.messageError);
  const [errorMessageState, setErrorMessageState] = useState("");
  const [email, setEmailState] = useState<string>("");
  const [isWarningMessageEmail, setIsWarningMessageEmail] = useState(false);
  const [isCheckEmail, setIsCheckEmail] = useState(false);
  const [isEmailRequired, setIsEmailRequired] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { forgetPasswordMutate, loading } = useforgetPassword();
  useEffect(() => {
    if (errorMessage) setErrorMessageState(errorMessage);
  }, [errorMessage]);

  const handleChangeEmailReset = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailState(e.target.value);
    setIsWarningMessageEmail(false);
    setIsEmailRequired(false);
    setErrorMessageState("");
  };

  useEffect(() => {
    if (email.length <= 0) {
      setIsCheckEmail(false);
    } else if (emailRegex.test(email)) {
      setIsCheckEmail(true);
      setIsEmailRequired(false);
    } else {
      setIsCheckEmail(false);
      setIsEmailRequired(false);
    }
  }, [email]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (email.length > 0) {
      forgetPasswordMutate(email);
      localStorage.setItem("confirmUserEmail", email);
    }
  };

  return (
    <>
      <Alert />
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div className="col-md-6 divCahngePasswordForm col-sm-12 ">
            <form className=" formDivPassword" style={{ width: "25rem" }}>
              <img
                src={
                  darkMode
                    ? "/images/GetConversations/Expertex-logo-dark.svg"
                    : "/images/GetConversations/Expertex-logo-light.svg"
                }
                className="ExpertexLogoChangePassword c-pointer"
                alt="Expertex_logo"
                onClick={() => navigate("/")}
              />
              <div className="m-2 ms-3 w-100 d-flex align-start">
                <p className="headerChangePassword ms-3">
                  Forget your password?
                </p>
              </div>
              <div className="mb-1">
                <div className="containerShowPassword mb-2">
                  <input
                    type="email"
                    onChange={handleChangeEmailReset}
                    onFocus={() => setIsWarningMessageEmail(true)}
                    onBlur={() => setIsWarningMessageEmail(false)}
                    name="Email Reset"
                    value={email}
                    className={
                      !isCheckEmail
                        ? "inputEmailBorderRed"
                        : "inputEmailBorderBlue"
                    }
                    placeholder="Enter your email"
                  />
                  {errorMessageState === "User not found." ||
                  isEmailRequired ? (
                    <div className="ms-1 d-flex" style={{ width: "23rem" }}>
                      {darkMode ? (
                        <img src="/images/icn-error-circle-dark.svg" />
                      ) : (
                        <img src="/images/icn-error-circle.svg" />
                      )}
                      <div
                        className="warningMessageNewPassword ps-1 text-left"
                        style={{
                          color: `${!darkMode ? "#BA1A1A" : "#FFB4AB"}`,
                        }}
                      >
                        {isEmailRequired
                          ? "Email is required"
                          : errorMessageState}
                      </div>
                    </div>
                  ) : (
                    !isCheckEmail &&
                    !isEmailRequired &&
                    email.length > 0 && (
                      <div className="ms-1 d-flex" style={{ width: "23rem" }}>
                        {darkMode ? (
                          <img src="/images/icn-error-circle-dark.svg" />
                        ) : (
                          <img src="/images/icn-error-circle.svg" />
                        )}
                        <div
                          className="warningMessageNewPassword ps-1 text-left"
                          style={{
                            color: `${!darkMode ? "#BA1A1A" : "#FFB4AB"}`,
                          }}
                        >
                          Invalid email address
                        </div>
                      </div>
                    )
                  )}
                </div>
                <div>
                  <button
                    className={`mt-1 btnPassword ${loading && "opacity-50"}`}
                    disabled={loading}
                    onClick={handleClick}
                  >
                    Send recovery link
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="col-md-6 backgroundImage d-none d-md-block">
            <img
              src="/Images/change-password.png"
              alt="Change Password"
              className="imgChangePassword"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
