import { configureStore } from "@reduxjs/toolkit";
import userlice from "./account/userSlice";
import conversationSlice from "./one-query/conversationSlice";
import ConversationTypesSlice from "./one-query/conversationType";
import chatsSlice from "./one-query/chatsSlice";
import conversationVersionReducer from "./one-query/conversationVersions";
import ChatModelSlice from "./one-query/ChatModelSlice";
export const store = configureStore({
  reducer: {
    User: userlice,
    Conversations: conversationSlice,
    ConversationTypes: ConversationTypesSlice,
    ChatsState: chatsSlice,
    conversationVersion: conversationVersionReducer,
    chatModel: ChatModelSlice,
  },
});

export type appDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
