import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import { useGetPaymentLink } from "../../../store/Apis/StripeApi";

const CancelSubscription = () => {
  const navigate = useNavigate();
  const { link, error, isPending, getSubscribeLink } = useGetPaymentLink();
  const { pathname } = useLocation();
  const handleCancel = () => {
    navigate("/main-page");
  };
  const handlePayment = () => {
    if (isPending || error) return;
    window.open(link?.url, "_self");
  };

  const handelContactUs = () => {
    navigate("/contact-us");
  };

  useEffect(() => {
    getSubscribeLink();
  }, []);
  return (
    <div className="main-subscription">
      <div className="logo-container">
        <img className="expertex-logo" />
      </div>
      <div className="subscription-content">
        {pathname !== "/cancel-my-Subscription" ? (
          <img className="cancel-subscription" />
        ) : (
          <img className="cancel-mySubscription" />
        )}
        <h1 className="subscription-title">
          {pathname !== "/cancel-my-Subscription"
            ? "Subscription Not Completed!"
            : "Your subscription has been canceled!"}
        </h1>
        {pathname !== "/cancel-my-Subscription" && (
          <p className="subscription-subtitle-cancel">
            It looks like you didn't finish subscribing. Would you like to
            continue where you left off or return to the playground?
          </p>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
          }}
        >
          <button
            className="subscription-button back-button"
            onClick={handleCancel}
          >
            Return to playground
          </button>
          <button
            style={{
              backgroundColor: isPending ? "#ccc" : "#12B6EA",
              cursor: isPending ? "wait" : "pointer",
            }}
            className="subscription-button"
            onClick={handlePayment}
          >
            {pathname !== "/cancel-my-Subscription"
              ? "Subscribe now!"
              : "Re-subscribe now!"}
          </button>
        </div>
        {pathname === "/cancel-my-Subscription" && (
          <div
            style={{
              marginTop: "20px",
            }}
          >
            <p
              style={{
                fontFamily: "Roboto",
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: 400,
              }}
            >
              Need Help?{" "}
              <span
                onClick={handelContactUs}
                style={{
                  cursor: "pointer",
                  userSelect: "none",
                  color: "#12B6EA",
                }}
              >
                Contact Us
              </span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CancelSubscription;
