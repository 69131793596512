import * as signalR from '@microsoft/signalr';

class SignalRService {
  private static instance: SignalRService;
  public connection: signalR.HubConnection;

  private constructor() {
    var url = `${process.env.REACT_APP_API_Base}hubs/notifications`;
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_API_Base}hubs/notifications`)
      .build();

    this.startConnection();

    // Ensure there's only one instance
    SignalRService.instance = this;
  }

  static getInstance(): SignalRService {
    if (!SignalRService.instance) {
      SignalRService.instance = new SignalRService();
    }
    return SignalRService.instance;
  }

  private startConnection = async () => {
    try {
      await this.connection.start();
    } catch (error) {
      console.error('Error starting SignalR connection:', error);
    }
  };
}

export default SignalRService;
