import React, { useEffect, useState } from 'react'
import "./ToggleTheme.css"
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { toggleThemeMode } from '../../../../store/account/userSlice';

function ToggleTheme() {
  const fullWidthMode = useAppSelector((state) => state.ChatsState.fullWidthMode);
  const dispatch = useAppDispatch();

  let darkMode = useAppSelector((state) => state.User.darkMode);
  const [toggleState, setToggleState] = useState(darkMode);

  const setToDarkMode= () => {
    document
      .querySelector("body")
      ?.setAttribute("color-theme", "dark")
  }

  const setToLightMode = () => {
    document
      .querySelector("body")
      ?.setAttribute("color-theme", "light")
  }
  
  const toggleTheme = () => {
    setToggleState(prevState => !prevState);
    dispatch(toggleThemeMode(!darkMode));
  }

  useEffect(() => {
    if (toggleState) {
      setToDarkMode();
    } else {
      setToLightMode();
    }
  }, [toggleState]);

  let isFullWidth = (c: string) => {
    return fullWidthMode != "" && fullWidthMode != "ExpertHome" ? c : "mainContainer";
  };
  
  return (
    <div className={isFullWidth("toggleContainer")}>
      <div className={`toggleTheme d-flex align-items-center justify-content-center ${fullWidthMode === "" || fullWidthMode === "ExpertHome" ? "my-3" :""} ${isFullWidth("w-25")}`}>
        <img className='darkIcon'/>
        <p className='ChangeThemeText mb-0'>Change theme</p>
        <img className='toggle c-pointer' onClick={toggleTheme}/>
    </div>
    </div>
  )
}

export default ToggleTheme
