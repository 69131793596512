import React, { useEffect, useRef, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  Form,
  OverlayTrigger,
  Tooltip,
  TooltipProps,
} from "react-bootstrap";
import "./Navbar.css";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  getChatsOpen,
  setSelectedPersona,
  toggleFullWidthMode,
} from "../../../../store/one-query/chatsSlice";
import PersonaModal from "./PersonaModal";
import { useGetConversationTypes } from "../../../../store/Apis/ConversationTypeApi";
import { conversationsActions } from "../../../../store/one-query/conversationSlice";
import { modelNames } from "../../../../Helper";
import { useUpdateConversation } from "../../../../store/Apis/ConversationApi";
import { useParams } from "react-router-dom";
import SignToSavePopup from "../../../landing-page/components/SignToSavePopup";

interface NavbarProps {
  onChatsNumChanged: () => void;
}
const Navbar = (props: NavbarProps) => {
  const currentPersona = useAppSelector(
    (state) => state.ChatsState.selectedPersona
  );
  const options = useAppSelector((s) => s.ChatsState.selectedOptions);
  const ChatsOpened = useAppSelector((s) => s.ChatsState.chatsNum);
  const [selectedPersonaItem, setSelectedPersonaItem] = useState<string>("");
  const lastActiveModels = useAppSelector(
    (s) => s.Conversations.lastActiveModels
  );
  const dropdownRef = useRef<HTMLDivElement>(null);
  const personaInputRef = useRef<HTMLInputElement>(null);
  const isFullWidth = useAppSelector((state) => state.ChatsState.fullWidthMode);
  const conversationTypes = useAppSelector((state) => state.ConversationTypes);
  const activeConversationId = useAppSelector(
    (state) => state.Conversations.activeConversationId
  );
  const isNewConversation = useAppSelector(
    (state) => state.ChatsState.newConversation
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showAddPersona, setShowAddPersona] = useState(false);
  const [idItemDelete, setIdItemDelete] = useState<string | null>("");
  const [personaModalType, setPersonaModalType] = useState("Create");
  const [search, setSearch] = useState("");
  const getConversationTypesMutate = useGetConversationTypes();
  const [updateCurrentPersona, setUpdateCurrentPersona] = useState({
    id: "",
    title: "",
    assistant: "",
    objective: "",
    createdAt: "",
  });
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector((s) => s.User.IsAuthenticated);
  const [showSignPopup, setShowSignPopup] = useState(false);

  useEffect(() => {
    getConversationTypesMutate();
  }, []);

  const closeFullWidthChat = () => {
    dispatch(toggleFullWidthMode(""));
  };

  const toggleDropdown = () => {
    if (!dropdownOpen) {
      setSearch(""); // Reset search when opening the dropdown
      if (personaInputRef.current) {
        personaInputRef.current.value = ""; // Clear the input field when opening
      }
    }
    setDropdownOpen(!dropdownOpen);
  };

  // handel selected chats number and witch model will open
  const handleColumnClick = (chatsNum: number) => {
    dispatch(conversationsActions.setIsConHistoryClicked(false));
    let updatedOptions = options.slice(0, chatsNum);
    const usedOptions = new Set(updatedOptions);
    for (let option of modelNames) {
      if (updatedOptions.length >= chatsNum) break;
      if (!usedOptions.has(option) || option === "MistralAi") {
        updatedOptions.push(option);
        usedOptions.add(option);
      }
    }

    dispatch(getChatsOpen({ chatsNum, selectedOptions: updatedOptions }));
    closeFullWidthChat();
    props.onChatsNumChanged();
  };

  const handleSetLastActiveModels = (
    chatsNum: number,
    updatedOptions: string[]
  ) => {
    dispatch(getChatsOpen({ chatsNum, selectedOptions: updatedOptions }));
  };

  // lastActiveModels
  useEffect(() => {
    if (lastActiveModels) {
      const lastActiveOptions: string[] = [];
      lastActiveModels.forEach((model) => {
        if (model.name?.toLowerCase() === "chatgpt") {
          lastActiveOptions.push("ChatGPT");
        } else if (model.name?.toLowerCase() === "gemini") {
          lastActiveOptions.push("Gemini");
        } else if (model.name?.toLowerCase() === "llama") {
          lastActiveOptions.push("Llama");
        }
        // else if (model.name?.toLowerCase() === "jinaai") {
        //   lastActiveOptions.push("JinAai");
        // }
        else if (model.name?.toLowerCase() === "anthropic") {
          lastActiveOptions.push("Anthropic");
        } else if (model.name?.toLowerCase() === "mistralai") {
          lastActiveOptions.push("MistralAi");
        } else if (model.name?.toLowerCase() === "mistralai2") {
          lastActiveOptions.push("MistralAi");
        }
      });
      if (lastActiveModels.length > 0) {
        if (lastActiveModels.length <= 2) {
          handleSetLastActiveModels(2, lastActiveOptions);
        } else if (lastActiveModels.length <= 4) {
          handleSetLastActiveModels(4, lastActiveOptions);
        } else {
          handleSetLastActiveModels(6, lastActiveOptions);
        }
      }
    }
  }, [lastActiveModels]);

  const handleSelectCategory = (title: string) => {
    setSelectedPersonaItem(title);
    setDropdownOpen(false);
  };

  const deleteConversationType = (id: string | null) => {
    setPersonaModalType("Delete");
    setSelectedPersonaItem("Persona");
    setShowAddPersona(true);
    if (id) setIdItemDelete(id);
  };

  const openCreatePersonaModal = () => {
    if (isAuthenticated) {
      setPersonaModalType("Create");
      setShowAddPersona(true);
    } else {
      setShowSignPopup(true);
    }
  };

  const openUpdatePersonaModal = (item: any) => {
    if (item) {
      // updateConversationType(item)
      setUpdateCurrentPersona(item);
      setPersonaModalType("Update");
      setShowAddPersona(true);
    }
  };
  const allConversations = useAppSelector(
    (state) => state?.Conversations.conversations
  );
  const selectedPersona = allConversations.find(
    (con) => con?.id === activeConversationId
  )?.persona;

  useEffect(() => {
    const personaExists = conversationTypes?.data?.some(
      (conversation) => conversation?.id === selectedPersona?.id
    );
    if (currentPersona?.title?.length > 0) {
      setSelectedPersonaItem(currentPersona?.title);
    } else if (selectedPersona?.title?.length > 0 && personaExists) {
      setSelectedPersonaItem(selectedPersona.title);
    } else {
      setSelectedPersonaItem("Persona");
    }
  }, [currentPersona, selectedPersona]);

  const setCurrentPersona = (persona: any) => {
    dispatch(setSelectedPersona(persona));
    handleSelectCategory(persona?.title);
  };

  const renderTooltip =
    (tooltipText: string | undefined) => (props: TooltipProps) => {
      let description = tooltipText?.split(".")[0]?.replace(/You Are\s/gi, "");
      return tooltipText !== "" ? (
        <Tooltip id="dropdown-item-tooltip" {...props}>
          <span className="persona-item-overlay">{description? description.replace('an', 'An'): ''}</span>
        </Tooltip>
      ) : (
        <div></div>
      );
    };

  let fullWidth = isFullWidth !== "" && isFullWidth !== "ExpertHome";
  let isSelected = (columns: number) =>
    ChatsOpened === columns &&
    isFullWidth !== "ExpertModel" &&
    isFullWidth !== "ExpertHome";
  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setDropdownOpen(false);
    }
  };
  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownOpen]);

  return (
    <div className={`expert-navbar ${fullWidth && "chat-full-width"}`}>
      {fullWidth ? <img className="expertex-logo ms-4 me-5 mt-2" /> : null}
      <div className={`d-flex gap-3 c-pointer ${isFullWidth !== "" && "me-5"}`}>
        <div onClick={() => handleColumnClick(2)}>
          <img className={`one_chat ${isSelected(2) ? "selected" : ""}`} />
        </div>
        <div className="c-pointer" onClick={() => handleColumnClick(4)}>
          <img className={`two_chats ${isSelected(4) ? "selected" : ""}`} />
        </div>
        <div className="c-pointer" onClick={() => handleColumnClick(6)}>
          <img className={`three_chats ${isSelected(6) ? "selected" : ""}`} />
        </div>
      </div>

      <div className="selectCategory ms-4">
        <Dropdown
          id="persona"
          className="w-100"
          show={dropdownOpen}
          ref={dropdownRef}
        >
          <Dropdown.Toggle className={`${dropdownOpen && "opened"}`}>
            {dropdownOpen ? (
              <div className="w-100 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center w-100">
                  <label htmlFor="input" className="c-pointer">
                    <img className="persona-search-icn me-2" />
                  </label>
                  <Form.Control
                    ref={personaInputRef}
                    className="personaInput border-0 bg-transparent p-0 m-0"
                    placeholder="Persona"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                <img
                  className="persona-arrow-up"
                  src="/images/icn-arrow-up (1).svg"
                  onClick={toggleDropdown}
                />
              </div>
            ) : (
              <div
                className="d-flex align-items-center justify-content-between"
                onClick={toggleDropdown}
              >
                <span className="selectedPersonaItem">
                  {selectedPersonaItem && selectedPersonaItem.length > 22
                    ? selectedPersonaItem.slice(0, 22) + "..."
                    : selectedPersonaItem}
                </span>
                <img className="persona-arrow" />
              </div>
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu className="pe-1">
            <div
              className="addPersona d-flex align-items-center gap-2 px-3 pb-2 c-pointer"
              onClick={openCreatePersonaModal}
            >
              <img className="new-persona-icn" src="" />
              <span id="category-name"> New persona </span>
            </div>

            <div id="category">
              {conversationTypes &&
                conversationTypes.data &&
                conversationTypes.data
                  .filter((item) => {
                    return search !== ""
                      ? item.title
                          ?.trim()
                          .toLowerCase()
                          .includes(search.trim().toLowerCase())
                      : item;
                  }).map((item, i) => (
                    <OverlayTrigger
                      key={i}
                      placement="bottom"
                      delay={{ show: 250, hide: 300 }}
                      overlay={renderTooltip(item.description)}
                    >
                      <DropdownItem
                        key={i}
                        className={`d-flex justify-content-between px-2 ${
                          selectedPersonaItem === item.title ? "active" : ""
                        }`}
                      >
                        <div>
                          <span
                            className="persona-name"
                            onClick={() => setCurrentPersona(item)}
                          >
                            {item.title && item.title.length > 22
                              ? item.title.slice(0, 22) + "..."
                              : item.title}
                          </span>
                          {isAuthenticated && item.title !== "General" && (
                            <>
                              <img
                                className="icn-rename-persona"
                                onClick={() => openUpdatePersonaModal(item)}
                                alt="rename icon"
                              />
                              <img
                                className="icn-delete-persona"
                                onClick={() => deleteConversationType(item.id)}
                                alt="delete icon"
                              />
                            </>
                          )}
                        </div>
                      </DropdownItem>
                    </OverlayTrigger>
                  ))}
            </div>
          </Dropdown.Menu>
        </Dropdown>
        <PersonaModal
          show={showAddPersona}
          idItemDelete={idItemDelete}
          updateCurrentPersona={updateCurrentPersona}
          personaModalType={personaModalType}
          handleClose={() => setShowAddPersona(false)}
          setSelectedPersonaItem={setSelectedPersonaItem}
        />
        <SignToSavePopup
          show={showSignPopup}
          handleClose={() => setShowSignPopup(false)}
          popupType="Create_Persona"
        />
      </div>
    </div>
  );
};

export default Navbar;
