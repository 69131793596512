import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useCancelMySubscription } from "../../../../store/Apis/StripeApi";
import { useDispatch } from "react-redux";
import { changeSubscriptionStatus } from "../../../../store/account/userSlice";
interface CancelModalProps {
  show: boolean;
  handleClose: () => void;
  name: string;
  message?: string;
}
const CancelSubscriptionModal: React.FC<CancelModalProps> = ({
  show,
  message,
  handleClose,
  name,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cancelSubscription, isLoading, error, cancel } =
    useCancelMySubscription();

  const handleCancelSubscription = () => {
    cancelSubscription();
  };

  useEffect(() => {
    if (isLoading || error) return;
    if (cancel) {
      dispatch(changeSubscriptionStatus(false));
      navigate("/cancel-my-Subscription");
    }
  }, [cancel]);

  return (
    <Modal className="DeleteModal" show={show} onHide={handleClose} centered>
      <Modal.Header closeButton className="clearConPopupHeader border-0">
        <Modal.Title>{name}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={`DeleteModalBody border-0 px-4 rounded-bottom-4`}>
        <div className="d-flex flex-column align-items-center p-4">
          <img
            src="/images/GetConversations/icn-cancel-subscription.svg"
            className="mb-4 mt-2 pb-2"
          />

          <p
            style={{
              textAlign: "center",
            }}
          >
            {message}
          </p>
        </div>

        <div className="d-flex pb-3 buttons align-items-center justify-content-center">
          <button className="confirmDeleteBtn mx-2" onClick={handleClose}>
            No
          </button>
          <button
            disabled={isLoading}
            className="cancelDeleteBtn mx-2"
            style={{
              cursor: isLoading ? "wait" : "pointer",
              opacity: isLoading ? 0.5 : 1,
            }}
            onClick={handleCancelSubscription}
          >
            Yes
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CancelSubscriptionModal;
