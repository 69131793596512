import React, { useEffect, useState } from "react";
import "./RegisterComponent.css";
import "../../../features/main-page/components/SettingPages/ChangePassword.css";
import "../../main-page/components/SettingPages/ChangePassword.css";
import { useAppSelector } from "../../../store/hooks";
import { Form } from "react-bootstrap";
import { useHelpContactUsEmail } from "../../../store/Apis/UserApi";
import { emailRegex } from "../../../Helper";

interface IFormValues {
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  message: string;
}

const ContactUs = () => {
  const userData = useAppSelector((s) => s.User.userData);
  const darkMode = useAppSelector((state) => state.User.darkMode);
  const [isFirstNameValid, setIsFirstNameValid] = useState(true);
  const [isLastNameValid, setIsLastNameValid] = useState(true);
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [formValues, setFormValues] = useState<IFormValues>({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });
  const [firstInteraction, setFirstInteraction] = useState({
    firstName: false,
    lastName: false,
    email: false,
    message: false,
  });

  const { contactUsMutate, loading } = useHelpContactUsEmail();

  const handleSendHelpEmail = (e: React.FormEvent) => {
    e.preventDefault();
    let isFormValid = true;

    if (!formValues.firstName) {
      setIsFirstNameValid(false);
      isFormValid = false;
    } else if (!formValues.lastName) {
      setIsLastNameValid(false);
      isFormValid = false;
    } else if (!formValues.email || isInvalidEmail) {
      setIsInvalidEmail(true);
      isFormValid = false;
    } else if (!formValues.message) {
      setMessageError(true);
      isFormValid = false;
    } else if (isFormValid) {
      contactUsMutate(
        formValues.firstName,
        formValues.lastName,
        formValues.email,
        formValues.message
      );
    }
  };

  useEffect(() => {
    setFormValues({
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      email: userData?.email,
      message: "",
    });
  }, [userData]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });

    if (name === "firstName") {
      setIsFirstNameValid(value.length > 0);
    } else if (name === "lastName") {
      setIsLastNameValid(value.length > 0);
    } else if (name === "email") {
      setIsInvalidEmail(!emailRegex.test(value));
    } else if (name === "message") {
      setMessageError(value.trim().length === 0);
    }
  };

  const handleFocus = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name } = e.target;
    setFirstInteraction({
      ...firstInteraction,
      [name]: true,
    });

    if (name === "firstName" && formValues.firstName?.length === 0) {
      setIsFirstNameValid(false);
    } else if (name === "lastName" && formValues.lastName?.length === 0) {
      setIsLastNameValid(false);
    } else if (name === "email" && formValues.email?.length === 0) {
      setIsInvalidEmail(true);
    } else if (name === "message" && formValues.message?.trim().length === 0) {
      setMessageError(true);
    }
  };

  return (
    <div className="mainRegister">
      <div className="borderRegister">
        <div className="leftPart col-md-6">
          <div className="ellipse2 ellipse z-n1"></div>
          <div className="ellipse1 ellipse"></div>
          <div className="ellipse8 ellipse"></div>
          <div className="ellipse3 ellipse"></div>
          <div className="ellipse6 ellipse"></div>
          <div className="ellipse5 ellipse"></div>
          <div className="ellipse9 ellipse"></div>
          <div className="container-fluid h-100">
            <div className="row h-100" style={{ overflowY: "scroll" }}>
              <div className="divRegisterForm col-12">
                <form
                  className="d-flex align-items-center flex-column w-75"
                  onSubmit={handleSendHelpEmail}
                >
                  <img className="ExpertexLogoRegister" alt="Expertex_logo" />
                  <div className="ms-3 w-100 d-flex align-start">
                    <p className="contact_us_head_title">Let’s talk!</p>
                  </div>
                  <div className="innerFormDiv w-100">
                    <div className="name_container">
                      <div className="user_input col-12 col-md-5">
                        <input
                          type="text"
                          name="firstName"
                          required
                          value={formValues.firstName}
                          placeholder="First name"
                          onChange={handleChange}
                          className={
                            isFirstNameValid
                              ? "inputPasswordBorderBlue"
                              : "inputPasswordBorderRed"
                          }
                          onFocus={handleFocus}
                        />
                        {!isFirstNameValid && firstInteraction.firstName && (
                          <div className="ms-1 d-flex align-items-center">
                            <img className="icn_error" alt="Error" />
                            <div className="warningNameRegister ps-2 text-left">
                              Please enter your first name
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="user_input col-12 col-md-5">
                        <input
                          type="text"
                          name="lastName"
                          required
                          value={formValues.lastName}
                          placeholder="Last name"
                          onChange={handleChange}
                          className={
                            isLastNameValid
                              ? "inputPasswordBorderBlue"
                              : "inputPasswordBorderRed"
                          }
                          onFocus={handleFocus}
                        />
                        {!isLastNameValid && firstInteraction.lastName && (
                          <div className="ms-1 d-flex align-items-center">
                            <img className="icn_error" alt="Error" />
                            <div className="warningNameRegister ps-2 text-left">
                              Please enter your last name
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      <input
                        type="text"
                        name="email"
                        value={formValues.email}
                        placeholder="Enter email or phone"
                        onChange={handleChange}
                        onFocus={handleFocus}
                        className={
                          isInvalidEmail
                            ? "inputPasswordBorderRed"
                            : "inputPasswordBorderBlue"
                        }
                      />
                      {isInvalidEmail && firstInteraction.email && (
                        <div className="ms-1 d-flex align-items-center">
                          <img className="icn_error" alt="Error" />
                          <div className="warningNameRegister ps-2 text-left">
                            Invalid email address
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="message_container">
                      <Form.Control
                        onChange={handleChange}
                        onFocus={handleFocus}
                        value={formValues.message}
                        name="message"
                        className={`contact_us_msg`}
                        as="textarea"
                        placeholder="Leave your message here...."
                        rows={4}
                        required
                      />
                      {messageError && (
                        <div className="ms-1 d-flex align-items-center">
                          <img className="icn_error" alt="Error" />
                          <div className="warningNameRegister ps-2 text-left">
                            Please enter your message
                          </div>
                        </div>
                      )}
                    </div>
                    <button
                      disabled={loading}
                      className={`regButton ${loading && "opacity-50"}`}
                      type="submit"
                    >
                      Send message
                    </button>
                    {/* <div className="container-fluid orConatiner w-100">
                      <span className="orName col-4">Follow us on</span>
                      <span className="hrLine col-8"></span>
                    </div>
                    <div className="iconsDivRegister">
                      <button className="iconButton">
                        <img src="/Images/googleLogo.svg" alt="Google" />
                      </button>
                      <button className="iconButton">
                        <img src="/Images/twitterLogo.svg" alt="Twitter" />
                      </button>
                      <button className="iconButton">
                        <img src="/Images/facebookLogo.svg" alt="Facebook" />
                      </button>
                      <button className="iconButton">
                        <img src="/Images/appleLogo.svg" alt="Apple" />
                      </button>
                    </div> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="contact_us_image"></div>
      </div>
    </div>
  );
};

export default ContactUs;
