// src/components/DeleteConversationsModal.tsx
import React, { useEffect, useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import "./DeletePopup.css";
import { useClearAllConversations } from "../../../../store/Apis/ConversationApi";
import {
  handleNewConversationRequest,
  setFileError,
} from "../../../../store/one-query/chatsSlice";
import { useNavigate, useParams } from "react-router-dom";

interface DeleteModalProps {
  show: boolean;
  handleClose: () => void;
  name: string;
  message?: string;
  method?: () => void;
  page?: string;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  show,
  handleClose,
  name,
  message,
  method,
  page,
}) => {
  const conversationsHistory = useAppSelector(
    (state) => state.Conversations.conversations
  );
  const [confirmDelete, setConfirmDelete] = useState<string>("");
  const dispatch = useAppDispatch();
  const clearAllConversations = useClearAllConversations();
  const navigate = useNavigate();
  const [flagDeleteAllConversation, setFlagDeleteAllConversation] =
    useState<boolean>(false);
  const [flagDeleteSpecficConversation, setFlagDeleteSpecficConversation] =
    useState<boolean>(false);
  const { id } = useParams<{ id: string }>();

  const handleDelete = () => {
    if (name === "Clear all conversations") {
      if (confirmDelete.trim().toLowerCase() === "delete") {
        clearAllConversations();
        dispatch(handleNewConversationRequest());
        handleClose();
        setConfirmDelete("");
        setFlagDeleteAllConversation(true);
      } else {
        dispatch(
          setFileError({
            isError: true,
            errorMsg: `"${confirmDelete}" is misspelled. Please correct it to "delete" and try again.`,
            reason: "clear all Conversation",
          })
        );
      }
    } else {
      if (method) {
        method();
      }
      setFlagDeleteSpecficConversation(true);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      handleDelete();
    }
  };

  useEffect(() => {
    setConfirmDelete("");
  }, [handleClose]);

  useEffect(() => {
    if (
      (id && flagDeleteAllConversation && conversationsHistory.length === 0) ||
      (id && flagDeleteSpecficConversation)
    )
      navigate("/main-page");
    setFlagDeleteSpecficConversation(false);
  }, [
    id,
    conversationsHistory,
    flagDeleteSpecficConversation,
    flagDeleteAllConversation,
  ]);

  return (
    <Modal className="DeleteModal" show={show} onHide={handleClose} centered>
      <Modal.Header closeButton className="clearConPopupHeader border-0">
        <Modal.Title>{name}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={`DeleteModalBody border-0 px-4 rounded-bottom-4`}>
        {conversationsHistory.length === 0 ? (
          <div className="d-flex flex-column align-items-center p-4">
            <img
              src="/images/GetConversations/icn-cancel-subscription.svg"
              className="mb-4 mt-2 pb-2"
            />
            {page === "Landing-page" ? (
              <p className="emptyHistoryText">
                <div>Almost there!</div>
                <span
                  className="c-pointer text-decoration-underline"
                  onClick={() => navigate("/account/login")}
                >
                  Sign in
                </span>{" "}
                or{" "}
                <span
                  className="c-pointer text-decoration-underline"
                  onClick={() => navigate("/account/register")}
                >
                  Register
                </span>{" "}
                to unlock this chat.
              </p>
            ) : (
              <p className="emptyHistoryText">
                History is already empty. There's nothing to delete.
              </p>
            )}
          </div>
        ) : (
          <div className="deleteRequirements d-flex flex-column align-items-center justify-content-center">
            <div className="mb-4 d-flex flex-column align-items-center text-center">
              <img
                src="/images/GetConversations/icn-cancel-subscription.svg"
                className="pb-4 mt-2"
              />
              <p className="warningDeleteText">{message}</p>
              {name === "Clear all conversations" && (
                <Form.Control
                  className="confirmDeleteInput"
                  placeholder="Type 'delete' in the box to confirm."
                  value={confirmDelete}
                  onChange={(e) => setConfirmDelete(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
              )}
            </div>
            <div className="d-flex pb-3 buttons">
              <button
                className="confirmDeleteBtn mx-2 py-3"
                onClick={handleDelete}
              >
                {name === "Delete conversation" ? "Delete" : "Confirm"}
              </button>
              <button className="cancelDeleteBtn mx-2" onClick={handleClose}>
                Cancel
              </button>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default DeleteModal;
