import { useQuery } from "@tanstack/react-query";
import Api from "../../features/one-query/services/ApiServices";
import { useEffect, useState } from "react";

interface PaymentLinkResponse {
  url: string;
}
const getPaymentLink = async () => {
  try {
    const response = await Api.post("/Stripe/subscribe");
    return response.data;
  } catch (error) {
    throw error;
  }
};

const useGetPaymentLinkQuery = () => {
  return useQuery<PaymentLinkResponse, Error>({
    queryKey: ["paymentLink"],
    queryFn: getPaymentLink,
    enabled: false, // Disable automatic query execution
  });
};

const useGetPaymentLink = () => {
  const { error, isPending, refetch } = useGetPaymentLinkQuery();
  const [link, setLink] = useState<PaymentLinkResponse | null>(null);

  const getSubscribeLink = async () => {
    const { data } = await refetch();
    if (data) {
      setLink(data);
    }
  };

  return { link, getSubscribeLink, isPending, error };
};

// ========================================

const getSubscribeCost = async () => {
  try {
    const response = await Api.get("/Stripe/GetSubscriptionCost");
    return response.data;
  } catch (error) {
    throw error;
  }
};

const useGetSubscribeCostQuery = () => {
  return useQuery<number, Error>({
    queryKey: ["subscribeCost"],
    queryFn: getSubscribeCost,
    enabled: false, // Disable automatic query execution
  });
};

const useGetSubscribeCost = () => {
  const { data, error, isLoading, refetch } = useGetSubscribeCostQuery();
  const [cost, setCost] = useState<number | null>(null);

  const getSubscribeCost = async () => {
    const { data } = await refetch();
    if (data) {
      setCost(data);
    }
  };

  return { cost, getSubscribeCost, isLoading, error };
};

const cancelMySubscription = async () => {
  try {
    const response = await Api.post("/Stripe/CancelSubscription");
    return response.data;
  } catch (error) {
  }
};

const useCancelMySubscriptionQuery = () => {
  return useQuery({
    queryKey: ["cancelMySubscription"],
    queryFn: cancelMySubscription,
    enabled: false,
  });
};

const useCancelMySubscription = () => {
  const { error, isLoading, refetch } = useCancelMySubscriptionQuery();
  const [cancel, setCancel] = useState<boolean>();
  const cancelSubscription = async () => {
    const { data } = await refetch();
    setCancel(data);
  };

  return { cancelSubscription, isLoading, error, cancel };
};
export { useGetPaymentLink, useGetSubscribeCost, useCancelMySubscription };
