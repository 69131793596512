import React from "react";
import "./Models.css";

interface Props {
  modelName: string;
}

const ResponseLoading = ({ modelName }: Props) => {
  function capitalizeWords() {
    return modelName?.replace(/ai/gi, "Ai")?.replace(/gpt/gi, "GPT");
  }

  return (
    <div>
      <div
        className="chatResponse position-relative mb-3"
        style={{ width: "100px" }}
      >
        <div
          id="chat-details"
          className=" d-flex align-items-center justify-content-between"
        >
          <div
            className={`d-flex align-items-center mb-1 justify-content-center gap-2`}
          >
            <img className={`${modelName}Logo`} />
            <p className="chatName mb-0">{capitalizeWords()}</p>
          </div>
        </div>
        <div
          className="response position-relative w-100 d-flex align-items-center "
          style={{ height: "60px" }}
        >
          <div className="loading d-flex gap-1 mt-2">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResponseLoading;
