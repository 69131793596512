import React, { useState } from "react";
import { useAppSelector } from "../../../../store/hooks";
import { AttachmentPayload } from "../../../../Types";

interface StreamingUserQuestionProps {
  prompt: string;
  attachmentFile?: AttachmentPayload;
}

const StreamingUserQuestion = ({
  prompt,
  attachmentFile,
}: StreamingUserQuestionProps) => {
  const darkMode = useAppSelector((state) => state.User.darkMode);
  const userDataProfile = useAppSelector((state) => state.User.userData);
  const userIsAuthenticated = useAppSelector((s) => s.User.IsAuthenticated);
  const pictureUrl = useAppSelector((s) => s.User?.profilePictureURL);
  let colorAvatr;
  const [currentDate] = useState(getCurrentFormattedTime);

  function getCurrentFormattedTime(): string {
    const now = new Date();
    const options: Intl.DateTimeFormatOptions = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return now.toLocaleTimeString("en-US", options);
  }

  return (
    <>
      <div className="position-relative my-3 w-100">
        <div id="user" className="d-flex align-items-center">
          {userDataProfile && (
            <>
              {userIsAuthenticated ? (
                pictureUrl ? (
                  <img className="userImage" src={pictureUrl} />
                ) : (
                  <span
                    className="userImage"
                    style={{ backgroundColor: `${colorAvatr}` }}
                  >
                    {userDataProfile?.firstName &&
                      userDataProfile?.lastName &&
                      userDataProfile.firstName.charAt(0) +
                        userDataProfile.lastName.charAt(0)}
                  </span>
                )
              ) : darkMode ? (
                <img className="userImage" src="/Images/Property 1=dark.svg" />
              ) : (
                <img className="userImage" src="/Images/Property 1=light.svg" />
              )}
            </>
          )}
          <p className="userName mb-0">
            {userIsAuthenticated
              ? userDataProfile?.firstName + " " + userDataProfile?.lastName
              : "Guest"}
          </p>
        </div>

        <div className="question position-relative d-flex flex-column justify-content-between overflow-hidden">
          {attachmentFile?.name && (
            <div className="d-flex flex-row containerFileUpload">
              <img
                className="me-3"
                src={
                  attachmentFile?.extension === "pdf"
                    ? "/Images/GetConversations/icn-pdf-light.svg"
                    : attachmentFile?.extension === "doc" ||
                      attachmentFile?.extension === "docx"
                    ? "/Images/GetConversations/icn-doc-light.svg"
                    : "/Images/GetConversations/icn-xlsx-light.svg"
                }
              />
              <div className="d-flex flex-column fileName">
                <span>{attachmentFile?.name}</span>
                <span>.{attachmentFile?.extension}</span>
              </div>
            </div>
          )}

          <div className="conatainerQuestionSentToBackEnd d-flex align-items-center justify-content-between  w-100 gap-3">
            <p className="questionText mb-0">{prompt}</p>

            <img
              className="c-pointer"
              src={darkMode ? "/images/icn-Edit-dark.svg" : "/images/Edit.svg"}
            />
          </div>

          <div className="align-self-end">
            <span className="questionDate">{currentDate}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default StreamingUserQuestion;
