import React, { memo, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import "./UserQuestion.css";
import { AttachmentPayload } from "../../../../Types";
import SignalRService from "../../../one-query/services/SignalRService";
import { useEditMessage } from "../../../../store/Apis/ConversationApi";
import {
  setCurrentObjSave,
  setIsEditingStatus,
  setParentMessageId,
} from "../../../../store/one-query/chatsSlice";
import {
  setIsEditing,
  setNextEditIndex,
  setNextReplyIndex,
  setPrevEditIndex,
  setPrevReplyIndex,
  setQueryEditsMessage,
  updateModelReplyIndex,
} from "../../../../store/one-query/ChatModelSlice";
import { ChatModelEditsState, ChatModelState } from "../../../../Helper";
import ChatResponse from "./NewChatResponse";
import ResponseLoading from "./ResponseLoading";

interface UserQuestionProps {
  attachmentFile?: AttachmentPayload;
  messageId: string;
  editMessageId: string;
  setQuery: (prompt: string) => void;
  modelName: string;
  response?: string;
  isFinished: boolean;
}

const UserQuestion= memo(({
  attachmentFile,
  messageId,
  editMessageId,
  setQuery,
  modelName,
  response,
  isFinished,
}:UserQuestionProps) => {
  let colorAvatr;
  const dispatch = useAppDispatch();
  const darkMode = useAppSelector((state) => state.User.darkMode);
  const userDataProfile = useAppSelector((state) => state.User.userData);
  const userIsAuthenticated = useAppSelector((s) => s.User.IsAuthenticated);
  const pictureUrl = useAppSelector((s) => s.User?.profilePictureURL);
  const IsEditingStatus = useAppSelector((s) => s?.ChatsState?.isEditing);

  const editQuestionInputRef = useRef<HTMLInputElement>(null);
  const [currentDate] = useState(getCurrentFormattedTime);
  const [isOpenToEdit, setIsOpenToEdit] = useState(false);
  const [editedMsgPrompt, setEditedMsgPrompt] = useState<string | undefined>(
    ""
  );
  const ParentMessageId = useAppSelector((s) => s.ChatsState.ParentMessageId);
  const modelState = useAppSelector((s) => s.chatModel.chatModelData);
  const chatModelEditsMessages = useAppSelector(
    (s) => s.chatModel.chatModelsReponsesAndEdits
  );
  const [currentEditMessageIndex, setCurrentEditMessageIndx] =
    useState<number>(0);
  const [currentReplyMessageIndex, seCurrentReplyMessageIndex] =
    useState<number>(0);
  const [currentMsgPrompt, setCurrentMsgPrompt] = useState<any>();
  const [currentMsgReply, setCurrentMsgReply] = useState<any>();
  const [currentMsgPromptId, setCurrentMsgPromptId] = useState<any>();
  const EditMsgIndex = useAppSelector(
    (s) =>
      s.chatModel.chatModelsReponsesAndEdits.find((msg) => msg.MessageId === messageId)
        ?.EditMessageIndex
  );
  const [currentMsgObj, setCurrentMsgObj] = useState<ChatModelState>();
  const [currentMsgIndexesObj, setCurrentMsgIndexesObj] =
    useState<ChatModelEditsState>();
  const { editMessageMutate, loading } = useEditMessage();
  const [messagesLength, setMessagesLength] = useState<any>();
  const ReplyMsgIndex = useAppSelector(
    (s) =>
      s.chatModel.chatModelsReponsesAndEdits.find(
        (msg) => msg.MessageId === currentMsgIndexesObj?.MessageId
      )?.ResponseIndex
  );

  const [MsgObj, setMsjObj] = useState<ChatModelState>();

  const findCurrentMessage = () => {
    // Find the message object based on the messageId
    const findMsgObject = modelState.find((msg: any) => msg.id === messageId);
    const findMsgObjFromEditsAndReplies = chatModelEditsMessages.find(
      (msg: any) => msg.MessageId === messageId
    );

    // Update message state
    setCurrentMsgObj(findMsgObject);
    setMsjObj(findMsgObject);
    setCurrentMsgIndexesObj(findMsgObjFromEditsAndReplies);
    // If a valid message is found
    if (findMsgObject) {
      // Handle replies and edit cases
      if (findMsgObject?.queryEdits && findMsgObject?.queryEdits?.length < 1) {
        setCurrentMsgPrompt(findMsgObject?.prompt);
        setCurrentMsgReply(
          findMsgObject?.replies[currentReplyMessageIndex]?.reply
        );
        setMessagesLength(findMsgObject?.replies?.length);
        setCurrentMsgPromptId(findMsgObject?.id);
      } else if (findMsgObject?.queryEdits) {
        // If there are query edits, get the edited message details
        const EditMsgId = findMsgObject.queryEdits[currentEditMessageIndex];
        const editedMessage = modelState.find(
          (msg: any) => msg.id === EditMsgId
        );
        const msgObjFromEditsAndReplies = chatModelEditsMessages.find(
          (msg: any) => msg.MessageId === currentMsgPromptId
        );
        // Update state with edited message details
        setCurrentMsgPrompt(editedMessage?.prompt);
        setMessagesLength(editedMessage?.replies?.length);
        setCurrentMsgPromptId(editedMessage?.id);
        setCurrentMsgReply(
          editedMessage?.replies[currentReplyMessageIndex]?.reply
        );

        setMsjObj(editedMessage);
        setCurrentMsgIndexesObj(msgObjFromEditsAndReplies);
      }

      // Handle the prompt for edited message
      setEditedMsgPrompt(findMsgObject?.prompt);
    }
  };

  useEffect(() => {
    if (isFinished)
      findCurrentMessage();
  }, [isFinished]);
  useEffect(() => {
    findCurrentMessage();
  }, [modelState, chatModelEditsMessages,messageId]);

  useEffect(() => {
    if (currentReplyMessageIndex >= 0 || currentEditMessageIndex >= 0 )
      findCurrentMessage();
  }, [currentReplyMessageIndex, currentEditMessageIndex]);

  useEffect(() => {
    setCurrentEditMessageIndx(EditMsgIndex); // not rev
  }, [EditMsgIndex]);

  useEffect(() => {
    if (ReplyMsgIndex===undefined)
      seCurrentReplyMessageIndex(0);
    else
       seCurrentReplyMessageIndex(ReplyMsgIndex);

  }, [ReplyMsgIndex]);

  // handel set parent id
  const handleOpenToEditQuestion = () => {
    if (currentMsgObj?.parentId) {
      dispatch(setParentMessageId(currentMsgObj?.parentId));
    } else {
      dispatch(setParentMessageId(messageId));
    }
    const currentObj = modelState?.find((obj) => obj.id === messageId);
    if (currentObj) dispatch(setCurrentObjSave(currentObj));
    setEditedMsgPrompt(currentMsgPrompt);
    setIsOpenToEdit(true);
  };

  // handle send edit request to BE
  const HandelEditQuestionRequest = () => {
    const connectionId = SignalRService.getInstance().connection.connectionId;
    if (connectionId && editedMsgPrompt && messageId) {
      dispatch(setIsEditing({ id: messageId, isEditing: true }));
      dispatch(setIsEditingStatus(true));
      editMessageMutate(messageId, editedMsgPrompt, connectionId);
      setQuery(editedMsgPrompt); // set the new question in the store
      setIsOpenToEdit(false);
    }
  };

  // handle send edit request to BE by Enter key
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      if (editedMsgPrompt) {
        HandelEditQuestionRequest();
      }
    }
  };

  // make the input of edit question focus when open
  useEffect(() => {
    if (isOpenToEdit && editQuestionInputRef.current) {
      editQuestionInputRef.current.focus();
    }
  }, [isOpenToEdit]);

  useEffect(() => {
    if (ParentMessageId && editMessageId) {
      dispatch(
        setQueryEditsMessage({
          messageId: editMessageId,
          parentMessageId: ParentMessageId,
        })
      );
    }
  }, [editMessageId]);

  const HandleNextEditMessageIndex = () => {
    let editAndReplyIndex = currentMsgIndexesObj; 
    // TODO: must be removed ASAP 
    if (currentMsgObj && !currentMsgIndexesObj) {
      editAndReplyIndex = chatModelEditsMessages.find((msg: any) => msg.MessageId === messageId);
      setCurrentMsgIndexesObj(editAndReplyIndex);
    }
    if (currentMsgObj?.queryEdits && editAndReplyIndex?.MessageId) {
      if (
        currentMsgObj?.queryEdits.length - 1 >
        editAndReplyIndex.EditMessageIndex
      ) {
        dispatch(setNextEditIndex({ messageId: messageId }));
        //currentMsgObj.queryEdits[currentEditMessageIndex+1]

        dispatch(
          updateModelReplyIndex({
            messageId: currentMsgObj.queryEdits[currentEditMessageIndex],
            replyIndex: 0,
          })
        );
      }
    }
  };

  const HandlePrevEditMessageIndex = () => {
    if (currentMsgObj?.queryEdits && currentMsgIndexesObj?.MessageId) {
      if (currentMsgIndexesObj.EditMessageIndex >= 0) {
        dispatch(setPrevEditIndex({ messageId: messageId }));
        dispatch(
          updateModelReplyIndex({
            messageId: currentMsgObj.queryEdits[currentEditMessageIndex],
            replyIndex: 0,
          })
        );
      }
    }
  };

  useEffect(() => {
    if (MsgObj && MsgObj.isRegernating) {
      dispatch(
        updateModelReplyIndex({
          // make user stand on first reply
          messageId: MsgObj.id,
          replyIndex: MsgObj?.replies.length,
        })
      );
    }
  }, [MsgObj]);

   function getCurrentFormattedTime(): string {
    const now = new Date();
    const options: Intl.DateTimeFormatOptions = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return now.toLocaleTimeString("en-US", options);
  }

  function formatTime(dateStr: string | undefined): string {
    if (!dateStr) {
      return currentDate;
    }
    const date = new Date(dateStr);
    const options: Intl.DateTimeFormatOptions = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return date.toLocaleTimeString("en-US", options);
  }

  // handle BG of user name if there is no user image
  const stringToHslColor = (str: string, s: number, l: number) => {
    var hash = 0;
    for (var i = 0; i < str?.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var h = hash % 360;
    return "hsl(" + h + ", " + s + "%, " + l + "%)";
  };

  if (userDataProfile?.firstName && userDataProfile?.lastName) {
    colorAvatr = stringToHslColor(
      userDataProfile.firstName + userDataProfile.lastName,
      30,
      50
    );
  }
  const isFirstReply = currentReplyMessageIndex === 0;
  const isLastReply = messagesLength
    ? currentReplyMessageIndex >= messagesLength - 1
    : true;

  const HandleNextReply = () => {
    if (!isLastReply) {
      dispatch(setNextReplyIndex({ messageId: currentMsgPromptId }));
    }
  };

  const HandlePrevReply = () => {
    if (!isFirstReply) {
      dispatch(setPrevReplyIndex({ messageId: currentMsgPromptId }));
    }
  };

  return (
    !currentMsgObj?.parentId &&
    !MsgObj?.isRegernating &&
    !currentMsgObj?.isEditing && (
      <>
        <div className="position-relative my-3 w-100">
          <div id="user" className="d-flex align-items-center">
            {userDataProfile && (
              <>
                {userIsAuthenticated ? (
                  pictureUrl ? (
                    <img className="userImage" src={pictureUrl} />
                  ) : (
                    <span
                      className="userImage"
                      style={{ backgroundColor: `${colorAvatr}` }}
                    >
                      {userDataProfile?.firstName &&
                        userDataProfile?.lastName &&
                        userDataProfile.firstName.charAt(0) +
                          userDataProfile.lastName.charAt(0)}
                    </span>
                  )
                ) : darkMode ? (
                  <img
                    className="userImage"
                    src="/Images/Property 1=dark.svg"
                  />
                ) : (
                  <img
                    className="userImage"
                    src="/Images/Property 1=light.svg"
                  />
                )}
              </>
            )}
            <p className="userName mb-0">
              {userIsAuthenticated
                ? userDataProfile?.firstName + " " + userDataProfile?.lastName
                : "Guest"}
            </p>
          </div>
          {isOpenToEdit ? (
            <>
              <div className="editQuestion">
                <input
                  className="editQuestionInput mb-3"
                  value={editedMsgPrompt}
                  onChange={(e) => setEditedMsgPrompt(e.target.value)}
                  onKeyDown={handleKeyDown}
                  ref={editQuestionInputRef}
                />
                <div className="editMsgBTNs">
                  <button
                    className="cancelEditBtn"
                    onClick={() => setIsOpenToEdit(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="sendEditBtn"
                    onClick={HandelEditQuestionRequest}
                  >
                    Send
                  </button>
                </div>
              </div>
            </>
          ) : (
            <div className="question position-relative d-flex flex-column justify-content-between overflow-hidden">
              {attachmentFile?.name && (
                <div className="d-flex flex-row containerFileUpload">
                  <img
                    className="me-3"
                    src={
                      attachmentFile?.extension === "pdf"
                        ? "/Images/GetConversations/icn-pdf-light.svg"
                        : attachmentFile?.extension === "doc" ||
                          attachmentFile?.extension === "docx"
                        ? "/Images/GetConversations/icn-doc-light.svg"
                        : "/Images/GetConversations/icn-xlsx-light.svg"
                    }
                  />
                  <div className="d-flex flex-column fileName">
                    <span>{attachmentFile?.name}</span>
                    <span>.{attachmentFile?.extension}</span>
                  </div>
                </div>
              )}

              <div className="conatainerQuestionSentToBackEnd d-flex align-items-center justify-content-between  w-100 gap-3">
                <p className="questionText mb-0">{currentMsgPrompt}</p>

                <img
                  className="c-pointer"
                  src={
                    darkMode ? "/images/icn-Edit-dark.svg" : "/images/Edit.svg"
                  }
                  onClick={handleOpenToEditQuestion}
                />
              </div>

              <div className="align-self-end">
                <span className="questionDate">
                  {formatTime(currentMsgObj?.createdAt)}
                </span>
              </div>
            </div>
          )}

          {currentMsgObj?.queryEdits &&
          currentMsgObj?.queryEdits?.length > 0 &&
          !isOpenToEdit ? (
            <div className="navigationButtons d-flex align-items-center">
              <button
                className={`changeBtn ${
                  currentEditMessageIndex < 1 && "opacity-50"
                }`}
                onClick={HandlePrevEditMessageIndex}
                disabled={currentEditMessageIndex < 1}
              >
                <img src="/Images/Models/AL.svg" alt="" />
              </button>
              <div className="mt-2 mb-2 replyIndex">
                {currentEditMessageIndex +
                  1 +
                  "/" +
                  currentMsgObj.queryEdits.length}
              </div>
              <button
                className={`changeBtn ${
                  currentEditMessageIndex ===
                    currentMsgObj.queryEdits.length - 1 && "opacity-50"
                }`}
                onClick={HandleNextEditMessageIndex}
                disabled={
                  currentEditMessageIndex ===
                  currentMsgObj.queryEdits.length - 1
                }
              >
                <img src="/Images/Models/AR.svg" alt="" />
              </button>
            </div>
          ) : null}
        </div>

        {true ? (
          <ChatResponse
            currentReply={currentMsgReply}
            modelName={modelName}
            prompt={currentMsgPrompt}
            currentMessageId={currentMsgPromptId}
            setQuery={setQuery}
          />
        ) : (
          !response &&
          (MsgObj?.isRegernating || currentMsgObj?.isEditing) && (
            <ResponseLoading modelName={modelName} />
          )
        )}
        {messagesLength > 1 && !MsgObj?.isRegernating && (
          <div className="mb-3 d-flex align-items-center">
            <button
              className={`changeBtn ${isFirstReply && "opacity-50"}`}
              onClick={HandlePrevReply}
              disabled={isFirstReply}
            >
              <img src="/Images/Models/AL.svg" alt="" />
            </button>
            <div className="mt-2 mb-2 replyIndex">
              {currentReplyMessageIndex + 1 + "/" + messagesLength}
            </div>
            <button
              className={`changeBtn ${isLastReply && "opacity-50"}`}
              onClick={HandleNextReply}
              disabled={isLastReply}
            >
              <img src="/Images/Models/AR.svg" alt="" />
            </button>
          </div>
        )}
      </>
    )
  );
});

export default UserQuestion;
