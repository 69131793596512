import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "../../features/one-query/services/ApiServices";
import { setFileError, setSnackBarAlert } from "./chatsSlice";
interface ConversationState {
  conversations: ConversationData[];
  activeConversationId?: string;
  errorMessage: string;
  isLoadingForGet: boolean;
  isLoadingForCreate: boolean;
  isLoadingForUpdate: boolean;
  isLoadingForDelete: boolean;
  conversationConversationModels: string[];
  lastActiveModels: any[];
  isConversationHistoryClicked: boolean;
  isNewConversation: boolean;
  currentSelectedPersonaFilter?: string | null;
}

export interface ConversationData {
  persona?: any;
  id?: string;
  name?: string;
  date?: string;
  createdAt?: string;
  conversationTypeId?: string;
  conversationType?: string;
  title?: string;
  ConversationModels?: { name: string; order: number }[];
  conversationConversationModels?: [];
  lastActiveModels?: {
    name: string | undefined;
    version: string;
    id: string;
  }[];
  isConversationHistoryClicked?: false;
  isNewConversation?: false;
  personaId: string;
}

const Conversations = createSlice({
  name: "Conversations",
  initialState: {
    conversations: [] as ConversationData[],
  } as ConversationState,
  reducers: {
    setActiveConversation: (state, action: PayloadAction<string>) => {
      state.activeConversationId = action.payload;
    },

    getConversationRequest: (state, action: PayloadAction<void>) => {
      state.isLoadingForGet = true;
      state.errorMessage = "";
    },

    getConversationSuccess: (
      state,
      action: PayloadAction<ConversationData[]>
    ) => {
      state.conversations = action.payload;
      state.isLoadingForGet = false;
    },

    getConversationError: (state, action: PayloadAction<string>) => {
      state.isLoadingForGet = false;
      state.errorMessage = action.payload;
    },

    createConversationRequest: (state, action: PayloadAction<void>) => {
      state.isLoadingForCreate = true;
      state.errorMessage = "";
    },

    newConversationSuccess: (
      state,
      action: PayloadAction<ConversationData>
    ) => {
      state.conversations.push(action.payload);
      state.isLoadingForCreate = false;
    },

    newConversationError: (state, action: PayloadAction<string>) => {
      state.isLoadingForCreate = false;
      state.errorMessage = action.payload;
    },

    deleteConversationRequest: (state, action: PayloadAction<string>) => {
      state.isLoadingForDelete = true;
      state.errorMessage = "";
    },

    deleteConversationSuccess: (state, action: PayloadAction<string>) => {
      const conversationId = action.payload;
      state.conversations = state.conversations.filter(
        (conversation) => conversation.id !== conversationId
      );
      state.isLoadingForDelete = false;
    },

    deleteConversationError: (state, action: PayloadAction<string>) => {
      state.isLoadingForDelete = false;
      state.errorMessage = action.payload;
    },

    updateConversationRequest: (state, action: PayloadAction<string>) => {
      state.isLoadingForUpdate = true;
      state.errorMessage = "";
    },

    updateConversationSuccess: (
      state,
      action: PayloadAction<ConversationData>
    ) => {
      const updatedConversation = action.payload;

      // Find the conversation that needs to be updated
      const toBeUpdated = state.conversations.find(
        (con) => con.id === updatedConversation.id
      );

      if (toBeUpdated) {
        toBeUpdated.name = updatedConversation.name;
        toBeUpdated.date = updatedConversation.date;
        toBeUpdated.persona = updatedConversation.persona;
      }

      state.isLoadingForUpdate = false;
    },

    updateConversationError: (state, action: PayloadAction<string>) => {
      state.isLoadingForUpdate = false;
      state.errorMessage = action.payload;
    },
    removeActiveConversation: (state) => {
      state.activeConversationId = undefined;
    },
    setConversationModelsIDs: (state, action: PayloadAction<string[]>) => {
      state.conversationConversationModels = action.payload;
    },
    clearAllConversations: (state) => {
      state.conversations = [];
    },
    getLastActiveModels: (state, action: PayloadAction<any>) => {
      state.lastActiveModels = action.payload;
    },
    setIsConHistoryClicked: (state, action: PayloadAction<boolean>) => {
      state.isConversationHistoryClicked = action.payload;
    },
    setIsNewConversation: (state, action: PayloadAction<boolean>) => {
      state.isNewConversation = action.payload;
    },
    setCurrentPersonaFilter: (state, action: PayloadAction<string | null>) => {
      state.currentSelectedPersonaFilter = action.payload;
    },
  },
});

export const SetActiveConversationModelStatus =
  (ConversationModelStatus: any) => (dispatch: any) => {
    Api.post(
      `Conversation/SetConversationUserModelStatus`,
      JSON.stringify(ConversationModelStatus)
    )
      .then((res) => {})
      .catch((e) => {});
  };

export const conversationsActions = Conversations.actions;
export default Conversations.reducer;
