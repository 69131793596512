import { AttachmentPayload } from "./Types";

export const ModelsCompany = [
  "OpenAI",
  "Google",
  "Meta",
  // "JinAai",
  "Anthropic",
  "MistralAi",
];

export const ModelsNamesCompany: any = {
  chatgpt: "OpenAI",
  gemini: "Google",
  llama: "Meta",
  // jinaai: "JinAai",
  anthropic: "Anthropic",
  mistralai: "MistralAi",
  mistralai2: "MistralAi",
};

export const modelNames = [
  "ChatGPT",
  "Gemini",
  "Llama",
  // "JinaAi",
  "Anthropic",
  "MistralAi",
  "MistralAi2",
];

// Email Validation
export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// Password validation
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

// Change password & Reset password validation 
export const changePasswordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/;
export interface FilterItem {
  title: string;
  value: string;
}
//firstName and lastName validation 
export const nameRegex = /^[A-Za-z]+$/;
export interface dataReplyType {
  createdAt: string;
  reply: string;
}

export interface ChatModelState {
  id: string;
  parentId: string|null;
  prompt: string;
  replies: dataReplyType[];
  isRegernating: boolean;
  isEditing:boolean;
  attachmentFile: AttachmentPayload;
  version: string;
  createdAt?: string;
  activeReplyIndex?: number;
  queryEdits?: string[];
  messageType: number;
}

export interface ChatModelEditsState {
  MessageId: string;
  EditMessageIndex: any;
  ResponseIndex: any;
}

export interface ModelVersionStatus {
  version: string;
  isFinished: boolean;
}