import React, { useState } from "react";
import "./LogoutPopup.css";
import { useAppDispatch } from "../../../../store/hooks"
import { useSignOutUser } from "../../../../store/Apis/UserApi";
function LogoutPopup() {
  const dispatch = useAppDispatch();
  const signOutUser = useSignOutUser();
  const Logout = () => {
    signOutUser({ IsAuthenticated: false });
    
  };

  return (
    <>
      <div className="logoutMain">
        <div id="myLogoutModal" className="logoutModal">
          <div className="logoutPopup">
            <div className="offsetDiv">
              <img className="sadFaceImg" src=" /Images/LogoutPopupResources/sadFace.svg" />
              <p className="logoutText">
                See you next time!
              </p>
              <p className="logoutText">
                Have a wonderful day!
              </p>
              <div className="signInButton" onClick={Logout}>
                Log out
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LogoutPopup;