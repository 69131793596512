import Api from "../../features/one-query/services/ApiServices";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  ConversationTypeRecord,
  conversationTypesActions,
} from "../one-query/conversationType";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

/*
get ConversationTypes Endpoint
*/

// function to get all ConversationTypes
const getConversationTypes = async () => {
  try {
    const response = await Api.get("/ConversationType");
    return response.data;
  } catch (e) {}
};

// custom hook to get all ConversationTypes using useQuery
const useGetConversation = () => {
  const { data, isPending, error, refetch } = useQuery<
    ConversationTypeRecord[]
  >({
    queryKey: ["ConversationTypes"],
    queryFn: getConversationTypes,
    enabled: false,
  });

  return { data, isPending, error,refetch };
};

// hook to get all ConversationTypes query

const useGetConversationTypes = () => {
  const { data, isPending, error, refetch } = useGetConversation();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const getConversationTypesMutate = async () => {
    const { data } = await refetch();
    if (data) {
      dispatch(
        conversationTypesActions.getConversationTypes(
          data as ConversationTypeRecord[]
        )
      );
    }
  };
  return getConversationTypesMutate;
};

// ========================================================================================================

/*
createNewConversationType Endpoint
*/

// function to create a new ConversationType
const createNewConversationType = async (category: ConversationTypeRecord) => {
  try {
    const response = await Api.post(
      "/ConversationType",
      JSON.stringify(category)
    );

    return response.data;
  } catch (e) {}
};

// custom hook to create a new ConversationType using useMutation
const useCreateNewConversation = () => {
  //   <Error>  is the type of any error that might be thrown when the
  return useMutation({
    mutationKey: ["createNewConversationType"],
    mutationFn: createNewConversationType,
  });
};

// hook to create a new ConversationType query

const useCreateNewConversationType = () => {
  const queryClient = useQueryClient();
  const createConversation = useCreateNewConversation();
  const dispatch = useDispatch();

  const createNewConversationMutate = (category: ConversationTypeRecord) => {
    createConversation.mutate(category, {
      onSuccess: (e: any) => {
        dispatch(conversationTypesActions.newConversationType(e));
        queryClient.invalidateQueries({ queryKey: ["ConversationTypes"] });
      },
    });
  };
  return createNewConversationMutate;
};

// ========================================================================================================
/*
updateConversationTypes Endpoint
*/

// function to update a ConversationType
const updateConversationType = async (category: ConversationTypeRecord) => {
  try {
    const response = await Api.patch(
      `/ConversationType/${category.id}`,
      JSON.stringify(category)
    );

    return response.data;
  } catch (e) {}
};

// custom hook to update a ConversationType using useMutation
const useUpdateConversation = () => {
  return useMutation({
    mutationKey: ["updateConversationType"],
    mutationFn: updateConversationType,
  });
};

// hook to update a ConversationType query

const useUpdateConversationType = () => {
  const updateConversation = useUpdateConversation();
  const dispatch = useDispatch();

  const updateConversationTypeMutate = (category: ConversationTypeRecord) => {
    updateConversation.mutate(category, {
      onSuccess: () => {
        dispatch(conversationTypesActions.updateConversationType(category));
      },
    });
  };
  return updateConversationTypeMutate;
};

// ========================================================================================================
/*
deleteConversationType Endpoint
*/

// function to delete a ConversationType
const deleteConversationType = async (id: string | null) => {
  try {
    const response = await Api.delete(`/ConversationType/${id}`);

    return response.data;
  } catch (e) {}
};

// custom hook to delete a ConversationType using useMutation
const useDeleteConversation = () => {
  return useMutation({
    mutationKey: ["deleteConversationType"],
    mutationFn: deleteConversationType,
  });
};

// hook to delete a ConversationType query

const useDeleteConversationType = () => {
  const queryClient = useQueryClient();
  const deleteConversation = useDeleteConversation();
  const dispatch = useDispatch();

  const deleteConversationTypeMutate = (id: string | null) => {
    deleteConversation.mutate(id, {
      onSuccess: () => {
        dispatch(conversationTypesActions.deleteConversationType(id));
      },
    });
  };
  return deleteConversationTypeMutate;
};

// ========================================================================================================

export {
  useGetConversationTypes,
  useCreateNewConversationType,
  useUpdateConversationType,
  useDeleteConversationType,
};
