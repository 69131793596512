import React from "react";
import "./HelpAndSupport.css";
interface SkeletonProps {
  width: string;
  height: string;
  borderRadius: string;
  margin: string;
  marginX?: string;
  backgroundColor?: string;
}

const Skeleton = ({
  width,
  height,
  borderRadius,
  margin,
  marginX,
  backgroundColor,
}: SkeletonProps) => {
  return (
    <div
      className={`skeleton ${margin} ${marginX}`}
      style={{
        width: width,
        height: height,
        borderRadius: borderRadius,
        backgroundColor: backgroundColor,
      }}
    ></div>
  );
};

export default Skeleton;
