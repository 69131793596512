import React from 'react'
import RegisterPage from '../components/RegisterPage'
import Alert from '../../one-query/services/Alert'

function RegisterView() {
    
  return (
    <>
    <Alert/>
    <RegisterPage />
      </>
  )
}

export default RegisterView