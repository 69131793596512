import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "../../features/one-query/services/ApiServices";

interface ConversationTypeState {
  data: ConversationTypeRecord[];
}

export interface ConversationTypeRecord {
  title: string | null;
  id: string | null;
  description: string | undefined;
  objective: string | null;
  createdAt: string;
}

const ConversationTypesSlice = createSlice({
  name: "Conversations",
  initialState: {} as ConversationTypeState,
  reducers: {
    getConversationTypes: (
      state,
      action: PayloadAction<ConversationTypeRecord[]>
    ) => {
      let data = action.payload;
      state.data = [];
      for (let i = 0; i < data?.length; i++) {
        state.data.push({
          id: data[i].id,
          title: data[i].title,
          description: data[i].description,
          objective: data[i].objective,
          createdAt: data[i].createdAt,
        });
      }
    },
    deleteConversationType: (state, action: PayloadAction<string | null>) => {
      const conversationTypeId = action.payload;
      state.data = state.data.filter(
        (conversationType) => conversationType.id !== conversationTypeId
      );
    },
    updateConversationType: (
      state,
      action: PayloadAction<ConversationTypeRecord>
    ) => {
      const updatedConversation = action.payload;
      const index = state.data.findIndex(
        (conversationType) => conversationType.id === updatedConversation.id
      );
      if (index !== -1) {
        state.data[index] = updatedConversation;
      }
    },
    newConversationType: (
      state,
      action: PayloadAction<ConversationTypeRecord>
    ) => {
      state.data.push(action.payload);
    },
  },
});

export const conversationTypesActions = ConversationTypesSlice.actions;
export default ConversationTypesSlice.reducer;
