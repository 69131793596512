import React from "react";
import { Outlet } from "react-router-dom";
import { useAppSelector } from "../store/hooks";
import Alert from "../features/one-query/services/Alert";

function Layout() {
  const user = useAppSelector((s) => s.User);

  if (!user.IsAuthenticated) {
    window.location.pathname = "account/login";
    return null;
  } else
    return (
      <>
        <Alert />
        <Outlet />
      </>
    );
}

export default Layout;
