import React, { useEffect, useRef, useState } from "react";
import results from "./data.json";
import "./HelpAndSupport.css";
import SignalRService from "../../../../one-query/services/SignalRService";

interface SearchProps {
  suggestionQuestion: string;
  handelSendRequest: (query: string, getConnectionId: string | null) => void;
  setResponse: (res: any) => void;
  setIsContentVisible: () => void;
  inputValue: string;
  setInputValue: (value: string) => void;
}

const SearchBar = ({
  suggestionQuestion,
  handelSendRequest,
  setResponse,
  setIsContentVisible,
  inputValue,
  setInputValue,
}: SearchProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    setShowSuggestions(true);
  };

  const handleClearInput = () => {
    setInputValue("");
    setShowSuggestions(false);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const getConnectionId = () => {
    return SignalRService.getInstance().connection.connectionId;
  };

  useEffect(() => {
    if (suggestionQuestion?.length > 0) {
      setInputValue(suggestionQuestion);
      setShowSuggestions(false);
    }
  }, [suggestionQuestion]);

  const filteredResults = results.filter((result) =>
    result.title.toLowerCase().includes(inputValue.toLowerCase())
  );

  const handleSendHelpRequest = () => {
    setResponse("");
    setIsContentVisible();
    if (inputRef.current) {
      if (inputValue.length > 0 && inputRef?.current.value.length > 0) {
        handelSendRequest(inputValue, getConnectionId());
        setShowSuggestions(false);
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      handleSendHelpRequest();
    }
  };

  const handleSuggestionClicked = (query: string) => {
    setInputValue(query);
    handleSendHelpRequest();
    setShowSuggestions(false); // Hide suggestions when one is clicked
  };

  return (
    <div className="d-flex align-items-center justify-content-center w-100">
      <div className="searchContainer">
        <div className={`searchInput ${inputValue && "pb-0"}`}>
          <input
            id="helpAndSupportInput"
            type="text"
            placeholder="Search"
            ref={inputRef}
            value={inputValue}
            onChange={handleInputChange}
            onInput={handleInputChange}
            onKeyDown={handleKeyDown}
          />
          {inputValue !== "" && (
            <img
              src="./Images/SettingsSideResources/delete_icn.svg"
              className="clearInputIcon c-pointer me-2"
              onClick={handleClearInput}
            />
          )}
          <div className="searchIcon">
            <label
              htmlFor="helpAndSupportInput"
              onClick={handleSendHelpRequest}
            >
              <img
                src="./Images/SettingsSideResources/search-icn.svg"
                className="Icn"
              />
            </label>
          </div>
        </div>
        {inputValue && filteredResults.length > 0 && showSuggestions && (
          <div className="Suggestion pb-3">
            {filteredResults.map((result, i) => (
              <div
                key={i}
                className="suggestionItem"
                onClick={() => handleSuggestionClicked(result.title)}
              >
                <img
                  src="./Images/SettingsSideResources/search_icn.svg"
                  alt=""
                />
                <p className="mb-0">{result.title}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBar;
