import React, { useEffect } from "react";
import SidebarApp from "../components/get-conversation/getConversation";
import "./MainPage.css";
import Models from "../components/get-conversation/Models";
import { useAppSelector } from "../../../store/hooks";
import "../../../../src/ColorTheme.css";
import AlertMessage from "../components/get-conversation/AlertMessage";
import { fetchBuildTimestamp } from "../../../clearCache";

function HomePage() {
  return (
    <>
      <div className="d-flex home-page">
        <div>
          <SidebarApp />
        </div>
        <div className="flex-grow-1">
          <Models page="Main-page" />
        </div>
      </div>
    </>
  );
}

export default HomePage;
