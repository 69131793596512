import React from 'react';
import "./PrivacyAndTermCondition.css";
const Privacy_TermContent = (props:any) => {
    return (
        <div className='mt-4 pe-3'>
            
            <h5 className='internalHeader mb-2'>{props?.header}</h5>
            <p className='subHeader'>{props?.subHeader}</p>
            {
                props.Content?.map((text: string,index: any)=>(<p key={index} className=' textContent'>{text}</p>))
            }
           
            
        </div>
    );
};

export default Privacy_TermContent;