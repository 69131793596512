import * as React from "react";
import { useEffect } from "react";
import {Routes, Route, Navigate } from "react-router-dom";
import Layout from "./shared/Layout";
import SigninCallbackView from "./features/Account/views/SigninCallbackView";
import LoginView from "./features/Account/views/LoginView";
import RegisterView from "./features/Account/views/RegisterView";
import HomePage from "./features/main-page/views/MainPage";
import ChangePassword from "./features/main-page/components/SettingPages/ChangePassword";
import LandingPage from "./features/landing-page/views/LandingPage";
import ForgetPassword from "./features/Account/components/ForgetPassword";
import ResendEmail from "./features/Account/components/ResendEmail";
import SettingComponent from "./features/main-page/components/get-conversation/SettingComponent";
import Privacy_Policy from "./features/main-page/components/SettingPages/Privacy_Policy";
import TermAndCondition from "./features/main-page/components/SettingPages/TermAndCondition";
import PaymentPage from "./features/main-page/components/get-conversation/PaymentPage";
import ContactUs from "./features/Account/components/ContactUs";
import ResetPassword from "./features/main-page/components/SettingPages/ResetPassword";
import HelpAndSupport from "./features/main-page/components/SettingPages/Help & Support/HelpAndSupport";
import CancelSubscription from "./features/Account/components/CancelSubscription";
import Subscription from "./features/Account/components/Subscription";
import AlertMessage from "./features/main-page/components/get-conversation/AlertMessage";
import { useAppSelector } from "./store/hooks";
import { fetchBuildTimestamp } from "./clearCache";
import SnackBarAlert from "./features/main-page/components/get-conversation/SnackBarAlert";

const App = () => {
  const fileErrorMessage = useAppSelector((s) => s.ChatsState.fileErrorMsg);
  const showSnackBar = useAppSelector((s) => s.ChatsState.snackAlert);
  let darkMode = useAppSelector((state) => state.User.darkMode);

  useEffect(() => {
    fetchBuildTimestamp();
  }, []);

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add("dark");
      document.querySelector("body")?.setAttribute("color-theme", "dark");
    } else {
      document.body.classList.remove("dark");
      document.querySelector("body")?.setAttribute("color-theme", "light");
    }
  }, [darkMode]);

  return (
    <>
      <AlertMessage
        showAlert={fileErrorMessage.isError}
        message={fileErrorMessage.errorMsg}
      />
      <SnackBarAlert
        showSnackBar={showSnackBar.show}
        snackBarMessage={showSnackBar.message}
      />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/account/login" element={<LoginView />} />
        <Route path="/account/register" element={<RegisterView />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/privacy-policy" element={<Privacy_Policy />} />
        <Route path="/term-condition" element={<TermAndCondition />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/resend-email" element={<ResendEmail />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route element={<Layout />}>
          <Route path="/main-page" element={<HomePage />} />
          <Route path="/settings" element={<SettingComponent />} />
          <Route path="/conversation/:id" element={<HomePage />} />
          <Route path="/one-query" element={<App />} />
          <Route path="/signin-oidc" element={<SigninCallbackView />} />
          <Route path="/upgradeToPro" element={<PaymentPage />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/help-and-support" element={<HelpAndSupport />} />
          <Route path="/Cancel-subscription" element={<CancelSubscription />} />
          <Route
            path="/cancel-my-Subscription"
            element={<CancelSubscription />}
          />
          <Route path="/subscription" element={<Subscription />} />
        </Route>
        <Route path="*" element={<Navigate to="/main-page" replace />} />
      </Routes>
    </>
  );
};

export default App;
